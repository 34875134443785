import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";

import Lines from "../../../../shared/images/new/lines.svg"

export const Superpower: FC = () => {

    const theme = useTheme();
    // const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box position={'relative'}>
            <img src={Lines} alt={''} style={{
                position: 'absolute', top: 0, left: '0', zIndex: 0,
                width: '140%'
            }}/>

            <Container mt={!down1366 ? '14rem' : '10rem'} flexDirection={'column'} alignItems={'center'}
                       // sx={{overflowX: 'hidden', overflowY: 'visible', position: 'relative'}}
                       pb={'1rem'}>

                <Typography variant={'h1'} textAlign={'center'}>
                    {'Regulait is your superpower'}
                </Typography>

                <Typography variant={"body1"} textAlign={'center'} mt={'1.6rem'} maxWidth={'min(100%, 120rem)'}>
                    {"We specialize in providing innovative compliance management and automation solutions for businesses of all sizes. Our cutting-edge suite of tools helps organizations stay ahead of regulatory requirements and streamline their compliance processes, freeing up valuable time and resources. With a focus on simplicity, efficiency, and accuracy, we are dedicated to empowering our clients to achieve their compliance goals with ease and confidence."}
                </Typography>

                <Box display={"flex"} gap={'17rem'} mt={'17rem'} flexWrap={"wrap"} justifyContent={"center"}>
                    <Box>
                        <Typography variant={'decorative'} color={'rgba(0,0,0,0.87)'}>
                            {'2022'}
                        </Typography>

                        <Typography variant={'h3'} color={'rgba(0,0,0,0.60)'} mt={'1.2rem'}>
                            {"Founded"}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant={'decorative'} color={'rgba(0,0,0,0.87)'}>
                            <span style={{color: '#689AFF'}}>+</span>{'1M'}
                        </Typography>

                        <Typography variant={'h3'} color={'rgba(0,0,0,0.60)'} mt={'1.2rem'}>
                            {"Capital raised"}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant={'decorative'} color={'rgba(0,0,0,0.87)'}>
                            {'100'}<span style={{color: '#689AFF'}}>+</span>
                        </Typography>

                        <Typography variant={'h3'} color={'rgba(0,0,0,0.60)'} mt={'1.2rem'}>
                            {"Customers"}
                        </Typography>
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}
