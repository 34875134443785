import React, {FC} from 'react';
import {Footer} from "../barsEnvironment/footer";
import {Header} from "../barsEnvironment/header";
import {ContactUsTop} from "./components/contactUsTop";

export const ContactUs: FC = () => {

    return (
        <>
            <Header/>

            <ContactUsTop/>
            {/*<FAQ/>*/}

            <Footer/>
        </>
    )
}