import {FC} from 'react';
import {Header} from "../barsEnvironment/header";
import {StreamlineCompliance} from "./components/streamlineCompliance";
import {GlobalBusinesses} from "./components/globalBusinesses";
import {ComplianceSuite} from "./components/complianceSuite";
import {Software} from "./components/software";
import {AnyQuestions} from "./components/anyQuestions";
import {Footer} from "../barsEnvironment/footer";

export const Main: FC = () => {

    return (
        <>
            <Header/>

            <StreamlineCompliance/>
            <GlobalBusinesses/>
            <ComplianceSuite/>
            <Software/>
            {/*<CustomersExperience/>*/}
            {/*<FAQ/>*/}
            <AnyQuestions/>

            <Footer/>
        </>
    )
}