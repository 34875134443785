import {createSlice} from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";
import {TSnackSlice} from "../types";

const initialState: TSnackSlice = {
  notifications: [],
}

const snackSlice = createSlice({
  name: 'snack',
  initialState,
  reducers: {
    addErrorSnack: (slice, { payload }) => {
      slice.notifications = [...slice.notifications, {key: uuidv4(), message: payload, variant: "error"}]
    },
    addInfoSnack: (slice, { payload }) => {
      slice.notifications = [...slice.notifications, {key: uuidv4(), message: payload, variant: "info"}]
    },
    addSuccessfulSnack: (slice, { payload }) => {
      slice.notifications = [...slice.notifications, {key: uuidv4(), message: payload, variant: "success"}]
    },
    removeSnack: (slice, { payload: id }) => {
      slice.notifications = slice.notifications.filter( note => note.key !== id)
    }
  }
})

export const snackReducer = snackSlice.reducer;
export const { addErrorSnack, removeSnack, addInfoSnack, addSuccessfulSnack }= snackSlice.actions;
