import React, {ChangeEvent, FC, useState} from "react";
import {Box, Divider, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ContactTextField, Container} from "../../../main/components/styled";

import Lines from "../../../../shared/images/new/lines.svg"
import {emailRegex, globalBusinesses} from "../../../main/constants";
import {LoadingButton} from "@mui/lab";
import {TContactForm} from "../../../main/types";
import {useSnack} from "../../../snack/hooks/useSnack";
import {sendContactUsEmail} from "../../../../shared/api";

const title = 'Have some questions?';
const description = "To get in touch with us, please fill out the contact form or reach out to us directly via email or phone. We value your feedback and are always looking for ways to improve our services, so please feel free to share your thoughts with us.";

const title2 = 'You’re in good company';
const description2 = "Join global businesses who are using Regulait to take their compliance services to the next level.";

export const ContactUsTop: FC = () => {

    const theme = useTheme();
    const down800 = useMediaQuery(theme.breakpoints.down('c800'));
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const [loading, setLoading] = useState<boolean>(false);
    const [form, setForm] = useState<TContactForm>({
        firstName: '', lastName: '', companyName: '', email: '', message: '', contactNumber: ''
    })
    const handleChange = (field: keyof TContactForm) => (e: ChangeEvent<HTMLTextAreaElement>) => {
        setForm(prev => ({...prev, [field]: e.target.value}))
    }

    const snack = useSnack();
    const handleSubmit = async () => {
        if (form.firstName.trim() === '') {
            snack('First name should not be empty', "error");
            return;
        }
        if (form.lastName.trim() === '') {
            snack('Last name should not be empty', "error");
            return;
        }

        if (!emailRegex.test(form.email)) {
            snack(`${form.email} doesn't looks like 'email'`, "error");
            return;
        }

        if (form.message.trim() === '') {
            snack('Message should not be empty', "error");
            return;
        }

        try {
            setLoading(true);
            // await sendEmail({
            await sendContactUsEmail({
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email,
                companyName: form.companyName,
                contactNumber: form.contactNumber,
                message: form.message,
            }, (message) => snack(message, "error"));
            snack('Message sent', 'success');
            setForm({firstName: '', lastName: '', companyName: '', email: '', message: '', contactNumber: ''})
        } finally {
            setLoading(false);
        }
    }

    return (
        <Box position={'relative'} >
            <img src={Lines} alt={''} style={{
                position: 'absolute', top: 0, left: '0', zIndex: 0,
                width: '140%'
            }}/>

            <Container mt={!down1366 ? '14rem' : '10rem'} display={'flex'} alignItems={'center'} {...(down1024 ? {flexDirection: 'column'} : {})}
                       sx={{overflowX: 'hidden', overflowY: 'visible', position: 'relative'}}
                       pb={'1rem'} gap={'6.4rem'}>

                <Box display={'flex'} flexDirection={'column'} flexBasis={'50%'}>
                    <Typography variant={'h1'} textAlign={'start'}>
                        {title}
                    </Typography>

                    <Typography variant={"body1"} textAlign={'start'} mt={'1.6rem'}>
                        {description}
                    </Typography>

                    <Typography variant={'h3'} textAlign={'start'} mt={'9rem'}>
                        {title2}
                    </Typography>

                    <Typography variant={"body1"} textAlign={'start'} mt={'1.6rem'}>
                        {description2}
                    </Typography>

                    <Divider flexItem sx={{mt: '1.6rem'}}/>

                    <Box display={"flex"} alignItems={"center"} gap={'2.9rem'} pt={'1rem'} flexWrap={"wrap"} justifyContent={"center"}>
                        {globalBusinesses.slice(0, 5).map(e => (
                            <img key={JSON.stringify(e)} src={e.icon} alt={e.id} style={{flexBasis: '20%', maxWidth: 'calc(100% / 5 - 2.9rem)', minWidth: '12rem'}}/>
                        ))}
                    </Box>
                </Box>


                <Box display={'flex'} flexDirection={"column"} alignItems={"center"} flexBasis={'50%'} width={!down800 ? undefined : '100%'}>

                    <Box display={"flex"} flexDirection={"column"} p={!down800 ? '4rem' : '2rem 2rem'} zIndex={4}
                         width={!down800 ? undefined : '100%'}
                         sx={{
                             background: '#FFFFFF',
                             boxShadow: '0rem 0.4rem 2rem rgba(0, 0, 0, 0.16)',
                             borderRadius: '1rem',
                         }}>
                        <Box display={"flex"} flexDirection={down800 ? "column" : undefined}>
                            <ContactTextField value={form.firstName} onChange={handleChange('firstName')} label={'First name'} name={'firstName'}/>
                            <div style={{width: '2rem', height: '2rem'}}/>
                            <ContactTextField value={form.lastName} onChange={handleChange('lastName')} label={'Last name'} name={'lastName'}/>
                        </Box>
                        <div style={{width: '2rem', height: '2rem'}}/>
                        <ContactTextField value={form.email} onChange={handleChange('email')} label={'E-mail'} name={'email'}/>
                        <div style={{width: '2rem', height: '2rem'}}/>
                        <ContactTextField value={form.contactNumber} onChange={handleChange('contactNumber')} label={'Contact number'} name={'contactNumber'}/>
                        <div style={{width: '2rem', height: '2rem'}}/>
                        <ContactTextField value={form.companyName} onChange={handleChange('companyName')} label={'Company name'} name={'company'}/>
                        <div style={{width: '2rem', height: '2rem'}}/>
                        <ContactTextField value={form.message} onChange={handleChange('message')} label={'Your Message...'} name={'comment'}
                                          multiline minRows={3} maxRows={3}/>


                        <div style={{width: '2rem', height: '2rem'}}/>
                        <LoadingButton loading={loading} variant={"contained"} fullWidth size={"large"} onClick={handleSubmit}>Submit</LoadingButton>
                    </Box>

                </Box>
            </Container>
        </Box>
    )
}
