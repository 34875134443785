import React, {FC} from "react";
import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import Illustration from "../../../../shared/images/new/product illustration.svg";
import {PATH_REGISTRATION} from "../../../main/constants";

// const count: string = '';
const title: string = 'Cost-effective and scalable solution';
const description: string = "Allows you to manage, monitor and enforce your compliance requirements and regulations. Regulait automates the compliance process and provides a centralized inventory for storing policies, procedures, and records, thereby reducing manual effort and errors. Ensuring business practices and operations adhere to the required legal and regulatory standards, such as data privacy laws, industry-specific regulations, and internal policies.";

export const UniqueCombination: FC = () => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));

    if (down1024) return (
        <Container mt={'10rem'} alignItems={'center'} flexDirection={'column'}>
            <Box width={'min(100%, 105rem)'}>
                <Typography variant={'h1'}>
                    {title}
                </Typography>
                <Typography variant={"body1"} marginTop={'1.6rem'}>
                    {description}
                </Typography>

                <br/>
                <Button variant={"contained"} sx={{mt: '3.2rem'}} size={"large"} href={PATH_REGISTRATION} target={'_blank'}>
                    {'Sign up'}
                </Button>
            </Box>

            <Box display={'flex'} flexDirection={"column"} alignItems={"center"} mt={'10rem'}>
                <img src={Illustration} alt={'Unic combination icon'} style={{width: '120%', position: "relative", zIndex: 3}}/>
            </Box>
        </Container>
    );

    return (
        <Container mt={'14rem'} alignItems={'center'}>
            <Box maxWidth={'50%'}>
                <Typography variant={'h1'}>
                    {title}
                </Typography>
                <Typography variant={"body1"} marginTop={'1.6rem'}>
                    {description}
                </Typography>

                <br/>
                <Button variant={"contained"} sx={{mt: '3.2rem'}} href={PATH_REGISTRATION} target={'_blank'}>
                    {'Sign up'}
                </Button>
            </Box>

            <Box position={"relative"} width={'50%'}>
                <img src={Illustration} alt={'Unique combination icon'} style={{maxWidth: '100%', position: "relative", zIndex: 3}}/>
            </Box>
        </Container>
    )
}
