import React, {ChangeEvent, FC, useState} from "react";
import {Box, Grid, InputAdornment, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ContactTextField, Container} from "../../../main/components/styled";
import {Search} from "@mui/icons-material";
import {integrations, PATH_CONTACT_US} from "../../../main/constants";
import colors from "../../../../shared/theme/colors";

const Card: FC<{logo: string, title: string}> = ({logo, title}) => {

    return (
        <Grid item c1920={3} c1440={3} c1366={3} c800={4} c600={6} c375={12} xs={12}>
            <Box sx={{
                height: '26.8rem',
                padding: '2rem',
                background: '#FFFFFF',
                boxShadow: '0rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2), 0rem 0.3rem 0.4rem rgba(0, 0, 0, 0.12), 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.14)',
                borderRadius: '2rem',

                display: 'flex', alignItems: 'center', justifyContent: 'center',
            }}>
                <img src={logo} alt={title} style={{maxHeight: '12rem', maxWidth: 'min(100%, 26.2rem)'}}/>
            </Box>
        </Grid>
    )
}

export const IntegrationsTitle: FC = () => {

    const theme = useTheme();
    // const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const [filterFocused, setFocused] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const handleChangeFilter = (e: ChangeEvent<HTMLTextAreaElement>) => {setFilter(e.target.value)}

    return (
        <Container mt={!down1366 ? '14rem' : '10rem'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant={"h1"} color={'#1C0D40'}>
                {'Integrations'}
            </Typography>
            <Typography variant={"body1"} maxWidth={'115.4rem'} textAlign={"center"} mt={'1.6rem'}>
                {"Regulait integrates with dozens of services. With these integrations, you'll have access to a wide range of tools and services to help you manage your compliance efforts more effectively. From cloud storage solutions to secure communications platforms, you'll have everything you need to ensure your compliance management is always up-to-date."}
            </Typography>

            <ContactTextField sx={{width: '100%', maxWidth: '43.9rem', m: '3.2rem 1.6rem'}}
                              placeholder={!filterFocused && filter === '' ? 'Search' : undefined}
                              label={filterFocused || filter !== '' ? 'Search' : undefined}
                              focused={filterFocused} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}
                              value={filter} onChange={handleChangeFilter}
                              InputProps={{
                                  startAdornment: <InputAdornment position="start"><Search/></InputAdornment>
                              }}
            />

            <Grid container sx={{mt: '1.6rem'}} spacing={4}>
                {integrations
                    .filter(e => e.name.toLowerCase().includes(filter.toLowerCase()) || e.alt.some(e => e.toLowerCase().includes(filter.toLowerCase())))
                    .map(e => <Card key={e.name} logo={e.logo} title={e.name}/>)}
            </Grid>

            <Typography variant={"body1"} textAlign={"center"} mt={'7rem'}
            sx={{
                '& span.hover:hover': {color: colors.landing.text2},
            }}>
                {'Integrations added monthly'}
                <br/>
                <Link className={'hover'} style={{textDecoration: 'underline', fontWeight: 600, cursor: 'pointer'}}
                      href={PATH_CONTACT_US}>
                    {'Contact us'}
                </Link>
                {' for custom integration requests.'}
            </Typography>

        </Container>
    )
}
