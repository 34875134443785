import React, {FC} from "react";
import {Box, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import {suiteCards} from "../../../main/constants";

import ComingSoon from '../../../../shared/images/new/coming soon.svg';

export const SuiteCards: FC = () => {

    const theme = useTheme();
    const down800 = useMediaQuery(theme.breakpoints.down('c800'));
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Container mt={'6.4rem'} flexDirection={'column'}
                   sx={{position: 'relative', zIndex: 3}}>

            <Grid container spacing={4} columns={12}>
                {suiteCards().filter(e => !e.comingSoon).map(item => (
                    <Grid item key={item.title} xs={12} c1024={6}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            padding: down1024 ? '4rem 2.5rem' : down1366 ? '5rem 2.5rem' : '10rem 5rem',

                            background: '#FFFFFF',

                            boxShadow: '0rem 0rem 0.5rem rgba(0, 0, 0, 0.15)',
                            borderRadius: '2rem',
                            height: '100%'
                        }}>
                            <Typography variant={"h1"} color={'rgba(0,0,0,0.87)'} textAlign={'center'}>
                                {item.title}
                            </Typography>

                            <Typography variant={"body1"} textAlign={'center'} mt={'1.6rem'} mb={'6.4rem'}>
                                {item.description}
                            </Typography>

                            <img src={item.icon} alt={item.title + ' img'} style={{
                                maxWidth: '100%', maxHeight: '35.5rem', margin: 'auto'
                            }}/>
                        </Box>
                    </Grid>
                ))}
            </Grid>


            {/*<CustomersExperience/>*/}

            {/*<Box sx={{width: '1rem', height: '14rem'}}/>*/}

            {suiteCards().filter(e => e.comingSoon).map(item => (
                <Box key={item.title} sx={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: down1366 ? 'column' : 'row',
                    // padding: down800 ? '6rem 20rem 6rem 10rem' : down1366 ? '4.4rem 14.4rem' : '4.4rem 28rem',
                    padding: '4.4rem',
                    justifyContent: 'space-evenly',

                    background: '#F5F6FB',

                    boxShadow: '0.4rem 0.4rem 0.8rem #D4E1FA',
                    borderRadius: '2rem',
                    mt: '3.2rem',
                    gap: down800 ? '5.4rem' : down1366 ? '5.4rem' : '10rem'
                }}>
                    <Box display={"flex"} flexDirection={"column"} flexBasis={'30%'} alignItems={down1024 ? 'center' : undefined}>
                        <Typography variant={"h1"} color={'rgba(0,0,0,0.87)'}>
                            {item.title}
                        </Typography>

                        <Typography variant={"body1"} mt={'1.6rem'}>
                            {item.description}
                        </Typography>
                    </Box>

                    <Box display={"flex"} flexBasis={'30%'} justifyContent={"center"} mt={down1024 ? '0.8rem' : undefined}>
                        <img src={item.icon} alt={item.title + ' img'} style={{
                            maxHeight: '35.5rem', maxWidth: 'min(100%)'
                        }}/>
                    </Box>

                    <img src={ComingSoon} alt={'coming soon'} style={{
                        position: 'absolute',
                        right: '0',
                        top: '0',
                        width: down1024 ? '25rem' : down1366 ? '30rem' : '35rem',
                    }}/>
                </Box>
            ))}
        </Container>
    )
}
