import {FC} from "react";
import {Header} from "./components/header";
import {Footer} from "./components/footer";
import {Top} from "./components/top";
import {Product} from "./components/product";
import {Suite} from "./components/suite";
import {ComplianceJourney} from "./components/complianceJourney";
import {Integrations} from "./components/integrations";
import {FrameworkCards} from "./components/frameworkCards";
import {FrameworksBuilder} from "./components/frameworksBuilder";
import {AnyQuestions} from "./components/anyQuestions";
import {useRoutes} from "../../shared/hooks/useRoutes";
import {tKey as arabik} from "./translates/arabic";
import {tKey as hebrew} from "./translates/hebrew";
import {frameworkCardsArabic, frameworkCardsHebrew} from "../main/constants";

export const MainLanguage: FC<{language: 'arabic' | 'hebrew' | 'french'}> = ({language}) => {

    const {handleHistoryPush2} = useRoutes()

    let t;
    let cards;
    switch (language) {
        case "arabic": {
            t = arabik;
            cards = frameworkCardsArabic;
            break;
        }
        case "hebrew": {
            t = hebrew;
            cards = frameworkCardsHebrew;
            break;
        }

        default: handleHistoryPush2('/'); return null;
    }

    return t && cards && (
        <>
            <Header tKey={t}/>

            <Top tKey={t}/>
            <Product tKey={t}/>
            <Suite tKey={t}/>
            <ComplianceJourney tKey={t}/>
            <Integrations tKey={t}/>
            <FrameworkCards tKey={t} cards={cards}/>
            <FrameworksBuilder tKey={t}/>
            {/*<FAQ tKey={t}/>*/}
            <AnyQuestions tKey={t}/>

            <Footer tKey={t}/>
        </>
    )
}