import React, {FC} from 'react';
import {Header} from "../barsEnvironment/header";
import {PartnerEcosystem} from "./components/partnerEcosystem";
import {PartnerPrograms} from "./components/partnerPrograms";
import {BecomePartner} from "./components/becomePartner";

export const Partners: FC = () => {

    return (
        <>
            <Header/>

            <PartnerEcosystem/>
            <PartnerPrograms/>
            <BecomePartner/>
            {/*<Footer/>*/}
        </>
    )
}