import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {CircleLines} from "../../../../main/components/styled";
import {TTitleDescriptionIcon} from "../../../types";
import {TTkey} from "../../../translates/arabic";

type TProps = TTitleDescriptionIcon & { reverse?: boolean, tKey: TTkey }
export const ProductRow: FC<TProps> = ({tKey, icon, description, title, link, reverse}) => {

    const theme = useTheme();
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const t = tKey('product');

    return (
        <Box display={"flex"} alignItems={"center"}
             {...(down1366 ? {flexDirection: 'column', p: '3rem 0'} : {flexDirection: !reverse ? 'row' : "row-reverse", p: '5rem 0'})}>
            <Box position={"relative"} {...(down1366 ? {width: 'min(100%, 105rem)'} : {ml: '7rem'})}>
                <CircleLines style={{position: 'absolute', top: '-10rem', left: '-8rem', width: '23rem', height: '23rem', zIndex: 0}}/>
                <Typography variant={'h1'} color={'rgba(0,0,0,0.87)'}
                            position={"relative"} zIndex={3}>
                    {t(title)}
                </Typography>
                <Typography variant={"body1"} position={"relative"} zIndex={3} mt={'1.6rem'}>
                    {t(description)}
                </Typography>
                {/*{link && (*/}
                {/*    <Typography variant={"body2"} color={colors.landing.text2} zIndex={3} mt={'1.6rem'}*/}
                {/*                sx={{cursor: 'pointer'}} onClick={handleClick(link)}>*/}
                {/*        {link}*/}
                {/*    </Typography>*/}
                {/*)}*/}
            </Box>

            <div style={{display: 'flex', alignItems: "center", justifyContent: "center", margin: down1366 ? '7rem 0' : '7rem'}}>
                <img src={icon} alt={''} style={{width: down1366 ? '100%' : '50rem'}}/>
            </div>
        </Box>
    )
}