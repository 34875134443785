//Core
import React, {FC} from "react";

//Router
import {Redirect, Route, Switch} from 'react-router';


// import {Main} from "../mainPage";
import {Main} from "../../main";
import {Product} from "../../product";
import {
    PATH_ARABIC,
    PATH_BLOG,
    PATH_CAREERS,
    PATH_COMPANY,
    PATH_CONTACT_US,
    PATH_EULA,
    PATH_FRAMEWORKS,
    PATH_HEBREW,
    PATH_INTEGRATIONS,
    PATH_PARTNERS,
    PATH_PRICING,
    PATH_PRIVACY_POLICY,
    PATH_PRODUCT,
    PATH_SUITE,
    PATH_TERMS_AND_CONDITIONS,
    PATH_TERMS_OF_SERVICE
} from "../../main/constants";
import {Integrations} from "../../integrations";
import {AboutUs} from "../../aboutus";
import {Pricing} from "../../pricing";
import {Suite} from "../../suite";
import {Partners} from "../../partners";
import {Frameworks} from "../../frameworks";
import {ContactUs} from "../../contactUs";
import {Blog} from "../../blog";
import {Careers} from "../../careers";
import {MainLanguage} from "../../mainArabic";
import {TermsPage} from "../../terms";


export const Routes: FC = () => {

    return <Switch>
        <Route path={"/"} exact component={Main} />
        <Route path={PATH_PRODUCT} exact component={Product} />
        <Route path={PATH_INTEGRATIONS} exact component={Integrations} />
        <Route path={PATH_COMPANY} exact component={AboutUs} />
        <Route path={PATH_PRICING} exact component={Pricing} />
        <Route path={PATH_SUITE} exact component={Suite} />
        <Route path={PATH_PARTNERS} exact component={Partners} />
        <Route path={PATH_FRAMEWORKS} exact component={Frameworks} />
        <Route path={PATH_CONTACT_US} exact component={ContactUs} />
        <Route path={PATH_BLOG} exact component={Blog} />
        <Route path={PATH_CAREERS} exact component={Careers} />

        <Route path={PATH_ARABIC} exact ><MainLanguage language={"arabic"}/></Route>
        <Route path={PATH_HEBREW} exact ><MainLanguage language={"hebrew"}/></Route>

        <Route path={[PATH_TERMS_AND_CONDITIONS, PATH_PRIVACY_POLICY, PATH_TERMS_OF_SERVICE, PATH_EULA]} component={TermsPage} />

        <Redirect to={"/"}/>
    </Switch>;
}
