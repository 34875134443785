import React, {ChangeEvent, FC, useState} from "react";
import {Box, Grid, InputAdornment, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ContactTextField, Container} from "../../../main/components/styled";
import {careerCards} from "../../../main/constants";
import colors from "../../../../shared/theme/colors";
import {KeyboardArrowRight, Search} from "@mui/icons-material";

const title = 'Work at Regulait';
const description = "We are looking for the best & brightest who are passionate about leveraging innovative technology to solve real-world problems and are looking to work with like-minded individuals in a supporting and enriching team.";

export const CareersCards: FC = () => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const [filterFocused, setFocused] = useState<boolean>(false);
    const [filter, setFilter] = useState<string>('');
    const handleChangeFilter = (e: ChangeEvent<HTMLTextAreaElement>) => {setFilter(e.target.value)}

    return (
        <Container mt={!down1366 ? '14rem' : '7rem'} flexDirection={'column'} alignItems={'center'}
                   sx={{position: 'relative', zIndex: 3}}>

            <Typography variant={"h1"} color={'#1C0D40'}>
                {title}
            </Typography>
            <Typography variant={"body1"} maxWidth={'115.4rem'} textAlign={"center"} mt={'1.6rem'}>
                {description}
            </Typography>

            <Box mt={'3.2rem'} display={'flex'} flexDirection={'column'} alignItems={'center'} gap={'1.6rem'} width={'100%'}>
                <ContactTextField sx={{width: '100%', maxWidth: '44rem'}}
                                  placeholder={!filterFocused && filter === '' ? 'Search' : undefined}
                                  label={filterFocused || filter !== '' ? 'Search' : undefined}
                                  focused={filterFocused} onFocus={() => setFocused(true)} onBlur={() => setFocused(false)}
                                  value={filter} onChange={handleChangeFilter}
                                  InputProps={{
                                      startAdornment: <InputAdornment position="start"><Search/></InputAdornment>
                                  }}
                />
            </Box>

            <Grid container spacing={4} columns={12} sx={{mt: '3.2rem'}}>
                {careerCards
                    .filter(e => (
                        e.title.toLowerCase().includes(filter.toLowerCase()) ||
                        e.timeStatus.toLowerCase().includes(filter.toLowerCase()) ||
                        e.place.toLowerCase().includes(filter.toLowerCase())
                    ))
                    .map(item => (
                    <Grid item key={item.id} xs={12} c800={6} c1366={4}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            // alignItems: 'center',
                            justifyContent: 'flex-start',
                            padding: down1024 ? '3.6rem 2.4rem' : down1366 ? '4rem 2.8rem' : '4.4rem 3.2rem',

                            boxShadow: '0rem 0rem 0.5rem rgba(0, 0, 0, 0.15)',
                            borderRadius: '2rem',
                            height: '100%',

                            background: 'transparent',
                            '&:hover': {
                                background: '#FFFFFF',
                            },
                        }}>
                            <Typography variant={"h2"} textAlign={'start'} noWrap>
                                {item.title}
                            </Typography>

                            <Typography variant={"body2"} textAlign={'start'} mt={'1.3rem'} color={'rgba(33, 33, 33, 1)'}>
                                {`${item.place} | `}<span style={{color: colors.landing.text2}}>{item.timeStatus}</span>
                            </Typography>

                            <Typography variant={"body1"} textAlign={'start'} mt={'2.3rem'}>
                                {item.description}
                            </Typography>

                            {item.link && (
                                <Box display={"flex"} alignItems={"center"} alignSelf={"flex-start"} flexShrink={1} mt={'3.2rem'} gap={'0.8rem'} sx={{cursor: 'pointer'}} component={Link} href={item.link}>
                                    <Typography variant={"body1"} color={colors.landing.text2}>
                                        {'Read more and apply'}
                                    </Typography>
                                    <KeyboardArrowRight sx={{color: colors.landing.text2}}/>
                                </Box>
                            )}
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
