import React, {FC} from 'react';
import {Footer} from "../barsEnvironment/footer";
import {Header} from "../barsEnvironment/header";
import {ComplianceTogether} from "./components/complianceTogether";
import {SuiteCards} from "./components/suiteCards";

export const Suite: FC = () => {

    return (
        <>
            <Header/>

            <ComplianceTogether/>
            <SuiteCards/>
            {/*<FAQ/>*/}

            <Footer/>
        </>
    )
}