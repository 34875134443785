import React, {FC} from "react";
import {Box, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import {blogCards} from "../../../main/constants";
import colors from "../../../../shared/theme/colors";
import {KeyboardArrowRight} from "@mui/icons-material";

export const BlogCards: FC = () => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Container mt={!down1366 ? '6.4rem' : '5.4rem'}
                   sx={{position: 'relative', zIndex: 3}}>

            <Grid container spacing={4} columns={12}>
                {blogCards.map(item => (
                    <Grid item key={item.title} xs={12} c800={6} c1366={4}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            // padding: down1024 ? '4rem 2.5rem' : down1366 ? '5rem 2.5rem' : '10rem 5rem',

                            background: '#FFFFFF',

                            boxShadow: '0rem 0rem 0.5rem rgba(0, 0, 0, 0.15)',
                            borderRadius: '2rem',
                            height: '100%'
                        }}>
                            <Box width={'100%'} height={down1024 ? '14rem' : down1366 ? '17rem' : '20.7rem'} overflow={"hidden"}>
                                <img src={item.icon} alt={'item icon'} style={{width: '100%'}}/>
                            </Box>

                            <Box width={'100%'} display={'flex'} flexDirection={"column"}
                                 padding={down1024 ? '1.6rem' : down1366 ? '2.8rem' : '3.2rem'}>
                                <Typography variant={"subtitle1"} textAlign={'start'}>
                                    {item.title}
                                </Typography>

                                <Typography variant={"body1"} textAlign={'start'} mt={'1.6rem'}>
                                    {item.description}
                                </Typography>

                                <Typography variant={"smallText"} textAlign={'start'} mt={'1.6rem'} color={'rgba(0, 0, 0, 0.6)'}>
                                    {item.writer}
                                </Typography>

                                {item.link && (
                                    <Box display={"flex"} alignItems={"center"} alignSelf={"flex-start"} flexShrink={1} mt={'2rem'} gap={'0.8rem'} sx={{cursor: 'pointer'}}>
                                        <Typography variant={"body1"} color={colors.landing.text2}>
                                            {'View details'}
                                        </Typography>
                                        <KeyboardArrowRight sx={{color: colors.landing.text2}}/>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}
