export const colors = {
    // primary: {
    //     blue_light: '#90CAF9',
    //     blue: '#2962FF',
    //     blue_dark: '#1A237E',
    //     hovered: '#448AFF',
    //     disable: '#E0E0E0',
    // },
    // secondary: {
    //     green_light: '#A5D6A7',
    //     green: '#2E7D32',
    //     green_dark: '#1B5E20',
    //     hovered: '#4CAF50',
    //     disable: '#E8F5E9',
    // },
    // text: {
    //     dark: '#202223',
    //     grey: '#A19F9D',
    //     grey_dark: '#605E5C',
    //     grey_light_2: '#D2D0CE',
    //     white: '#FFFFFF',
    //     blue: '#106EBE',
    //     grey_light_1: '#E1DFDD',
    //     critical: '#D72C0D',
    //     warning: '#916A00',
    //     success: '#008060',
    // },
    // backgrounds: {
    //     white: '#FFFFFF',
    //     grey_light: '#F7F7F7',
    //     blue_light_1: '#E3F2FD',
    //     blue_light_2: '#E8EAF6',
    //     dark: '#111827',
    //     dark_blue: '#192745',
    //     green: '#E0F2F1',
    //     red: '#FFCDD2',
    //     grey_dark: '#EDEBE9',
    //     orange: '#FFF9C4',
    // },
    // stroke: {
    //     grey: '#E0E0E0',
    //     blue: '#436FC0',
    //     green: '#00A47C',
    //     red: '#DD2C00',
    //     dark_grey: '#616161',
    //     dark: '#212121',
    //     white: '#FFFFFF',
    // },
    // warningActions: {
    //     green: '#006D41',
    //     red: '#EA3C1D',
    // },
    // decorative: {
    //     brown: '#7E5700',
    //     bright_green: '#12A778',
    //     green_dark: '#4D8063',
    //     orange: '#ED6C02',
    //     violet: '#4968D3',
    //     blue_light: '#5BCDDA',
    //     lilac: '#C886D3',
    // },
    // divider: '#79747e',


    landing: {
        primary: 'rgba(233, 244, 255, 1)',
        primary2: 'rgba(247, 160, 16, 1)',
        button: 'rgba(67, 48, 184, 1)',
        secondary1: 'rgba(245, 246, 251, 1)',
        secondary2: 'rgba(227, 223, 253, 1)',
        background: 'linear-gradient(107.56deg, #FFFFFF 0%, #F0F6FB 54.69%, #FFFFFF 100%)',
        text: 'rgba(33, 33, 33, 1)',
        text2: 'rgba(71, 136, 201, 1)',
        icon: 'rgba(4, 77, 159, 1)',
    },
}

export default colors;
