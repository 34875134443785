import React, {FC} from "react";
import {Box, Button, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import Builder from "../../../../shared/images/new/framework builder.png";
import {PATH_REGISTRATION} from "../../../main/constants";

// const count: string = '';
const title: string = 'Build your compliance framework';
const description: string = "In addition to offering a range of pre-built compliance frameworks, we also provide our users with the ability to build custom frameworks. We understand that every business has unique security and privacy needs, and that a one-size-fits-all approach may not be suitable for everyone. That's why we've made it easy for you to build a framework that aligns with your specific regulatory requirements.\n" +
    "Our platform offers a simple yet powerful interface that allows you to create and customize your compliance framework. You can select from a variety of controls, policies, and procedures that are tailored to your business needs. Our user-friendly interface makes it easy for you to add, modify, and remove controls as necessary, so you can stay up-to-date with any changes to your regulatory requirements.";

export const FrameworksBuilder: FC = () => {
    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));

    return (
        <Container {...(down1024 ? {mt: '10rem', alignItems: 'center', flexDirection: 'column'} : {mt: '14rem', alignItems: 'center', gap: '10rem'})}>
            <Box flexBasis={'50%'}>
                <Typography variant={'h1'}>
                    {title}
                </Typography>
                <Box width={'100%'} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    {description.split('\n').map((e) => (
                        <Typography variant={"body1"} textAlign={'start'} key={e}>
                            <br/>{e}
                        </Typography>
                    ))}
                </Box>

                <br/>
                <Button variant={"contained"} sx={{mt: '3.2rem'}} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                    {'Sign up'}
                </Button>
            </Box>

            <Box position={"relative"} flexBasis={'50%'}>
                <img src={Builder} alt={'Unique combination icon'} style={{maxWidth: '100%', position: "relative", zIndex: 3}}/>
            </Box>
        </Container>
    )
}
