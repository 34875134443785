import React, {FC} from 'react';
import {Footer} from "../barsEnvironment/footer";
import {Header} from "../barsEnvironment/header";
import {products} from "./constants";
import {ProductRow} from "./components/productRow";
import {UniqueCombination} from "./components/uniqueCombination";
import {ProductMore} from "./components/productMore";

export const Product: FC = () => {

    return (
        <>
            <Header/>

            <UniqueCombination/>

            {products.map((product, index) => (
                <ProductRow key={product.title} {...product} reverse={index % 2 !== 0}/>
            ))}

            <ProductMore/>

            <Footer/>
        </>
    )
}