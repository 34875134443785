import Lines from "../../../../shared/images/new/lines.svg";
import {Container} from "../../../main/components/styled";
import {Box, Button, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import React, {FC} from "react";
import {PATH_REGISTRATION} from "../../../main/constants";
import {TTkey} from "../../translates/arabic";


export const ComplianceJourney: FC<{tKey: TTkey}> = ({tKey}) => {

    const theme = useTheme();
    const down800 = useMediaQuery(theme.breakpoints.down('c800'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const t = tKey('complianceJourney');
    const tCommon = tKey('common');

    const title = t('Begin your compliance journey today');

    return (
        <Box position={'relative'} mt={'14rem'}>
            <img src={Lines} alt={''} style={{
                position: 'absolute', top: '-13rem', left: 'min(0rem, calc(100% - 100rem) / 2)', zIndex: 0,
                width: '130%', minWidth: '130rem',
            }}/>

            <Container mt={'14rem'} position={'relative'} zIndex={4} flexDirection={'column'}
                       alignItems={'center'}>
                <Box display={"flex"} flexDirection={"column"} zIndex={4} maxWidth={'min(100%, 106rem)'}>
                    <Typography textAlign={'center'} {...(
                        down800
                            ? {fontWeight: 500, fontSize: '4.8rem', lineHeight: '7.14rem'}
                            : down1366
                                ? {fontWeight: 500, fontSize: '5.8rem', lineHeight: '8.14rem'}
                                : {fontWeight: 500, fontSize: '7.8rem', lineHeight: '10.14rem'}
                    )}>
                        {title}
                    </Typography>

                    <Button variant={"contained"} size={"large"} sx={{margin: '6.4rem auto 0', width: 'fit-content'}} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                        {tCommon('Start now')}
                    </Button>
                </Box>
            </Container>
        </Box>
    )
}