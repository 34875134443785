import {useSnackbar} from "notistack";
import {useDispatch, useSelector} from "react-redux";
import {notificationsSelector} from "../../store/selectors";
import {useEffect} from "react";
import {removeSnack} from "../../store/slice";

export const useSnackBar = () => {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const notifications = useSelector(notificationsSelector);
  useEffect(() => {
    for (const { key, message, variant } of notifications) {
      enqueueSnackbar(message, {
        key,
        variant,
        onExited: (event, sKey) => {
          dispatch(removeSnack(sKey));
        }
      });
    }
    //eslint-disable-next-line
  }, [notifications, dispatch]);
}
