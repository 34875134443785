import React, {FC} from "react";
import {Box, Grid, Link, List, useMediaQuery, useTheme} from "@mui/material";
import {Container, FooterListItem, UnderFooterText} from "../../../main/components/styled";
import {ANCHOR_FRAMEWORKS, ANCHOR_INTEGRATIONS, ANCHOR_PRODUCT, ANCHOR_SUITE} from "../../constants";
import {TTkey} from "../../translates/arabic";
import {PATH_PRIVACY_POLICY, PATH_TERMS_AND_CONDITIONS} from "../../../main/constants";
import {SocialButtons} from "../../../barsEnvironment/footer/components/socialButtons";
import {useLocation} from "react-router";

export const Footer: FC<{tKey: TTkey}> = ({tKey}) => {

    const {pathname} = useLocation();
    const theme = useTheme();
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));
    const down800 = useMediaQuery(theme.breakpoints.down('c800'));

    const t = tKey('header');

    const Home = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={''}>{t('Home')}</FooterListItem>
        </List>
    )

    const AboutUs = () => (
        <List>
            <FooterListItem isTitle>{t('About us')}</FooterListItem>
            <FooterListItem sx={{textAlign: 'start'}}>{t("Regulait makes the Compliance process a task by establishing a consistent control analysis and automated procedures across every framework and regulation adherence.")}</FooterListItem>
        </List>
    )

    const Contacts = () => (
        <>
            <List>
                <FooterListItem isTitle>{t('Contacts')}</FooterListItem>
                <FooterListItem isLink><a href={"mailto: hello@regulait.com"}>{'hello@regulait.com'}</a></FooterListItem>
            </List>
            <List>
                <FooterListItem isTitle>{t('Legal Inquiries')}</FooterListItem>
                <FooterListItem isLink><a href={"mailto: legal@regulait.com"}>{'legal@regulait.com'}</a></FooterListItem>
            </List>
            <List>
                <FooterListItem isTitle>{t('Investor Relations')}</FooterListItem>
                <FooterListItem isLink><a href={"mailto: investors@regulait.com"}>{'investors@regulait.com'}</a></FooterListItem>
            </List>
        </>
    )

    const Product = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={pathname + '#' + ANCHOR_PRODUCT}>{t('Product')}</FooterListItem>
        </List>
    )

    const Suite = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={pathname + '#' + ANCHOR_SUITE}>{t('Suite')}</FooterListItem>
        </List>
    )

    const Integrations = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={pathname + '#' + ANCHOR_INTEGRATIONS}>{t('Integrations')}</FooterListItem>
        </List>
    )

    const Frameworks = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={pathname + '#' + ANCHOR_FRAMEWORKS}>{t('Frameworks')}</FooterListItem>
        </List>
    )

    const UnderFooter = () => (
        <>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={'3.2rem'}>
                <UnderFooterText isLink component={Link} href={pathname + '#' + PATH_TERMS_AND_CONDITIONS}>{t('Terms and conditions')}</UnderFooterText>
                <UnderFooterText isLink component={Link} href={pathname + '#' + PATH_PRIVACY_POLICY}>{t('Privacy policy')}</UnderFooterText>
            </Box>
            <UnderFooterText textAlign={'center'} mt={'0.8rem'}>{t('© All rights reserved to Regulait, 2023')}</UnderFooterText>
        </>
    )

    if (down800) return (
        <Container mt={'15rem'} flexDirection={'column'}>
            <Grid container columns={12}>
                <Grid item xs={6}>
                    <AboutUs/>
                    <SocialButtons/>
                    <Contacts/>
                </Grid>

                <Grid item xs={6}>
                    <Home/>
                    <Product/>
                    <Suite/>
                    <Integrations/>
                    <Frameworks/>
                </Grid>
            </Grid>

            <UnderFooter/>
        </Container>
    );

    if (down1366) return (
        <Container mt={'12rem'} flexDirection={'column'}>
            <Grid container columns={12}>
                <Grid item xs={4}>
                    <AboutUs/>
                    <SocialButtons/>
                </Grid>

                <Grid item xs={4}>
                    <Home/>
                    <Product/>
                    <Suite/>
                    <Integrations/>
                    <Frameworks/>
                </Grid>

                <Grid item xs={4}>
                    <Contacts/>
                </Grid>
            </Grid>

            <UnderFooter/>
        </Container>
    );

    return (
        <Container mt={'17rem'} flexDirection={'column'}>
            <Grid container columns={12}>
                <Grid item xs={3}>
                    <AboutUs/>
                    <SocialButtons/>
                </Grid>

                <Grid item xs={1}/>

                <Grid item xs={2}>
                    <Home/>
                    <Suite/>
                </Grid>

                <Grid item xs={2}>
                    <Frameworks/>
                </Grid>

                <Grid item xs={2}>
                    <Integrations/>
                </Grid>

                <Grid item xs={2}>
                    <Contacts/>
                </Grid>
            </Grid>

            <UnderFooter/>
        </Container>
    )
}
