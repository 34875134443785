import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container, SeeMoreButton} from "../../../main/components/styled";
import Circle from "../../../../shared/images/new/integrationsCircle.svg";
import Frameworks from "../../../../shared/images/new/frameworksSmall.png";
import Suite from "../../../../shared/images/new/suiteSmall.png";
import {PATH_FRAMEWORKS, PATH_INTEGRATIONS, PATH_SUITE} from "../../../main/constants";

const title = 'Integrations';
const description = "Regulait integrates with dozens of services. With these integrations, you'll have access to a wide range of tools and services to help you manage your compliance efforts more effectively.";

export const ProductMore: FC = () => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <>
            <Container mt={!down1366 ? '14rem' : '10rem'} display={'flex'} alignItems={'center'} flexDirection={'column'}
                       sx={{position: 'relative'}}
                       pb={'1rem'}>

                <Box display={'flex'} flexDirection={"column"} alignItems={"center"} flexBasis={'50%'}>
                    <img src={Circle} alt={'Integrations icon'} style={{width: '100%', position: "relative", zIndex: 3}}/>
                </Box>

                <Box display={'flex'} flexDirection={'column'} {...(!down1024 ? {maxWidth: '40%', mt: '-24rem'} : {maxWidth: 'min(100%, 80rem)'})} zIndex={5}>
                    <Typography variant={'h1'} textAlign={'center'}>
                        {title}
                    </Typography>

                    <Box width={'100%'} display={"flex"} flexDirection={"column"} alignItems={"center"}>
                        {description.split('\n').map((e) => (
                            <Typography variant={"body1"} textAlign={'center'} key={e}>
                                <br/>{e}
                            </Typography>
                        ))}
                    </Box>

                    <SeeMoreButton title={`See all Integrations`} link={PATH_INTEGRATIONS} center/>
                    {/*<Typography mt={'1.6rem'} variant={'body2'} textAlign={'center'} color={colors.landing.text2} onClick={handleHistoryPush(PATH_INTEGRATIONS)} style={{cursor: 'pointer'}}>{`See all Integrations`}</Typography>*/}
                </Box>
            </Container>

            <Container mt={!down1366 ? '14rem' : '10rem'} display={'flex'} alignItems={'stretch'} flexWrap={'wrap'}
                       sx={{position: 'relative'}} gap={'4rem'}
                       pb={'1rem'}>
                {[
                    {title: 'Frameworks', description: 'We offer a wide range of compliance frameworks and add new frameworks monthly to help you streamline your compliance operations and maintain an effective Compliance posture strategy.', icon: Frameworks, link: PATH_FRAMEWORKS},
                    {title: 'Suite', description: "At Regulait, we understand the challenges of maintaining compliance in today's fast-paced business world. That's why we've created the Ultimate Compliance Solution - a comprehensive platform that brings all your compliance-related tasks under one roof.", icon: Suite, link: PATH_SUITE},
                ].map(e => (
                    <Box display={'flex'} alignItems={'center'} width={!down1366 ? 'calc(50% - 2rem)' : '100%'} key={e.title} minWidth={'min(100%, 60rem)'}
                         gap={!down1024 ? '5.4rem' : '3.2rem'}
                         sx={{
                             padding: !down1024 ? '5rem 3.2rem' : '3.2rem 2.4rem',
                             background: '#F1F5FF',
                             boxShadow: '0rem 0rem 0.5rem rgba(0, 0, 0, 0.15)',
                             borderRadius: '2rem',
                         }}>
                        <img src={e.icon} alt={e.title + ' icon'} style={{maxWidth: '30%'}}/>

                        <Box display={'flex'} flexDirection={'column'} gap={'1.6rem'}>
                            <Typography variant={'h1'} textAlign={'start'}>{e.title}</Typography>
                            <Typography variant={'body1'} textAlign={'start'}>{e.description}</Typography>
                            <SeeMoreButton title={`See all ${e.title}`} link={e.link}/>
                            {/*<Typography variant={'body2'} textAlign={'start'} color={colors.landing.text2} onClick={handleHistoryPush(e.link)} style={{cursor: 'pointer'}}>{`See all ${e.title}`}</Typography>*/}
                        </Box>
                    </Box>
                ))}
            </Container>
        </>
    )
}