import React, {FC} from "react";
import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";

import BrochureImage from "../../../../shared/images/new/brochure.png"


export const Datasheet: FC = () => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));

    const download = () => {
        const a = document.createElement('a');
        a.href = '/files/dashboard.pdf';
        a.download = 'Regulait datasheet.pdf';
        a.click();
    };

    if (down1024) return (
        <Container mt={'17rem'} flexDirection={'column'} alignItems={'center'}>
            <Box display={"flex"} flexDirection={"column"} alignItems={'center'}>
                <Typography variant={"h1"} color={'rgba(0,0,0,0.87)'}>
                    {'Download our Product datasheet'}
                </Typography>

                <img src={BrochureImage} alt={'brochure'}
                     style={{
                         maxWidth: 'min(100%, 80rem)',
                         marginTop: '1.6rem',
                     }}/>

                <Button variant={"contained"} sx={{width: 'fit-content', textTransform: 'none', mt: '3.2rem'}} size={"large"} onClick={download}>
                    Download
                </Button>
            </Box>
        </Container>
    );

    return (
        <Container mt={'17rem'} alignItems={'center'}>
            <Box display={"flex"} flexDirection={"column"} maxWidth={'57.1rem'}>
                <Typography variant={"h1"} color={'rgba(0,0,0,0.87)'}>
                    {'Download our Product datasheet'}
                </Typography>

                <Button variant={"contained"} sx={{width: 'fit-content', textTransform: 'none', mt: '3.2rem'}} size={"medium"} onClick={download}>
                    Download
                </Button>
            </Box>

            <img src={BrochureImage} alt={'brochure'} style={{maxWidth: 'calc(100% - 10rem - 60.1rem)', marginLeft: 'auto'}}/>
        </Container>
    )
}
