//Core
import React, {FC, useEffect} from 'react';
import {Routes} from "./routes";
import {useLocation} from "react-router";
import {rlt} from "../../main/constants";
import {accessibilityDefault, useAccessibility} from "../../../shared/hooks/useAccessibility";
import {Box, IconButton, Popper, Typography} from "@mui/material";
import {Accessible, Add, Contrast, FilterBAndWOutlined, Logout, Remove, RestartAlt} from "@mui/icons-material";


export const Shell: FC = () => {
    // const {revDir} = useDirection()

    const location = useLocation();
    useEffect(() => {
        if (location.hash.length > 1 && location.hash.startsWith('#')) {
            setTimeout(() => {
                const element = document.getElementById(location.hash.substring(1));
                if (element) {
                    window.scrollTo({
                        behavior: 'smooth',
                        top: element.offsetTop - 130,
                    })
                }
            }, 100);

        } else window.scrollTo(0, 0);

        if (rlt.some(e => location.pathname.startsWith(e))) document.body.style.direction = 'rtl'
        else document.body.style.direction = 'ltr';

    }, [location.pathname, location.hash]);

    const {
        isOpen: isOpenAccessibility, setOpen: setAccessibilityOpen,
        setAccessibility,
    } = useAccessibility();
    
    return (
            <div style={{width: '100%', height: '100%', padding: '3.2rem 0', margin: '0 auto', overflowX: 'hidden', overflow: 'hidden', position: 'relative'}}>
                
                <IconButton size={"medium"} onClick={(e) => setAccessibilityOpen(prev => !prev ? e.currentTarget : null)}
                            sx={{
                                border: '1px solid black', p: '0.5rem', borderRadius: '0.5rem',
                                position: 'fixed', top: '20rem', left: 0, zIndex: 5000,
                                '& svg': {width: '2rem', height: '2rem'}
                }}>
                    <Accessible/>
                </IconButton>
                <Popper open={!!isOpenAccessibility} anchorEl={isOpenAccessibility} sx={{zIndex: 5000}}>
                    <Box sx={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center',
                        backgroundColor: 'white',
                        boxShadow: '0rem 0.1rem 1rem rgba(0, 0, 0, 0.2), 0rem 0.4rem 0.5rem rgba(0, 0, 0, 0.12), 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.14)',
                        p: '3.2rem',
                    }}>
                        <Typography variant={'h3'} sx={{textAlign: 'center'}}>{'Accessibility menu'}</Typography>
                        <Box display={"flex"} flexWrap={"wrap"} gap={'1rem'} maxWidth={'27rem'} mt={'1.6rem'}>
                            <Box display={"flex"} flexDirection={'column'} alignItems={"center"} gap={'1.2rem'} p={'1rem'} border={'0.1rem solid rgba(0, 0, 0, 0.87)'}
                                 flexBasis={'48%'}
                                 sx={{cursor: 'pointer'}} onClick={() => setAccessibility(prevState => ({...prevState, contrast: !prevState.contrast}))}>
                                <Contrast/>
                                <Typography variant={'body1'}>{'High contrast'}</Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={'column'} alignItems={"center"} gap={'1.2rem'} p={'1rem'} border={'0.1rem solid rgba(0, 0, 0, 0.87)'}
                                 flexBasis={'48%'}
                                 sx={{cursor: 'pointer'}} onClick={() => setAccessibility(prevState => ({...prevState, monochrome: !prevState.monochrome}))}>
                                <FilterBAndWOutlined/>
                                <Typography variant={'body1'}>{'Monochrome'}</Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={'column'} alignItems={"center"} gap={'1.2rem'} p={'1rem'} border={'0.1rem solid rgba(0, 0, 0, 0.87)'}
                                 flexBasis={'48%'}
                                 sx={{cursor: 'pointer'}} onClick={() => setAccessibility(prevState => ({...prevState, size: prevState.size > 20 ? prevState.size : prevState.size + 1}))}>
                                <Add/>
                                <Typography variant={'body1'}>{'Increase Text'}</Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={'column'} alignItems={"center"} gap={'1.2rem'} p={'1rem'} border={'0.1rem solid rgba(0, 0, 0, 0.87)'}
                                 flexBasis={'48%'}
                                 sx={{cursor: 'pointer'}} onClick={() => setAccessibility(prevState => ({...prevState, size: prevState.size < 11 ? prevState.size : prevState.size - 1}))}>
                                <Remove/>
                                <Typography variant={'body1'}>{'Reduce Text'}</Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={'column'} alignItems={"center"} gap={'1.2rem'} p={'1rem'} border={'0.1rem solid rgba(0, 0, 0, 0.87)'}
                                 flexBasis={'48%'}
                                 sx={{cursor: 'pointer'}} onClick={() => setAccessibility(accessibilityDefault)}>
                                <RestartAlt/>
                                <Typography variant={'body1'}>{'Reset'}</Typography>
                            </Box>
                            <Box display={"flex"} flexDirection={'column'} alignItems={"center"} gap={'1.2rem'} p={'1rem'} border={'0.1rem solid rgba(0, 0, 0, 0.87)'}
                                 flexBasis={'48%'}
                                 sx={{cursor: 'pointer'}} onClick={() => setAccessibilityOpen(null)}>
                                <Logout/>
                                <Typography variant={'body1'}>{'Close'}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Popper>

                <Routes/>
            </div>
    )
}
