import React, {FC} from "react";
import {Header} from "../barsEnvironment/header";
import {Footer} from "../barsEnvironment/footer";
import {Container} from "../main/components/styled";
import {Routes} from "./routes";
import {Box, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import {useLocation} from "react-router";
import {PATH_EULA, PATH_PRIVACY_POLICY, PATH_TERMS_AND_CONDITIONS, PATH_TERMS_OF_SERVICE} from "../main/constants";
import colors from "../../shared/theme/colors";

export const TermsPage: FC = () => {

    const {pathname} = useLocation();

    const theme = useTheme();
    const isDown800 = useMediaQuery(theme.breakpoints.down('c800'));

    return (
        <>
            <Header/>

            <Container mt={'10rem'} flexDirection={!isDown800 ? 'row' : 'column'}
                       pb={'1rem'}
                       sx={{
                           color: 'rgba(0, 0, 0, 0.6)',
                           fontSize: '1.6rem',

                           '& .MuiTypography-root': {
                               mt: '2rem',
                               mb: '1.2rem'
                           },
                           '& .MuiTypography-root.MuiTypography-body2': {
                               mt: '0',
                               mb: '0'
                           },

                           '& ul': {
                               // listStyle: 'inside',
                               ml: '2rem',
                               mt: '1.2rem',
                           },
                           '& li': {
                               listStyleType: 'disc',
                               mb: '0.4rem'
                           },
                       }}
                       gap={'4rem'}
            >
                <Box display={"flex"} flexDirection={"column"} mb={'auto'} sx={{
                    background: '#FFFFFF',

                    boxShadow: '0.4rem 0.4rem 0.8rem #D4E1FA',
                    backdropFilter: 'blur(2.25rem)',
                    borderRadius: '1rem',
                    p: '3.2rem',
                }}>
                    <Typography variant={'h3'} href={PATH_TERMS_AND_CONDITIONS} component={Link} whiteSpace={"nowrap"}
                                sx={{cursor: 'pointer', color: pathname === PATH_TERMS_AND_CONDITIONS ? colors.landing.icon : undefined}}>Terms and Conditions</Typography>
                    <Typography variant={'h3'} href={PATH_PRIVACY_POLICY} component={Link} whiteSpace={"nowrap"}
                                sx={{cursor: 'pointer', color: pathname === PATH_PRIVACY_POLICY ? colors.landing.icon : undefined}}>Privacy Policy</Typography>
                    <Typography variant={'h3'} href={PATH_TERMS_OF_SERVICE} component={Link} whiteSpace={"nowrap"}
                                sx={{cursor: 'pointer', color: pathname === PATH_TERMS_OF_SERVICE ? colors.landing.icon : undefined}}>Terms of Service</Typography>
                    <Typography variant={'h3'} href={PATH_EULA} component={Link} whiteSpace={"nowrap"}
                                sx={{cursor: 'pointer', color: pathname === PATH_EULA ? colors.landing.icon : undefined}}>EULA</Typography>
                </Box>

                <Box display={"flex"} flexDirection={"column"}>
                    <Routes/>
                </Box>
            </Container>

            <Footer/>
        </>
    )
}