import React, {FC} from "react";
import {Box, Button, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import {CircleLines, Container, Gear} from "../../../main/components/styled";
import colors from "../../../../shared/theme/colors";
import {PATH_REGISTRATION} from "../../../main/constants";
import DashboardSvg from "../../../../shared/images/new/dashboard new.png";
import {TTkey} from "../../translates/arabic";

export const Top: FC<{tKey: TTkey}> = ({tKey}) => {

    const theme = useTheme();
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const t = tKey('top');
    const tCommon = tKey('common');

    const title = t('Streamline Compliance');
    const subtitle = t('Automate Assurance').length > 0 ? t('Automate Assurance') : null;
    const description = t('Easily manage and automate your compliance needs with our all-in-one suite. The only solution that provides a complete suite of systems for compliance assurance.');

    return (
        <Container alignItems={'center'} {...(down1366 ? {mt: '10rem', flexDirection: 'column'} : {mt: '14rem'})}>
            <Box {...(down1366 ? {width: 'min(100%, 105rem)'} : {})}>
                <Typography variant={'h1'}>
                    {title}
                </Typography>
                {subtitle && (
                    <Typography {...(down1366 ? {fontWeight: 300, fontSize: '3.2rem', lineHeight: '3.9rem'} : {fontWeight: 300, fontSize: '4.8rem', lineHeight: '5.86rem'})} color={colors.landing.primary2}>
                        {subtitle}
                    </Typography>
                )}
                <Typography variant={"body1"} marginTop={'1.6rem'}>
                    {description}
                </Typography>

                <Button variant={"contained"} sx={{mt: '3.2rem'}} style={{textTransform: 'none'}} size={"large"} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                    {tCommon('Sign up')}
                </Button>
            </Box>

            <Box style={{minWidth: '4rem', height: '4rem'}}/>

            <Box position={"relative"} width={'100%'}>
                <Gear style={{position: 'absolute', zIndex: 1, top: '0rem', right: '6rem'}}/>
                <CircleLines style={{
                    position: 'absolute',
                    zIndex: 1,
                    bottom: '2.0rem',
                    left: '-12.5rem',
                }}/>

                <img src={DashboardSvg} alt={'DashboardSvg icon'} style={{position: 'relative', zIndex: 3, maxWidth: '100%'}}/>
            </Box>

        </Container>
    )
}