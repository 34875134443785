import React, {FC} from 'react';
import {Footer} from "../barsEnvironment/footer";
import {Header} from "../barsEnvironment/header";
import {AnyQuestions} from "../main/components/anyQuestions";
import {Plans} from "./components/plans";

export const Pricing: FC = () => {

    return (
        <>
            <Header/>
            <Plans/>
            <AnyQuestions/>
            <Footer/>
        </>
    )
}