import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";

const title = 'Blog';
const description = "Learn about Regulait and how  to take your compliance services to the next level.";

export const BlogTop: FC = () => {

    const theme = useTheme();
    // const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Container mt={!down1366 ? '14rem' : '10rem'} flexDirection={'column'} alignItems={'center'}
                   sx={{overflowX: 'hidden', overflowY: 'visible', position: 'relative'}}
                   pb={'1rem'}>

            <Typography variant={'h1'} textAlign={'center'}>
                {title}
            </Typography>

            <Box mt={'1.6rem'} width={'100%'}>
                {description.split('\n').map(e => (
                    <Typography key={JSON.stringify(e)} variant={"body1"} textAlign={'center'} maxWidth={'min(100%, 115.4rem)'} m={'0 auto'}>
                        {e}
                    </Typography>
                ))}
            </Box>

        </Container>
    )
}
