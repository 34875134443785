import React, {FC} from "react";
import {Redirect, Route, Switch} from "react-router";
import {PATH_EULA, PATH_PRIVACY_POLICY, PATH_TERMS_AND_CONDITIONS, PATH_TERMS_OF_SERVICE} from "../main/constants";
import {TermsAndConditions} from "./components/termsAndConditions";
import {PrivacyPolicy} from "./components/privacyPolicy";
import {TermsOfService} from "./components/termsOfService";
import {Eula} from "./components/eula";

export const Routes: FC = () => {

    return <Switch>
        <Route path={PATH_TERMS_AND_CONDITIONS} exact component={TermsAndConditions} />
        <Route path={PATH_PRIVACY_POLICY} exact component={PrivacyPolicy} />
        <Route path={PATH_TERMS_OF_SERVICE} exact component={TermsOfService} />
        <Route path={PATH_EULA} exact component={Eula} />

        <Redirect to={PATH_TERMS_AND_CONDITIONS}/>
    </Switch>;
}