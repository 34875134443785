import React, {FC} from "react";
import {Container} from "../styled";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {software} from "../../constants";
import {SoftwareRow} from "./components";

const title = 'Software that delivers compliance confidence';
const description = 'Promoting responsible business practices and mitigating the risk of non-compliance';

export const Software: FC = () => {

    const theme = useTheme();
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    if (down1366) return (
        <Container mt={'16rem'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant={'h1'} textAlign={"center"}>
                {title}
            </Typography>
            <Typography variant={"body1"} textAlign={"center"} mt={'2.1rem'}
                        width={'min(100%, 105rem)'}>
                {description}
            </Typography>

            <Box position={"relative"} mt={'6.4rem'}
                 sx={{
                     backgroundColor: '#F5F6FB',
                     borderRadius: '2rem',
                 }}>
                {software.map((e, index) => (
                    <SoftwareRow key={JSON.stringify(e)} icon={e.icon} title={e.title} description={e.description} link={e.link} reverse={index % 2 !== 0}/>
                ))}
            </Box>
        </Container>
    );

    return (
        <Container mt={'17rem'} flexDirection={'column'}>
            <Typography variant={"h1"} textAlign={"center"}>
                {title}
            </Typography>
            <Typography variant={"body1"} textAlign={"center"} mt={'2.1rem'} >
                {description}
            </Typography>

            <Box position={"relative"} mt={'6.4rem'} overflow={'hidden'}
                 sx={{
                     backgroundColor: '#F5F6FB',
                     borderRadius: '2rem',
                 }}>
                {software.map((e, index) => (
                    <SoftwareRow key={JSON.stringify(e)} icon={e.icon} title={e.title} description={e.description} link={e.link} reverse={index % 2 !== 0}/>
                ))}
            </Box>
        </Container>
    )
}
