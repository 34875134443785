import React, {FC} from 'react';
import {Footer} from "../barsEnvironment/footer";
import {Header} from "../barsEnvironment/header";
import {Superpower} from "./components/superpower";
import {Founders} from "./components/founders";
import {OurValues} from "./components/ourValues";
import {Datasheet} from "./components/datasheet";

export const AboutUs: FC = () => {

    return (
        <>
            <Header/>

            <Superpower/>
            <Founders/>
            <OurValues/>
            {/*<Team/>*/}
            {/*<Investors/>*/}
            <Datasheet/>

            <Footer/>
        </>
    )
}