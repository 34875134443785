import {TTitleDescriptionIcon} from "../../../types";
import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {CircleLines, SeeMoreButton} from "../../styled";

type TProps = TTitleDescriptionIcon & { reverse?: boolean }
export const SoftwareRow: FC<TProps> = ({icon, description, title, link, reverse}) => {

    const theme = useTheme();
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    if (down1366) return (
        <Box display={"flex"} flexDirection={"column"} alignItems={"center"}
             justifyContent={"space-between"} p={'3rem 0'}>
            <Box position={"relative"} width={'min(100%, 105rem)'}>
                <CircleLines style={{position: 'absolute', top: '-10rem', left: '-8rem', zIndex: 0}}/>
                <Typography variant={'h1'} color={'rgba(0,0,0,0.87)'}
                            position={"relative"} zIndex={3}>
                    {title}
                </Typography>
                <Typography variant={"body1"} position={"relative"} zIndex={3} mt={'1.6rem'}>
                    {description}
                </Typography>
                {link && (
                    <SeeMoreButton title={'Learn more'} link={link || ''}/>
                )}
            </Box>

            <div style={{display: 'flex', alignItems: "center", justifyContent: "center", margin: '7rem 0'}}>
                <img src={icon} alt={''} style={{maxWidth: '100%'}}/>
            </div>
        </Box>
    );

    return (
        <Box display={"flex"} flexDirection={!reverse ? 'row' : "row-reverse"} alignItems={"center"}
             justifyContent={"space-between"} p={'5rem 0'}>
            <Box position={"relative"} ml={'7rem'}>
                <CircleLines style={{position: 'absolute', top: '-10rem', left: '-8rem', width: '23rem', height: '23rem', zIndex: 0}}/>
                <Typography variant={'h1'} position={"relative"} zIndex={3}>
                    {title}
                </Typography>
                <Typography variant={"body1"} position={"relative"} zIndex={3} mt={'1.6rem'}>
                    {description}
                </Typography>
                {link && (
                    <SeeMoreButton title={'Learn more'} link={link || ''}/>
                )}
            </Box>

            <div style={{display: 'flex', alignItems: "center", justifyContent: "center", margin: '7rem'}}>
                <img src={icon} alt={''} style={{width: '89rem'}}/>
            </div>
        </Box>
    )
}