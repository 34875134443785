import React, {FC} from 'react';
import {Footer} from '../barsEnvironment/footer';
import {Header} from "../barsEnvironment/header";
import {CareersTop} from "./components/careersTop";
import {CareersCards} from "./components/careersCards";
import {WeValueOurEmployees} from "./components/weValueOurEmployees";

export const Careers: FC = () => {

    return (
        <>
            <Header/>

            <CareersTop/>
            <CareersCards/>
            <WeValueOurEmployees/>

            <Footer/>
        </>
    )
}