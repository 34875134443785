import {useEffect, useState} from "react";

export const useDirection = () => {

    const [revDir, setRevRid] = useState<boolean>(document.body.style.direction === 'rtl' || document.dir === 'rtl');
    useEffect(() => {
        setRevRid(document.body.style.direction === 'rtl' || document.dir === 'rtl');
        // eslint-disable-next-line
    }, [document.body.style.direction])

    return {revDir}
}