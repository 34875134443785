import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import Lines from "../../../../shared/images/new/lines.svg";

import Face1 from '../../../../shared/images/new/careers_faces/Ellipse 430.png';
import Face2 from '../../../../shared/images/new/careers_faces/Ellipse 431.png';
import Face3 from '../../../../shared/images/new/careers_faces/Ellipse 432.png';
import Face4 from '../../../../shared/images/new/careers_faces/Ellipse 433.png';
import Face5 from '../../../../shared/images/new/careers_faces/Ellipse 434.png';
import Face6 from '../../../../shared/images/new/careers_faces/Ellipse 435.png';

const title = 'Become a part of our team';

export const CareersTop: FC = () => {

    const theme = useTheme();
    const down600 = useMediaQuery(theme.breakpoints.down('c600'));
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box position={'relative'}>
            <img src={Lines} alt={''} style={{
                position: 'absolute', top: 0, left: '0', zIndex: 0,
                width: '100%'
            }}/>

            <Container mt={!down1366 ? '14rem' : '10rem'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}
                       height={down1024 ? '60rem' : down1366 ? '70rem' : '80rem'}
                       sx={{overflowX: 'hidden', overflowY: 'visible', position: 'relative'}}
                       pb={'1rem'}>
                {!down1366 ? (
                    <>
                        <img alt={'Face1'} src={Face1} style={{maxWidth: '17rem', position: 'absolute', left: '30rem', top: '0'}}/>
                        <img alt={'Face1'} src={Face2} style={{maxWidth: '17rem', position: 'absolute', right: '30rem', top: '3rem'}}/>
                        <img alt={'Face1'} src={Face3} style={{maxWidth: '17rem', position: 'absolute', left: '1rem', top: '35%'}}/>
                        <img alt={'Face1'} src={Face4} style={{maxWidth: '17rem', position: 'absolute', right: '1rem', top: 'calc(35% + 3rem)'}}/>
                        <img alt={'Face1'} src={Face5} style={{maxWidth: '17rem', position: 'absolute', left: '30rem', bottom: '4rem'}}/>
                        <img alt={'Face1'} src={Face6} style={{maxWidth: '17rem', position: 'absolute', right: '30rem', bottom: '0'}}/>
                    </>
                ) : !down1024 ? (
                    <>
                        <img alt={'Face1'} src={Face1} style={{maxWidth: '14rem', position: 'absolute', left: '15rem', top: '0'}}/>
                        <img alt={'Face1'} src={Face2} style={{maxWidth: '14rem', position: 'absolute', right: '15rem', top: '3rem'}}/>
                        {/*<img alt={'Face1'} src={Face3} style={{maxWidth: '17rem', position: 'absolute', left: '1rem', top: '35%'}}/>*/}
                        {/*<img alt={'Face1'} src={Face4} style={{maxWidth: '17rem', position: 'absolute', right: '1rem', top: 'calc(35% + 3rem)'}}/>*/}
                        <img alt={'Face1'} src={Face5} style={{maxWidth: '14rem', position: 'absolute', left: '10rem', bottom: '4rem'}}/>
                        <img alt={'Face1'} src={Face6} style={{maxWidth: '14rem', position: 'absolute', right: '20rem', bottom: '0'}}/>
                    </>
                ) : !down600 ? (
                    <>
                        <img alt={'Face1'} src={Face1} style={{maxWidth: '10rem', position: 'absolute', left: '20%', top: '0'}}/>
                        <img alt={'Face1'} src={Face2} style={{maxWidth: '10rem', position: 'absolute', right: '20%', top: '8rem'}}/>
                        {/*<img alt={'Face1'} src={Face3} style={{maxWidth: '17rem', position: 'absolute', left: '1rem', top: '35%'}}/>*/}
                        {/*<img alt={'Face1'} src={Face4} style={{maxWidth: '17rem', position: 'absolute', right: '1rem', top: 'calc(35% + 3rem)'}}/>*/}
                        <img alt={'Face1'} src={Face5} style={{maxWidth: '10rem', position: 'absolute', left: '10%', bottom: '2rem'}}/>
                        <img alt={'Face1'} src={Face6} style={{maxWidth: '10rem', position: 'absolute', right: '10%', bottom: '0'}}/>
                    </>
                ) : (
                    <>
                        <img alt={'Face1'} src={Face1} style={{maxWidth: '8rem', position: 'absolute', left: '20%', top: '0'}}/>
                        <img alt={'Face1'} src={Face2} style={{maxWidth: '8rem', position: 'absolute', right: '20%', top: '8rem'}}/>
                        {/*<img alt={'Face1'} src={Face3} style={{maxWidth: '17rem', position: 'absolute', left: '1rem', top: '35%'}}/>*/}
                        {/*<img alt={'Face1'} src={Face4} style={{maxWidth: '17rem', position: 'absolute', right: '1rem', top: 'calc(35% + 3rem)'}}/>*/}
                        <img alt={'Face1'} src={Face5} style={{maxWidth: '8rem', position: 'absolute', left: '10%', bottom: '2rem'}}/>
                        <img alt={'Face1'} src={Face6} style={{maxWidth: '8rem', position: 'absolute', right: '10%', bottom: '0'}}/>
                    </>
                )}

                <Typography variant={'decorative'} textAlign={'center'} maxWidth={'min(100%, 85rem)'}>
                    {title}
                </Typography>

            </Container>
        </Box>
    )
}
