import React, {FC} from 'react';
import {Footer} from "../barsEnvironment/footer";
import {Header} from "../barsEnvironment/header";
import {FrameworksTop} from "./components/frameworksTop";
import {FrameworkCards} from "./components/frameworkCards";
import {FrameworksBuilder} from "./components/frameworksBuilder";

export const Frameworks: FC = () => {

    return (
        <>
            <Header/>

            <FrameworksTop/>
            <FrameworkCards/>
            <FrameworksBuilder/>

            <Footer/>
        </>
    )
}