import React, {FC} from "react";
import {Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";

export const PartnerEcosystem: FC = () => {

    const theme = useTheme();
    // const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Container mt={!down1366 ? '14rem' : '10rem'} flexDirection={'column'} alignItems={'center'}
                   sx={{overflowX: 'hidden', overflowY: 'visible', position: 'relative'}}
                   pb={'1rem'}>

            <Typography variant={'h1'} textAlign={'center'}>
                {'Explore our powerful partner ecosystem'}
            </Typography>

            <Typography variant={"body1"} textAlign={'center'} mt={'1.6rem'} maxWidth={'min(100%, 115.4rem)'}>
                {"Are you looking to take your compliance services to the next level? We're excited to offer a partnership program designed to help you achieve just that. As a partner, you'll have access to a range of benefits that will allow you to provide your clients with the best possible service."}
            </Typography>
        </Container>
    )
}
