import {TTitleDescriptionIcon} from "../../../domain/main/types";
import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

type TProps = TTitleDescriptionIcon
export const OneOfThree: FC<TProps> = ({icon, description, title}) => {

    return (
        <Box display={"flex"} flexDirection={"column"} maxWidth={'43.6rem'}>
            <div style={{width: '6rem', height: '6rem', display: 'flex', alignItems: "center", justifyContent: "center"}}>
                <img src={icon} alt={title + ' icon'} style={{maxWidth: '6rem', maxHeight: '6rem'}}/>
                {/*<icon style={{maxWidth: '6rem', maxHeight: '6rem'}}/>*/}
            </div>
            <Typography variant={'subtitle1'} mt={'1.6rem'}
                        textTransform={"uppercase"} >
                {title}
            </Typography>
            <Typography variant={"body1"} mt={'1.6rem'}>
                {description}
            </Typography>
        </Box>
    )
}