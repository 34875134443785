import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

export const TermsOfService: FC = () => {

    // const theme = useTheme();
    // const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%',
                '& .MuiTypography-subtitle2': {
                        marginBottom: '0.8rem',
                },
                '& .MuiTypography-body1': {
                        marginTop: '1rem',
                },
        }}>

            <Typography variant={"h1"}>Terms of Service</Typography>
            <Typography variant={"body1"}>Date last modified: March 12, 2023</Typography>
            <Typography variant={"body1"}>BEFORE YOU SELECT THE "I ACCEPT" CHECKBOX AT THE BOTTOM OF THIS WINDOW, PLEASE READ CAREFULLY THE FOLLOWING TERMS OF SERVICE. BY CHOOSING THE "I ACCEPT" BUTTON YOU ARE (1) REPRESENTING THAT YOU ARE OVER THE AGE OF 18 AND HAVE THE CAPACITY AND AUTHORITY TO BIND YOURSELF AND YOUR EMPLOYER/E/ORGANIZATION TO THESE TERMS OF SERVICE; AND (2) CONSENTING ON BEHALF OF YOURSELF AND AS AN AUTHORIZED REPRESENTATIVE OF YOUR EMPLOYER/ORGANIZATION, TO BE BOUND BY THE FOLLOWING TERMS OF SERVICE.  IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THE FOLLOWING TERMS OF SERVICE, OR DO NOT REPRESENT THE FOREGOING, DO NOT SELECT THE "I ACCEPT" CHECKBOX, AND IN WHICH CASE YOU MAY NOT PROCEED WITH SIGNING UP AND/OR IN AND/OR USING THE REGULAIT PLATFORM.</Typography>

            <Typography variant={"subtitle2"}>General</Typography>
            <Typography variant={"body2"}>These terms of service (these “Terms of Service”) in conjunction with the Privacy Policy, available at https://regulait.com/privacy, as may change from time to time, the End User License Agreement available at https://regulait.com/eula, as may change from time to time (the "EULA") , and any other agreement executed between you and Regulait, constitute a legal and binding agreement, between you and Regulait Security Ltd, with address at Abba Hillel Silver Road 7, Ramat Gan, Israel (“Regulait”), that governs your use of the Regulait online platform at https://app.regulait.com, as may change from time to time and any service therefrom (collectively, the “Regulait Platform”). By accessing and using the Regulait Platform, you accept these Terms of Service, without limitation or qualification. If you do not agree with these Terms of Service, you must immediately sign out and cease using Regulait Platform.</Typography>
            <Typography variant={"body2"}>“you”, and “your” shall apply collectively to you as an individual and to the organization for which you are using the Regulait Platform. You hereby acknowledge, warrant, and covenant that you have the authority to (i) use the Regulait Platform; (ii) bind your organization to the terms of these Terms of Service.</Typography>
            <Typography variant={"subtitle2"}>Materials</Typography>
            <Typography variant={"body2"}>All materials contained on the Regulait Platform are provided for informational purposes only and should not be construed as an advice, commercial offer, license, or professional relationship between you and Regulait. No information provided on the Regulait Platform should be considered a substitute for your independent research.</Typography>
            <Typography variant={"subtitle2"}>User Accounts</Typography>
            <Typography variant={"body2"}>To use the Regulait Platform , you must create an account. You agree to provide accurate, current and complete information about yourself during the registration process and to update such information to keep it accurate, current and complete. You are solely responsible for maintaining the confidentiality of your account and password and for all activities that occur under your account. You may not share your password with anyone, or transfer or give access to the Regulait Platform to anyone else.</Typography>
            <Typography variant={"subtitle2"}>Proprietary Rights</Typography>
            <Typography variant={"body2"}>Regulait Platform and any part thereof are protected by copyright, trademark and other proprietary rights. You agree not to use, modify, reproduce, distribute, sell, license, reverse engineer or create derivative works based on Regulait Platform or any part thereof, except as expressly permitted by these Terms of Service or as otherwise authorized by Regulait in writing.</Typography>
            <Typography variant={"body2"}>Regulait reserves the right to deactivate, cease, block or suspend, temporarily or permanently, your account and/or your ability to make use of the Regulait Platform or any of the services and features thereof, for any reason, at its sole and absolute discretion.</Typography>
            <Typography variant={"body2"}>Please note that Regulait may, at its sole discretion, reject or deny your registration to the Regulait Platform or any of the features or services thereof.</Typography>
            <Typography variant={"subtitle2"}>License</Typography>
            <Typography variant={"body2"}>The use of any of the Regulait Platform, its features or services, is not and shall not be deemed as a sale, transfer or assignment of any rights, and is subject to the terms of the EULA.</Typography>
            <Typography variant={"subtitle2"}>Use of Regulait Platform</Typography>
            <Typography variant={"body2"}>Please note that the scope and nature of the Regulait Platform may vary according to your specific workplace or specific binding agreement executed by your organization and Regulait, as applicable and valid (the “Specific Agreement”), and you do not and shall not have any claim and/or demand on this matter.</Typography>
            <Typography variant={"body2"}>You agree to grant Regulait the right to edit, supervise, and remove any content posted or shared by you in the Regulait Platform</Typography>
            <Typography variant={"body2"}>Regulait at all times has the right, but not the obligation, to update, to upgrade and/or to adapt the Regulait Platform or any part thereof and/or to change or remove data or information stored on it’s servers.</Typography>
            <Typography variant={"body2"}>Your use of the Regulait Platform or any part thereof is at your own account and risk. You are responsible for any and all use by you, as well as for the integrity of the information you provide on the Services.</Typography>
            <Typography variant={"subtitle2"}>Disclaimer</Typography>
            <Typography variant={"body2"}>THE REGULAIT PLATFORM IS PROVIDED ON AN "AS IS" AND “AS AVALIABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. BY USING ANY OF THEM, YOU HEREBY PRESENT AND WARRANT NOT TO USE FOR ANY ILLEGAL, IMMORAL OR UNAUTHORIZED PURPOSES, NOR TO KNOWINGLY OR PURPOSELY USE IN VIOLATION OF ANY RIGHT OF THIRD PARTY. YOUR USE DOES NOT AND SHALL NOT CREATE ANY PARTNERSHIP, JOINT VENTURE, AGENCY OR ANY OTHER RELATIONSHIP BETWEEN YOU AND REGULAIT. REGULAIT MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE QUALITY, ACCURACY, COMPLETENESS, RELIABILITY, OR AVAILABILITY OF REGULAIT PLATFORM, NOR DOES IT WARRANT ERROR OR DEFECTS FREE, ACCURACY, COMPLETENESS, MERCHANTABILITY, TITLE AND NON-INFRINGEMENT OF THIRD-PARTY RIGHTS OR THAT ANY ERRORS OR DEFECTS ON WILL BE CORRECTED.</Typography>
            <Typography variant={"subtitle2"}>Unlawful or Prohibited Use</Typography>
            <Typography variant={"body2"}>You may not use the Regulait Platform for any purpose that is unlawful or prohibited by applicable law or by these Terms of Service, or interfere with any other user's use. You may not attempt to gain unauthorized access to the Regulait Platform.</Typography>
            <Typography variant={"body2"}>You must notify Regulait immediately in the event you become aware of any unauthorized access to or use of the Regulait Platform by third parties under your username or password or any other possible breach in security.</Typography>
            <Typography variant={"body2"}>Regulait is neither responsible nor liable for any loss or damage that may result from any unauthorized use.</Typography>
            <Typography variant={"body2"}>You may not reproduce, duplicate, copy or otherwise exploit material on the Regulait Platform for a commercial or any unauthorized purpose without a prior written consent from Regulait. You further agree not to engage in any activity that interferes with or disrupts the Regulait Platform or any part thereof. This includes, but is not limited to, transmitting any viruses, worms, defects, Trojan horses, malicious code, or any other similar software or programs that may damage the operation of the Regulait Platform or any part thereof.</Typography>
            <Typography variant={"body2"}>You agree and warrant that your use of the Regulait Platform and any part thereof will not infringe upon any and all rights of third parties, and that such use will always be in conformity with all applicable laws and regulations.</Typography>
            <Typography variant={"subtitle2"}>Term and Termination</Typography>
            <Typography variant={"body2"}>You may use the Regulait Platform from the when you are granted access by Regulait and until the earlier of: (i) the date on which the term set by the Specific Agreement, if applicable;  (ii) the date in which you terminate according to a prior written notice by you to Regulait when you , or (iii) on the date Regulait notifies you in writing (the “Regulait Termination”). Regulait reserves the right, in its sole discretion, to terminate, suspend, remove, block, deactivate, and/or disable your access and use to all or part of the Regulait Platform, with or without notice and with or without cause, other than provided otherwise in the Specific Agreement, if applicable.</Typography>
            <Typography variant={"body2"}>In the event you notify Regulait in writing within 14 of the days, of any of your Users commencement of using the Regulait Platform, of you terminating within such 14 days, then any fees paid to Regulait for the use of or subscription to the Regulait Platform for the use of the Regulait Platform for more than such 14 days, shall be refunded to you or your organization, as applicable. Other than such termination within 14 days, all payments and fees by you, shall be non-refundable.</Typography>
            <Typography variant={"body2"}>in the event that the Regulait Termination occurred other than due to an act or omission or any breach of by you, prior to the end of a paid subscription by you to Regulait, for the use of the Regulait Platform, then Regulait shall refund the relative portion of the already paid fees for the number of months remaining until the end of such subscription.</Typography>
            <Typography variant={"subtitle2"}>Indemnification</Typography>
            <Typography variant={"body2"}>You hereby agree to defend, indemnify and hold Regulait, their owners, employees, consultants and affiliates, harmless from and against all damages, claims, costs, charges and expenses (including attorneys’ fees) arising out of your use of the Regulait Platform.</Typography>
            <Typography variant={"subtitle2"}>Limitation of Liability</Typography>
            <Typography variant={"body2"}>Notwithstanding any damages that you might incur, the entire liability of Regulait under any provision of these Terms of Service and your exclusive remedy for all of the foregoing shall be limited to the amount actually paid by you to Regulait in the 12 months preceding event in which the damage was caused to you. To the maximum extent permitted by applicable law, in no event shall Regulait be liable for any special, incidental, indirect, or consequential damages whatsoever (including, but not limited to, damages for loss of profits, for loss of data or other information, for business interruption, for personal injury, for loss of privacy arising out of or in any way related to the use of or inability to use the Regulait Platform, third-party software and/or third-party hardware used with the Regulait Service(s)Platform, or otherwise in connection with any provision of these Terms of Service, even if Regulait has been advised of the possibility of such damages and even if the remedy fails of its essential purpose.</Typography>
            <Typography variant={"body2"}>Some states/jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so the above limitation or exclusion may not apply to you.</Typography>
            <Typography variant={"subtitle2"}>Data Protection and Privacy</Typography>
            <Typography variant={"body2"}>In performing the Services, Reguliat will comply with the Regulait Privacy Policy, which is available at https://regulait.com/privacy and incorporated herein by reference, as may change from time to time (the “Privacy Policy”) Regulait highly regards your rights in your personal data and your privacy. The collection and use of your data is subject to Regulait's Privacy Policy.</Typography>
            <Typography variant={"subtitle2"}>Amendments</Typography>
            <Typography variant={"body2"}>Regulait reserves the right, at its sole discretion, to modify or replace these Terms of Service at any time. If a revision is material, Regulait will provide at least 30 days' notice prior to any new terms taking effect. You are advised to review these Terms of Service on a regular basis. By continuing to access or use the Regulait Platform, after any revisions become effective, you agree to be bound by the revised terms. If, within thirty (30) days of Regulait posting changes or amendments to these Terms of Service, you decide that you do not agree to the updated terms, you may withdraw your acceptance to the amended terms by providing Regulait with written notice of your withdrawal. Upon providing Regulait with the written notice of the withdrawal of your acceptance, you are no longer authorized to access or use the Regulait Platform.</Typography>
            <Typography variant={"subtitle2"}>Waiver</Typography>
            <Typography variant={"body2"}>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under these Terms of Service shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under these Terms of Service preclude further exercise of that or any other right granted herein.</Typography>
            <Typography variant={"subtitle2"}>Precedence</Typography>
            <Typography variant={"body2"}>In the event of any contradiction between the terms of the EULA, these Terms of Service or the Specific Agreement, the precedence of the documents shall be as follows:</Typography>
            <Typography variant={"body2"}>First – the Specific Agreement</Typography>
            <Typography variant={"body2"}>Second - the EULA</Typography>
            <Typography variant={"body2"}>Third - these Terms of Service</Typography>

            <Typography variant={"subtitle2"}>Survival and Severability</Typography>
            <Typography variant={"body2"}>Any provisions in these Terms of Service, which by their nature extend beyond the termination or expiration of any License under these Terms of Service, will remain in effect after such termination.</Typography>
            <Typography variant={"body2"}>If any provision of these Terms of Service is deemed to be void, invalid, unenforceable or illegal, Regulait will replace such invalid provision while the other provisions shall continue in full force and effect.</Typography>
            <Typography variant={"subtitle2"}>Law and Jurisdiction</Typography>
            <Typography variant={"body2"}>These Terms of Service and your use of the Services are governed by the laws of the State of Israel. The courts of competent jurisdiction located within the district of Tel Aviv-Jaffa, Israel, will have the exclusive jurisdiction over any and all disputes arising out of, relating to or concerning these Terms of Service and/or the Services or in which these Terms of Service and/or the Services are a material fact.</Typography>
            <Typography variant={"body2"}>No Employment or Agency Relationship</Typography>
            <Typography variant={"body2"}>No provision of these Terms of Service, or any part of the relationship between you and Regulait, is intended to create nor shall they be deemed or construed to create any relationship between you and Regulait other than as specifically referred to in these Terms of Service.</Typography>
            <Typography variant={"subtitle2"}>Equitable Relief</Typography>
            <Typography variant={"body2"}>You acknowledge and agree that your breach of these Terms of Service would cause Regulait irreparable harm for which money damages alone would be inadequate. In addition to damages and any other remedies to which Regulait may be entitled, you acknowledge and agree that Regulait may seek injunctive relief to prevent the actual, threatened or continued breach of these Terms of Service.</Typography>
            <Typography variant={"subtitle2"}>Headings</Typography>
            <Typography variant={"body2"}>The headings in these Terms of Service are for reference only and shall not limit the scope of, or otherwise affect, the interpretation of these Terms of Service.</Typography>
            <Typography variant={"subtitle2"}>Limitation of Time to File Claims</Typography>
            <Typography variant={"body2"}>Any cause of action or claim you may have arisen out of or relating to these Terms of Service or the Regulait Platform must be commenced within one (1) year after the cause of action accrues, otherwise, such cause of action or claim is permanently barred.</Typography>
            <Typography variant={"subtitle2"}>Assignment</Typography>
            <Typography variant={"body2"}>You may not sublicense, transfer or assign these Terms of Service or any of the rights or responsibilities or licenses granted under these Terms of Service without Regulait’s prior written consent. Regulait may assign these Terms of Service.</Typography>
            <Typography variant={"subtitle2"}>Entire Agreement</Typography>
            <Typography variant={"body2"}>these Terms of Service together with the EULA, Privacy Policy, and any agreement executed between you and Regulait, regarding your use of the Regulait Platform and/or Regulait Service(s) and/or any part thereof shall be binding upon you and your use. You may be subject to additional terms and conditions that apply when you use or purchase services, which Regulait will provide to you at the time of such use or purchase.</Typography>
            <Typography variant={"subtitle2"}>Contact Us</Typography>
            <Typography variant={"body2"}>Don't hesitate to contact us if you have any questions about these Terms of Service.</Typography>
            <Typography variant={"body2"}>
                <ul>
                    <li>
                        Via Email: support@regulait.com
                    </li>
                    <li>
                        Via this Link: https://regulait.com/contact
                    </li>
                </ul>
            </Typography>
        </Box>
    )
}