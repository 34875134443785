import React, {FC} from "react";
import {CircleLines, Container, Gear} from "../styled";
import {Box, Button, Link, Typography, useMediaQuery, useTheme} from "@mui/material";
import {PATH_REGISTRATION} from "../../constants";
import DashboardSvg from "../../../../shared/images/new/dashboard new.png";
import colors from "../../../../shared/theme/colors";

const title = 'Streamline Compliance';
const subtitle = 'Automate Assurance';
const description = 'Easily manage and automate your compliance needs with our all-in-one suite. The only solution that provides a complete suite of systems for compliance assurance.';

export const StreamlineCompliance: FC = () => {
    const theme = useTheme();
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    if (down1366) return (
        <Container alignItems={'center'} mt={'10rem'} flexDirection={'column'}>
            <Box width={'min(100%, 105rem)'}>
                <Typography variant={'h1'}>
                    {title}
                </Typography>
                <Typography fontWeight={300} fontSize={'3.2rem'} lineHeight={'3.904rem'} color={colors.landing.primary2}>
                    {subtitle}
                </Typography>
                <Typography variant={"body1"} marginTop={'1.6rem'}>
                    {description}
                </Typography>

                <Button variant={"contained"} size={"large"} sx={{mt: '3.2rem'}} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                    {'Sign up'}
                </Button>
            </Box>

            <Box position={"relative"} sx={{'& svg.gear path': {fill: '#DDE7FE'}}} mt={'10rem'}
                 display={"flex"} alignItems={"center"}>
                <img src={DashboardSvg} alt={'DashboardSvg icon'} style={{maxWidth: '100%', position: "relative", zIndex: 3}}/>
            </Box>
        </Container>
    );

    return (
        <Container alignItems={'center'} mt={'14rem'}>
            <Box>
                <Typography variant={'h1'}>
                    {title}
                </Typography>
                <Typography fontWeight={300} fontSize={'4.8rem'} lineHeight={'5.856rem'} color={colors.landing.primary2}>
                    {subtitle}
                </Typography>
                <Typography variant={"body1"} marginTop={'1.6rem'}>
                    {description}
                </Typography>

                <Button variant={"contained"} sx={{mt: '3.2rem'}} style={{textTransform: 'none'}} size={"large"} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                    {'Sign up'}
                </Button>
            </Box>

            <Box style={{minWidth: '4rem', height: '4rem'}}/>

            <Box position={"relative"} width={'100%'}>
                <Gear style={{position: 'absolute', zIndex: 1, top: '0rem', right: '6rem'}}/>
                <CircleLines style={{
                    position: 'absolute',
                    zIndex: 1,
                    bottom: '2.0rem',
                    left: '-12.5rem',
                }}/>

                <img src={DashboardSvg} alt={'DashboardSvg icon'} style={{position: 'relative', zIndex: 3, maxWidth: '100%'}}/>
            </Box>

        </Container>
    )
}
