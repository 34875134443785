import React, {FC, Fragment, useEffect, useState} from "react";
import {Container} from "../../main/components/styled";

import {ReactComponent as RegulaitLogo} from "../../../shared/images/new/logoRegulait.svg";
import {
    AppBar,
    Box,
    Button,
    Collapse,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {languages, PATH_LOG_IN, PATH_REGISTRATION, topBarElements} from "../../main/constants";
import {Close, KeyboardArrowDown, KeyboardArrowUp, Language, MenuRounded as MenuIcon} from "@mui/icons-material";
import colors from "../../../shared/theme/colors";
import {ElevationScroll, TopTextButton} from "../styled";


export const Header: FC = (props) => {
    const [anchorEl, setAnchorEl] = useState<null | {element: HTMLElement, title: string}>(null);
    const handleDropdownOpen = (title: string) => (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        setAnchorEl({element: event.currentTarget, title});
    };
    const handleDropdownClose = () => {
        setAnchorEl(null);
    };
    const [leftMenuDropdown, setLeftMenuDropdown] = useState<string>('');

    const theme = useTheme();
    const down440 = useMediaQuery(theme.breakpoints.down('c440'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const [menuOpen, setOpen] = useState<boolean>(false);
    useEffect(() => {
        setOpen(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [down1366])

    return (
        <>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar color={"transparent"} >
                    <Container sx={{justifyContent: 'space-between', alignItems: 'center', gap: '3.2rem'}}>
                        <Box display={"flex"} alignItems={"center"} gap={'1.6rem'}>
                            <Link href={'/'} style={{maxWidth: 'min(16.3rem, 100%)'}}><RegulaitLogo className={'logo'} style={{cursor: 'pointer', maxWidth: '100%', }}/></Link>

                            {!down1366 && (
                                <IconButton onClick={handleDropdownOpen('language')}
                                            sx={{color: colors.landing.text, direction: 'ltr', paddingLeft: '2rem', paddingRight: '2rem'}}
                                            size={"large"}
                                >
                                    <Language/>
                                </IconButton>
                            )}
                            <Menu open={anchorEl?.title === 'language'} onClose={handleDropdownClose}
                                  anchorEl={anchorEl?.element}
                                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                  transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                                {languages.map(element => (
                                    <MenuItem key={JSON.stringify(element)} component={Link} href={window.location.origin + element.path}
                                              sx={{minWidth: anchorEl?.element.offsetWidth, textAlign: 'center'}}>
                                        {element.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        {!down1366 && (
                            <Box display={"flex"} alignItems={"center"} gap={'5.4rem'}>
                                {topBarElements.filter(e => !e.hide).map((element, index, array) => (
                                    <Fragment key={JSON.stringify(element)}>
                                        {element.type === 'link' && (
                                            <TopTextButton component={Link} href={element.link}>
                                                <Typography variant={'tabText'}>{element.title}</Typography>
                                            </TopTextButton>
                                        )}

                                        {element.type === 'menu' && (
                                            <>
                                                <TopTextButton onClick={handleDropdownOpen(element.title)}>
                                                    <Typography variant={'tabText'}>{element.title}</Typography>
                                                    {anchorEl?.title === element.title ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}
                                                </TopTextButton>


                                                <Menu
                                                    anchorEl={anchorEl?.element}
                                                    open={anchorEl?.title === element.title}
                                                    onClose={handleDropdownClose}
                                                >
                                                    {element.data.filter(e => !e.hide).map(item => (
                                                        <MenuItem component={Link} href={item.link} key={item.title}>{item.title}</MenuItem>
                                                    ))}
                                                </Menu>
                                            </>
                                        )}
                                    </Fragment>
                                ))}
                            </Box>
                        )}

                        <Box display={"flex"} alignItems={"center"} gap={!down1366 ? '3.2rem': '0.9rem'}>
                            {!down1366 && (
                                <TopTextButton component={Link} href={PATH_LOG_IN} target={'_blank'}>
                                    <Typography variant={"button"} color={colors.landing.text}>{'Log in'}</Typography>
                                </TopTextButton>
                            )}

                            <Button variant={"contained"} size={"medium"} sx={{whiteSpace: 'nowrap'}} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                                {'Sign up'}
                            </Button>

                            {down1366 && (
                                <IconButton size={"large"} color={"primary"}
                                            onClick={() => setOpen(prev => !prev)}>
                                    <MenuIcon/>
                                </IconButton>
                            )}
                        </Box>
                    </Container>
                </AppBar>
            </ElevationScroll>

            <Drawer
                anchor={"right"}
                open={menuOpen}
                onClose={() => setOpen(false)}
            >
                <Box p={'1rem 2rem'} width={!down440 ? '400px' : '100vw'} display={"flex"} flexDirection={'column'} alignItems={'flex-end'}>
                    <Box display={"flex"} alignItems={"center"} gap={!down1366 ? '3.2rem': '0.9rem'}>
                        <Button variant={"contained"} size={"medium"} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                            {'Sign up'}
                        </Button>

                        <IconButton size={"large"} color={"primary"}
                                    onClick={() => setOpen(prev => !prev)}>
                            <Close/>
                        </IconButton>
                    </Box>

                    <Button variant={"text"} onClick={handleDropdownOpen('language')}
                            sx={{
                                color: colors.landing.text, direction: 'ltr', paddingLeft: '2rem', paddingRight: '2rem',
                                m: '3.2rem auto 0'
                            }}
                            startIcon={<Language/>} size={"medium"}
                            endIcon={anchorEl?.title === 'language' ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}>
                        {'Language'}
                    </Button>

                    <Box width={'100%'} mt={'1.6rem'}>
                        <List sx={{width: '100%'}}>
                            {topBarElements.filter(e => !e.hide).map((element, index, array) => (
                                <Fragment key={JSON.stringify(element)}>
                                    {element.type === 'link' && (
                                        <ListItem sx={{
                                            width: '100%',
                                            '&:hover': {
                                                backgroundColor: colors.landing.secondary1,
                                            },
                                        }} component={Link} href={element.link}>
                                            <ListItemText sx={({breakpoints}) => ({
                                                cursor: 'pointer',
                                                textAlign: 'end',

                                                [breakpoints.down('c440')]: {
                                                    textAlign: 'center',
                                                },
                                            })}>
                                                <Typography variant={'tabText'}>{element.title}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )}

                                    {element.type === 'menu' && (
                                        <ListItem sx={{
                                            width: '100%',
                                            '&:hover': {
                                                backgroundColor: colors.landing.secondary1,
                                            },
                                        }} onClick={() => setLeftMenuDropdown(element.title)}>
                                            <ListItemText sx={({breakpoints}) => ({
                                                cursor: 'pointer',
                                                textAlign: 'end',

                                                [breakpoints.down('c440')]: {
                                                    textAlign: 'center',
                                                },
                                            })}>
                                                <Typography variant={'tabText'}>{element.title}</Typography>
                                            </ListItemText>
                                        </ListItem>
                                    )}

                                    {element.type === 'menu' && (
                                        <Collapse in={element.title === leftMenuDropdown}>
                                            {element.type === 'menu' && element.data.filter(e => !e.hide).map(element => (
                                                <ListItem key={element.title} sx={{
                                                    width: '100%',
                                                    '&:hover': {
                                                        backgroundColor: colors.landing.secondary1,
                                                    },
                                                }} component={Link} href={element.link}>
                                                    <ListItemText sx={({breakpoints}) => ({
                                                        cursor: 'pointer',
                                                        textAlign: 'end',

                                                        [breakpoints.down('c440')]: {
                                                            textAlign: 'center',
                                                        },
                                                    })}>
                                                        <Typography variant={'tabText'}>{element.title}</Typography>
                                                    </ListItemText>
                                                </ListItem>
                                            ))}
                                        </Collapse>
                                    )}

                                    <Divider/>
                                </Fragment>
                            ))}
                        </List>

                        <TopTextButton sx={{m: '', mt: '3.2rem', height: ''}} component={Link} href={PATH_LOG_IN} target={'_blank'}>
                            <Typography variant={"button"} color={colors.landing.text} sx={{textAlign: down440 ? 'center' : 'end', width: '100%'}}>{'Log in'}</Typography>
                        </TopTextButton>
                    </Box>
                </Box>
            </Drawer>
        </>
    )
}
