import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ProductRow} from "./components";
import {products} from "../../../product/constants";
import {Container} from "../../../main/components/styled";
import {TTkey} from "../../translates/arabic";
import {ANCHOR_PRODUCT} from "../../constants";


export const Product: FC<{tKey: TTkey}> = ({tKey}) => {

    const theme = useTheme();
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const t = tKey('product');

    const title = t('Software that delivers Compliance confidence');
    const description = t('Promoting responsible business practices and automatically mitigating risk of non-compliance');

    return (
        <Container flexDirection={'column'} {...(down1366 ? {mt: '16rem'} : {mt: '17rem'})} id={ANCHOR_PRODUCT}>
            <Typography variant={"h1"} textAlign={"center"}>
                {title}
            </Typography>
            <Typography variant={"body1"} textAlign={"center"} mt={'2.1rem'} >
                {description}
            </Typography>

            <Box position={"relative"} mt={'6.4rem'} {...(down1366 ? {} : {overflow: 'hidden'})}
                 sx={{
                     backgroundColor: '#F5F6FB',
                     borderRadius: '2rem',
                 }}>
                {products.map((e, index) => (
                    <ProductRow tKey={tKey} key={JSON.stringify(e)} icon={e.img} title={e.title} description={e.description} reverse={index % 2 !== 0}/>
                ))}
            </Box>
        </Container>
    )
}
