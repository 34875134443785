import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import {careerValues} from "../../../main/constants";

const title = 'We value our employees';
const description = "Join our team of compliance professionals who are committed to growth, collaboration, competitive compensation, and ethical behavior. At our company, we value the personal and professional growth of our employees and provide clear paths for career advancement.";

export const WeValueOurEmployees: FC = () => {

    const theme = useTheme();
    // const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Container mt={!down1366 ? '14rem' : '7rem'} flexDirection={'column'} alignItems={'center'}
                   sx={{position: 'relative', zIndex: 3}}>

            <Typography variant={"h1"}>
                {title}
            </Typography>
            <Typography variant={"body1"} maxWidth={'min(100%, 115.4rem)'} textAlign={"center"} mt={'1.6rem'}>
                {description}
            </Typography>

            <Box mt={'6.4rem'} width={'min(100%, 100rem)'} flexWrap={"wrap"} gap={'4rem'}
                 display={"flex"} alignItems={"center"} justifyContent={"center"}>
                {careerValues.map(item => (
                    <Box key={item.title} mt={'3.2rem'} display={'flex'} gap={'3.2rem'} width={'40.4rem'}>
                        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minWidth={'5rem'} width={'5rem'} height={'5rem'}>
                            <img src={item.icon} alt={'item icon'} style={{width: '100%', maxHeight: '100%'}}/>
                        </Box>

                        <Box display={"flex"} flexDirection={"column"} gap={'1.6rem'}>
                            <Typography variant={"h3"}>
                                {item.title}
                            </Typography>
                            <Typography variant={"body1"}>
                                {item.description}
                            </Typography>
                        </Box>
                    </Box>
                ))}
            </Box>

        </Container>
    )
}
