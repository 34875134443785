export const ANCHOR_PRODUCT = 'product';
export const ANCHOR_SUITE = 'suite';
export const ANCHOR_INTEGRATIONS = 'integrations';
export const ANCHOR_FRAMEWORKS = 'frameworks';

export const headerMenu: {title: string, link: string}[] = [
    {title: 'Product', link: ANCHOR_PRODUCT},
    {title: 'Suite', link: ANCHOR_SUITE},
    {title: 'Integrations', link: ANCHOR_INTEGRATIONS},
    {title: 'Frameworks', link: ANCHOR_FRAMEWORKS},
];