import React, {FC, useEffect, useState} from "react";
import {Box, ButtonBase, Collapse, Link, Typography} from "@mui/material";
import colors from "../../../shared/theme/colors";
import {PATH_PRIVACY_POLICY} from "../../main/constants";

export const CookiesAccept: FC = () => {

    const [cookieAccept, setCookieAccept] = useState<boolean | undefined>(false);
    const getCookieAcceptLocal = (): boolean | undefined => {
        switch (localStorage.getItem('cookieAccept')) {
            case 'true': return true;
            case 'false': return false;
            default: return undefined;
        }
    }
    const setCookieAcceptLocal = (status: boolean) => {
        setCookieAccept(status);
        // localStorage.setItem('cookieAccept', JSON.stringify(status));
    }

    useEffect(() => {
        const cookieAcceptLocal = getCookieAcceptLocal();
        setTimeout(() => {setCookieAccept(cookieAcceptLocal)}, 500)
    }, [])

    return (
        <Box width={'100vw'} bgcolor={'white'} position={"sticky"} zIndex={5}
             sx={{
                 boxShadow: '0 0 1rem #DDE8F0',
                 bottom: 0,
             }}>
            <Collapse in={cookieAccept === undefined}>
                <Box display={'flex'} flexDirection={'column'} gap={'1.8rem'} m={'0 auto'} maxWidth={'108rem'} p={'2rem'}>
                    <Typography variant={'smallText'}>
                        {
                            'This website stores cookies on your computer. ' +
                            'These cookies are used to improve your website and provide more personalised services to you, both on this website and through other media. ' +
                            'To find out more about the cookies we use, see our '
                        }
                        <Link style={{color: colors.landing.text2}} href={PATH_PRIVACY_POLICY}>{'Privacy Policy'}</Link>
                        {'.'}
                    </Typography>

                    <Box display={'flex'} justifyContent={'flex-end'} gap={'2rem'}>
                        <ButtonBase onClick={() => setCookieAcceptLocal(true)}  sx={{
                            fontWeight: 500,
                            fontSize: '1.4rem',
                            lineHeight: '2.4rem',
                            color: 'white',
                            padding: '0.8rem 1.6rem',
                            backgroundColor: '#044D9F',
                            border: '1px solid #044D9F',
                        }}>{'Accept'}</ButtonBase>
                        <ButtonBase onClick={() => setCookieAcceptLocal(false)} sx={{
                            fontWeight: 500,
                            fontSize: '1.4rem',
                            lineHeight: '2.4rem',
                            color: colors.landing.text,
                            padding: '0.8rem 1.6rem',
                            backgroundColor: 'transparent',
                            border: '1px solid #044D9F',
                        }}>{'Decline'}</ButtonBase>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    )
}