import React, {FC} from 'react';
import {Footer} from "../barsEnvironment/footer";
import {Header} from "../barsEnvironment/header";
import {IntegrationsTitle} from "./components/integrationsTitle";

export const Integrations: FC = () => {

    return (
        <>
            <Header/>
            <IntegrationsTitle/>
            <Footer/>
        </>
    )
}