import React, {FC} from "react";
import {Container, FooterListItem, UnderFooterText} from "../../main/components/styled";
import {Box, Grid, Link, List, useMediaQuery, useTheme} from "@mui/material";
import {
    footerCompany,
    footerProduct,
    PATH_PARTNERS,
    PATH_PRICING,
    PATH_PRIVACY_POLICY,
    PATH_SUITE,
    PATH_TERMS_AND_CONDITIONS
} from "../../main/constants";
import {SocialButtons} from "./components/socialButtons";

export const Footer: FC = () => {
    const theme = useTheme();
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));
    const down800 = useMediaQuery(theme.breakpoints.down('c800'));

    const Home = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={''}>{'Home'}</FooterListItem>
        </List>
    )

    const AboutUs = () => (
        <List>
            <FooterListItem isTitle>{'About us'}</FooterListItem>
            <FooterListItem>{"Regulait makes the Compliance process a task by establishing a consistent control analysis and automated procedures across every framework and regulation adherence."}</FooterListItem>
        </List>
    )

    const Contacts = () => (
        <>
            <List>
                <FooterListItem isTitle>{'Contacts'}</FooterListItem>
                <FooterListItem isLink><a href={"mailto: hello@regulait.com"}>{'hello@regulait.com'}</a></FooterListItem>
            </List>
            <List>
                <FooterListItem isTitle>{'Legal Inquiries'}</FooterListItem>
                <FooterListItem isLink><a href={"mailto: legal@regulait.com"}>{'legal@regulait.com'}</a></FooterListItem>
            </List>
            <List>
                <FooterListItem isTitle>{'Investor Relations '}</FooterListItem>
                <FooterListItem isLink><a href={"mailto: investors@regulait.com"}>{'investors@regulait.com'}</a></FooterListItem>
            </List>
        </>
    )

    const Suite = () => (
        <List>
            <FooterListItem isTitle component={Link} href={PATH_SUITE}>{'Suite'}</FooterListItem>
        </List>
    )

    const Product = () => (
        <List>
            <FooterListItem isTitle>{'Product'}</FooterListItem>
            {footerProduct.map(e => (<FooterListItem key={e.title} isPath component={Link} href={e.path}>{e.title}</FooterListItem>))}
        </List>
    )

    const Company = () => (
        <List>
            <FooterListItem isTitle>{'Company'}</FooterListItem>
            {footerCompany.map(e => (<FooterListItem key={e.title} isPath component={Link} href={e.path}>{e.title}</FooterListItem>))}
        </List>
    )

    const Price = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={PATH_PRICING}>{'Pricing'}</FooterListItem>
        </List>
    )

    const Partners = () => (
        <List>
            <FooterListItem isTitle isPath component={Link} href={PATH_PARTNERS}>{'Partners'}</FooterListItem>
        </List>
    )

    const UnderFooter = () => (
        <>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"} gap={'3.2rem'}>
                <UnderFooterText isLink component={Link} href={PATH_TERMS_AND_CONDITIONS}>{'Terms and conditions'}</UnderFooterText>
                <UnderFooterText isLink component={Link} href={PATH_PRIVACY_POLICY}>{'Privacy policy'}</UnderFooterText>
            </Box>
            <UnderFooterText textAlign={'center'} mt={'0.8rem'}>{'© All rights reserved to Regulait, 2023'}</UnderFooterText>
        </>
    )

    if (down800) return (
        <Container mt={'15rem'} flexDirection={'column'}>
            <Grid container columns={12}>
                <Grid item xs={6}>
                    <AboutUs/>
                    <SocialButtons/>
                    <Contacts/>
                </Grid>

                <Grid item xs={6}>
                    <Product/>
                    <Suite/>
                    <Price/>
                    <Partners/>
                    <Company/>
                </Grid>
            </Grid>

            <UnderFooter/>
        </Container>
    );

    if (down1366) return (
        <Container mt={'12rem'} flexDirection={'column'}>
            <Grid container columns={12}>
                <Grid item xs={4}>
                    <AboutUs/>
                    <SocialButtons/>
                </Grid>

                <Grid item xs={4}>
                    <Product/>
                    <Suite/>
                    <Price/>
                    <Partners/>
                </Grid>

                <Grid item xs={4}>
                    <Company/>
                    <Contacts/>
                </Grid>
            </Grid>

            <UnderFooter/>
        </Container>
    );

    return (
        <Container mt={'17rem'} flexDirection={'column'}>
            <Grid container columns={12}>
                <Grid item xs={3}>
                    <AboutUs/>
                    <SocialButtons/>
                </Grid>

                <Grid item xs={1}/>

                <Grid item xs={2}>
                    <Home/>
                    <Product/>
                </Grid>

                <Grid item xs={2}>
                    <Suite/>
                    <Partners/>
                    <Price/>
                </Grid>

                <Grid item xs={2}>
                    <Company/>
                </Grid>

                <Grid item xs={2}>
                    <Contacts/>
                </Grid>
            </Grid>

            <UnderFooter/>
        </Container>
    )
}
