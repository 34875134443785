// Core
import {combineReducers} from 'redux';

// Reducers
import {snackReducer} from "../../domain/snack/store/slice";


export const rootReducer = combineReducers({
    snack: snackReducer,
}); 


export type AppState = ReturnType<typeof rootReducer>;
