import React, {FC, Fragment, useEffect, useState} from "react";

import {ReactComponent as RegulaitLogo} from "../../../../shared/images/new/logoRegulait.svg";
import {
    AppBar,
    Box,
    Button,
    CssBaseline,
    Divider,
    Drawer,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Menu,
    MenuItem,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Close, KeyboardArrowDown, KeyboardArrowUp, Language, MenuRounded as MenuIcon} from "@mui/icons-material";
import {ElevationScroll, TopTextButton} from "../../../barsEnvironment/styled";
import {Container} from "../../../main/components/styled";
import {languages, PATH_LOG_IN, PATH_REGISTRATION} from "../../../main/constants";
import colors from "../../../../shared/theme/colors";
import {TTkey} from "../../translates/arabic";
import {headerMenu} from "../../constants";
import {useLocation} from "react-router";


export const Header: FC<{tKey: TTkey}> = ({tKey, ...props}) => {

    const {pathname} = useLocation();
    const theme = useTheme();
    const down440 = useMediaQuery(theme.breakpoints.down('c440'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const [anchorEl, setAnchorEl] = useState<null | {element: HTMLElement, title: string}>(null);
    const handleDropdownOpen = (title: string) => (event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>) => {
        setAnchorEl({element: event.currentTarget, title});
    };
    const handleDropdownClose = () => {
        setAnchorEl(null);
    };

    const [menuOpen, setOpen] = useState<boolean>(false);
    useEffect(() => {
        setOpen(false);
        handleDropdownClose();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [down1366])

    // const {
    //     // handleHistoryNewTab, handleHistoryThisTab,
    //     // handleHistoryAnchor: handleHistoryPush,
    //     // handleHistoryPush: historyPush
    // } = useRoutes({func: () => {setOpen(false); handleDropdownClose()}});

    const t = tKey('header');
    const tCommon = tKey('common');
    return (
        <>
            <CssBaseline />
            <ElevationScroll {...props}>
                <AppBar color={"transparent"} >
                    <Container sx={{justifyContent: 'space-between', alignItems: 'center', gap: '3.2rem'}}>
                        <Box display={"flex"} alignItems={"center"} gap={'1.6rem'}>
                            <Link href={''}>
                                <RegulaitLogo className={'logo'} style={{cursor: 'pointer', maxWidth: 'min(16.3rem, 100%)', }}/>
                            </Link>

                            {!down1366 && (
                                <IconButton onClick={handleDropdownOpen('language')}
                                            sx={{color: colors.landing.text, direction: 'ltr', paddingLeft: '2rem', paddingRight: '2rem'}}
                                            size={"large"}
                                >
                                    <Language/>
                                </IconButton>
                            )}
                            <Menu open={anchorEl?.title === 'language'} onClose={handleDropdownClose}
                                  anchorEl={anchorEl?.element}
                                  anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
                                  transformOrigin={{vertical: 'top', horizontal: 'center'}}>
                                {languages.map(element => (
                                    <MenuItem key={JSON.stringify(element)} component={Link} href={window.location.origin + element.path}
                                              sx={{minWidth: anchorEl?.element.offsetWidth, textAlign: 'center'}}>
                                        {element.title}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                        {!down1366 && (
                            <Box display={"flex"} alignItems={"center"} gap={'5.4rem'}>
                                {headerMenu.map((element, index, array) => (
                                    <Fragment key={JSON.stringify(element)}>
                                        <TopTextButton component={Link} href={pathname + '#' + element.link}>
                                            <Typography variant={'tabText'}>{t(element.title)}</Typography>
                                        </TopTextButton>
                                    </Fragment>
                                ))}
                            </Box>
                        )}

                        <Box display={"flex"} alignItems={"center"} gap={!down1366 ? '3.2rem': '0.9rem'}>
                            {!down1366 && (
                                <TopTextButton component={Link} href={PATH_LOG_IN} target={'_blank'}>
                                    <Typography variant={"button"} color={colors.landing.text}>{tCommon('Log in')}</Typography>
                                </TopTextButton>
                            )}

                            <Button variant={"contained"} size={"medium"} sx={{whiteSpace: 'nowrap'}} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                                {tCommon('Sign up')}
                            </Button>

                            {down1366 && (
                                <IconButton size={"large"} color={"primary"}
                                            onClick={() => setOpen(prev => !prev)}>
                                    <MenuIcon/>
                                </IconButton>
                            )}
                        </Box>
                    </Container>
                </AppBar>
            </ElevationScroll>

            <Drawer
                anchor={"left"}
                open={menuOpen}
                onClose={() => setOpen(false)}
            >
                <Box p={'1rem 2rem'} width={!down440 ? '400px' : '100vw'} display={"flex"} flexDirection={'column'} alignItems={'flex-end'}>
                    <Box display={"flex"} alignItems={"center"} gap={!down1366 ? '3.2rem': '0.9rem'}>
                        <Button variant={"contained"} size={"medium"} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                            {tCommon('Sign up')}
                        </Button>

                        <IconButton size={"large"} color={"primary"}
                                    onClick={() => setOpen(prev => !prev)}>
                            <Close/>
                        </IconButton>
                    </Box>

                    <Button variant={"text"} onClick={handleDropdownOpen('language')}
                            sx={{
                                color: colors.landing.text, direction: 'ltr', paddingLeft: '2rem', paddingRight: '2rem',
                                m: '3.2rem auto 0'
                    }}
                            startIcon={<Language/>} size={"medium"}
                            endIcon={anchorEl?.title === 'language' ? <KeyboardArrowUp/> : <KeyboardArrowDown/>}>
                        {t('Language')}
                    </Button>

                    <Box width={'100%'} mt={'1.6rem'}>
                        <List sx={{width: '100%'}}>
                            {headerMenu.map((element, index, array) => (
                                <Fragment key={JSON.stringify(element)}>
                                    <ListItem sx={{
                                        width: '100%',
                                        '&:hover': {
                                            backgroundColor: colors.landing.secondary1,
                                        },
                                    }}  component={Link} href={pathname + '#' + element.link}>
                                        <ListItemText sx={({breakpoints}) => ({
                                            cursor: 'pointer',
                                            textAlign: 'end',

                                            [breakpoints.down('c440')]: {
                                                textAlign: 'center',
                                            },
                                        })}>
                                            <Typography variant={'tabText'}>{t(element.title)}</Typography>
                                        </ListItemText>
                                    </ListItem>

                                    <Divider/>
                                </Fragment>
                            ))}
                        </List>
                    </Box>
                </Box>
            </Drawer>
        </>
    )
}
