import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";

import Lines from "../../../../shared/images/new/lines.svg"
import Frameworks from "../../../../shared/images/new/frameworks.png";

const title = 'Maintain an effective data protection strategy';
const description = "At Regulait, we understand the importance of compliance and the challenges it can pose for businesses. That's why we offer a range of pre-built compliance frameworks that helps you meet your regulatory and legal obligations.\n" +
    "Our expert team has crafted each framework with the utmost care and attention to detail, ensuring that they meet the highest standards of quality and compliance. Whether you need to comply with GDPR, ISO 27001, SOC 2, or any other regulation, we have the framework to suit your needs.";

export const FrameworksTop: FC = () => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box position={'relative'} >
            <img src={Lines} alt={''} style={{
                position: 'absolute', top: 0, left: '0', zIndex: 0,
                width: '140%'
            }}/>

            <Container mt={!down1366 ? '14rem' : '10rem'} display={'flex'} alignItems={'center'} {...(down1024 ? {flexDirection: 'column'} : {})}
                       sx={{overflowX: 'hidden', overflowY: 'visible', position: 'relative'}}
                       pb={'1rem'}>

                <Box display={'flex'} flexDirection={'column'} flexBasis={'50%'}>
                    <Typography variant={'h1'} textAlign={'start'}>
                        {title}
                    </Typography>

                    <Box width={'100%'} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                        {description.split('\n').map((e) => (
                            <Typography variant={"body1"} textAlign={'start'} key={e}>
                                <br/>{e}
                            </Typography>
                        ))}
                    </Box>
                </Box>


                <Box display={'flex'} flexDirection={"column"} alignItems={"center"} flexBasis={'50%'}>
                    <img src={Frameworks} alt={'Frameworks icon'} style={{width: '100%', position: "relative", zIndex: 3}}/>
                </Box>
            </Container>
        </Box>
    )
}
