import React, {FC} from "react";
import {Box, Link} from "@mui/material";
import Facebook from "../../../../../shared/images/new/contactFacebook.svg";
import Twitter from "../../../../../shared/images/new/contactTwitter.svg";
import Linkedin from "../../../../../shared/images/new/contactLinkedin.svg";
import {PATH_FACEBOOK, PATH_LINKEDIN, PATH_TWITTER} from "../../../../main/constants";

export const SocialButtons: FC = () => {

    return (
        <Box display={"flex"} alignItems={"center"}>
            <Link href={PATH_FACEBOOK} target={'_blank'}><img src={Facebook} alt={'Facebook'} style={{cursor: 'pointer', maxHeight: '6.2rem'}}/></Link>
            <Link href={PATH_TWITTER} target={'_blank'}><img src={Twitter}  alt={'Twitter'}  style={{cursor: 'pointer', maxHeight: '6.2rem'}} /></Link>
            <Link href={PATH_LINKEDIN} target={'_blank'}><img src={Linkedin} alt={'Linkedin'} style={{cursor: 'pointer', maxHeight: '6.2rem'}}/></Link>
        </Box>
    )
}