import {TTranslation} from "../types";

export const translate = {
    'common' : {
        'Log in': 'התחברות',
        'Sign up': 'צור חשבון',
        'Start free': 'התחל בחינם',
        'Start now': 'התחל בחינם',
        'Try free': 'התחל בחינם',
        "Submit": "שלח",
        "Language": "Language",
    },

    'header': {
        'Home': 'בית',
        'Product': 'מוצר',
        'Suite': 'מערכות תמיכה',
        'Integrations': 'אינטגרציות',
        'Frameworks': 'תקנים',

        'Contacts': 'יצירת קשר',
        'Legal Inquiries': 'נושאים משפטיים',
        'Investor Relations': 'קשרי משקיעים',

        'Terms and conditions': 'תנאים והגבלות',
        'Privacy policy': 'פרטיות',

        '© All rights reserved to Regulait, 2023': 'כל הזכויות שמורות לרגולייט',

        'About us': 'אודותינו',
        "Regulait makes the Compliance process a task by establishing a consistent control analysis and automated procedures across every framework and regulation adherence.": "רגיולייט הופכת את תהליך העמידה בתקן (רגולציה) והציות למשימה ע\"י פענוח ובקרה רצופים, בנוסף לתהליכים אוטומטיים, לשם ציות לכל תקן ורגולציה",
    },

    'top': {
        'Streamline Compliance': 'ייעול ואוטומציה להבטחת ציות (קומפליינס)',
        'Automate Assurance': '',
        'Easily manage and automate your compliance needs with our all-in-one suite. The only solution that provides a complete suite of systems for compliance assurance.': 'נהל ללא מאמץ באמצעות תהליכים אוטומטיים את צורכי הציות שלך עם חבילת ה-All-in-One שלנו. הפתרון היחיד המספק חבילה שלמה של מערכות להבטחת ציות.',
    },

    'product': {
        "Software that delivers Compliance confidence": "תוכנה שמספקת ביטחון בציות ועמידה בתקנים מסחריים ורגולטוריים מרובים",
        "Promoting responsible business practices and automatically mitigating risk of non-compliance": "קידום שיטות פעולה עסקיות אחראיות והפחתה אוטומטית של הסיכון לאי-ציות ואי-עמידה בתקן.",

        "Cross framework mapping": "מיפוי בין תקנים",
        "Built-in cross-framework mappings allow you to reuse integration data, evidence & policies, across multiple regulatory and industry frameworks such as ISO27001, NIST, PCI DSS and more, cutting down the completion time and resources by up to 80%.\nThe goal is to simplify the compliance process by allowing you to leverage the controls and best practices from one framework to meet the requirements of another framework.": "מיפויים מובנים בין תקנים צולבים מאפשרים לך לעשות שימוש חוזר בנתוני אינטגרציה, ראיות ומדיניות, על פני תקנים רגולטוריים ומסחריים מרובים כגון ISO27001, NIST, PCI DSS ועוד, ולקצץ את זמן ההשלמה והמשאבים בשיעור של עד 80%. המטרה היא לפשט את תהליך הציות על ידי מינוף הבקרות והשיטות המומלצות מתקן אחד כדי לעמוד בדרישות של תקן אחר.",

        "Document library & lifecycle automation": "אוטומציה במעקב וניהול המסמכים",
        "A rich library of standardized document templates that outline the guidelines, rules, and procedures for a particular area of business operations or compliance requirements, and can be used as a starting point to create your specific documents and policies. Document lifecycle can now be completely automatic using Regulait automation pipelines. Sharing, reviewing or signoff, all included.": "ספרייה עשירה של תבניות מסמכים סטנדרטיות המתארות את ההנחיות, הכללים והנהלים עבור תחום מסוים של פעילות עסקית או דרישות ציות. ניתן להשתמש בהן כנקודת התחלה ליצירת מסמכים ספציפיים הנוגעים למדיניות שלך. מחזור חיי המסמך אוטומטי לחלוטין באמצעות הטכנולוגיה של רגיולייט. שיתוף, סקירה או חתימה - הכול כלול.",

        "Audit space": "מרחב הביקורת",
        "Get faster, more efficient audits, save hours of back-and-forth communication, never misplace crucial evidence, and share documentation instantly. All in one, intuitive suite. Regulait Reporting can be used to securely share sensitive content with official auditors, customers or prospects.": "קבל ביקורות מהירות ויעילות יותר, חסוך שעות של תקשורת הלוך ושוב, שמור ראיות לצמיתות ושתף תיעוד באופן מידי. הכול בחבילה אחת אינטואיטיבית. ניתן להשתמש ב-Regulait Reporting כדי לשתף בצורה מאובטחת תוכן רגיש עם מבקרים רשמיים, לקוחות או לקוחות פוטנציאליים.",

        "Automatic evidence collection & analysis": "איסוף וניתוח ראיות אוטומטיים",
        "Benefit with less time and resources spent on manual data collection, avoid errors and inconsistencies in the analysis process. Now you can automatically track changes and updates to your compliance posture and quickly identify and address any compliance gaps. Demonstrate a commitment to responsible business practices with all stakeholders.": "חסוך זמן ומשאבים המושקעים באיסוף נתונים ידני ובכך הימנע משגיאות וחוסר עקביות בתהליך הניתוח. כעת ניתן לעקוב אוטומטית אחר שינויים ועדכונים במצב הציות שלך, ולזהות ולטפל במהירות בכל פערי ציות. התנהל באופן אחראי ומסודר עם כל הגורמים הרלוונטיים.",

        "VICA": "VICA (עוזרת ציות וירטואלית – Virtual Compliance Assistant)",
        "An automated compliance support and guidance system. Using machine learning algorithms to provide relevant information, guidance, and support to manage compliance posture, understand and respond to inquiries and more. Regulait VICA can provide step-by-step guidance for compliance processes, and describe specific procedures to ensure compliance requirements are met. VICA is a cost-effective and convenient alternative to traditional compliance support, helping organizations stay informed and up-to-date on their compliance posture, required remediation tasks or updates to current regulatory requirements.": "מערכת תמיכה והדרכה אוטומטית לעמידה בציות. שימוש באלגוריתמים של למידת מכונה בכדי לספק מידע רלוונטי, הדרכה ותמיכה לניהול עמדת ציות, הבנה והיענות לפניות ועוד. VICA יכולה לספק הנחיות שלב אחר שלב לתהליכי ציות ולתאר נהלים ספציפיים כדי להבטיח שדרישות הציות מתקיימות. VICA מהווה אלטרנטיבה חסכונית ונוחה לתמיכה מסורתית בציות, המסייעת לארגונים להישאר מעודכנים לגבי עמדת הציות שלהם, משימות תיקון נדרשות או עדכונים לדרישות הרגולטוריות הנוכחיות.",

        "Collaboration": "שיתופי פעולה",
        "Regulait collaboration functionality makes sure that all relevant parties and stakeholders work together to develop effective compliance strategies and processes, share best practices, and ensure that everyone is aware of their responsibilities. Improve the overall effectiveness and efficiency of an organization's compliance program by building trust with stakeholders, customers, and regulators through transparency and accountability.": "פונקציית שיתוף הפעולה של רגיולייט מוודאת שכל הצדדים ובעלי העניין הרלוונטיים עובדים יחד כדי לפתח אסטרטגיות ותהליכים אפקטיביים של ציות, לשתף שיטות עבודה מומלצות ולהבטיח שכולם מודעים לאחריותם. שפר את האפקטיביות והיעילות הכוללת של תוכנית הציות ע\"י בניית אמון עם בעלי עניין, לקוחות ורגולטורים באמצעות שקיפות ואחריות.",
    },

    'suite': {
        'Compliance is easier together': "מערכת ציות שגדלה עם העסק שלך",
        "At Regulait, we understand the challenges of maintaining compliance in today's fast-paced business world. That's why we've created the Ultimate Compliance Solution - a comprehensive platform that brings all your compliance-related tasks under one roof.": "מערכת שליטה אחודה שמשלבת מערכת לניהול משאבי ארגון (ERP), אוטומציה לשליטה ואיסוף ראיות, מרחב ביקורת, פורטל לשירות עצמי ועוד.",

        "HR management": "ניהול משאבי אנוש",
        "Manage and automate HR processes and ensure compliance with laborlaws and regulations, save time, store employee information organized, and create data-driven reports. Use features like employee onboarding, employee data management, performance evaluation, and compensation.": "נהל והשתמש באוטומציה של תהליכי כוח אדם, ודא ציות לחוקי ותקנות התעסוקה, חסוך בזמן, אחסן מידע אודות העובדים והפק דוחות מידע שונים. השתמש בפיצ׳רים כגון קליטת עובדים, ניהול מידע אודות העובדים, הערכת עובדים ופיצויים.",

        "Asset management": "ניהול נכסים",
        "Ensure operational assets and equipment are in place and tracked. Optimize the quality and utilization of assets throughout their lifecycle, increase productive uptime and reduce operational costs. Using features like asset tracking, inventory management, and maintenance scheduling.": "ודא כי נכסים אופרציונליים וציוד נמצאים במקומם ותחת מעקב. שפר את איכות ואופן השימוש בנכסים לכל אורך מחזור החיים שלהם, הגבר את הפרודוקטיביות וזמן הפעולה הפרודוקטיבי שלהם, והפחת עלויות התפעול. השתמש בפיצ'רים כגון מעקב אחר נכסים, ניהול מלאי וניהול זמני תחזוקה.",

        "Risk management": "ניהול סיכונים",
        "Identify, assess, and mitigate risks, monitor and track them, improve communication and collaboration, ensure compliance, and ultimately reduce the likelihood and impact of negative events. Use features like multiple risk registries, risk assessment, and risk owners collaboration.": "זהה, הערך וצמצם סיכונים, נטר ועקוב אחריהם, שפר את התקשורת ושיתוף הפעולה, ודא ציות, ועל ידי כך הפחת את הסבירות וההשפעה של אירועים שליליים. השתמש בפיצ'רים כגון רישום סיכונים מרובים, הערכת סיכונים ושיתוף פעולה של בעלי סיכונים.",

        "Vulnerability management": "ניהול פגיעוּת",
        "Quickly identify security vulnerabilities, ensure comprehensive coverage, prioritize remediation efforts, and maintain compliance with relevant regulations. Scan your external facing systems for known vulnerabilities and provide you with a list of issues that need to be addressed, freeing up your team to focus on other tasks.": "זהה במהירות פרצות אבטחה, הבטח כיסוי מקיף, תעדף מאמצי תיקון ושמור על עמידה בתקנות הרלוונטיות. סרוק את המערכות הפונות כלפי חוץ לאיתור נקודות תורפה ידועות והפק רשימה של בעיות שיש לטפל בהן, ובכך הקצה את משאבי הצוות שלך למשימות הנכונות.",

        "Vendor management": "ניהול ספקים",
        "Take necessary measures for cost control, risk reduction, service deliverability assurance and generating value from vendors. That includes identifying suitable vendors, monitoring the quality of work, evaluating performance and ensuring value for services delivered.": "נקוט באמצעים הדרושים לשליטה בעלויות, הפחתת סיכונים, הבטחת אספקת השירות ויצירת ערך מהספקים. זה כולל פיצ'רים לזיהוי הספקים המתאימים, ניטור איכות העבודה, הערכת ביצועים והבטחת ערך לשירותים המסופקים.",

        "Access management": "ניהול גישה",
        "Improve security, compliance, cost savings, and productivity by controlling who has access to what resources within your organization. Ensure that only authorized users can access sensitive data, systems and applications, and meet regulatory requirements.": "שפר את האבטחה, הציות, החיסכון בעלויות והפרודוקטיביות על ידי ניהול השליטה למשאבי הארגון שלך. ודא כי רק למשתמשים מורשים תהיה גישה לנתונים, מערכות ויישומים רגישים, וכי הם עומדים בדרישות הרגולטוריות.",
    },

    "complianceJourney": {
        "Begin your compliance journey today": "התחל היום את המסע שלך לציות מלא",
    },

    "integrations": {
        "Integrations": "אינטגרציות",
        "Regulait integrates with dozens of services. With these integrations, you'll have access to a wide range of tools and services to help you manage your compliance efforts more effectively. From cloud storage solutions to secure communications platforms, you'll have everything you need to ensure your compliance management is always up-to-date.": "רגיולייט מבצעת אינטגרציות של עשרות שירותים. עם האינטגרציות הללו, תהיה לך גישה למגוון רחב של כלים ושירותים שיעזרו לך לנהל את מאמצי הציות שלך בצורה יעילה יותר. מפתרונות אחסון בענן ועד לפלטפורמות תקשורת מאובטחות - כל מה שתצטרך כדי להבטיח שניהול הציות שלך תמיד מעודכן.",
    },

    "frameworks": {
        "Built-in Framework Library": "ספריית מובנית של תקנים ורגלוציות",
        "We offer a wide range of compliance frameworks and add new frameworks monthly to help you streamline your compliance operations and maintain an effective Compliance posture strategy.": "אנו מציעים מגוון תקני ציות ומוסיפים תקנים חדשים מדי חודש כדי לעזור לך לייעל את פעולות הציות שלך ולשמור על אסטרטגיית ציות אפקטיבית.",

        "Standard for implementing and maintaining an Information Security Management System (ISMS) that is designed to protect the confidentiality, integrity, and availability of information assets.": "תקן להטמעה ותחזוקה של מערכת ניהול אבטחת מידע (ISMS) שנועד להגן על הסודיות, היושרה והזמינות של נכסי מידע.",
        "Standard that provides guidelines for cloud service providers and customers on how to implement information security controls specifically for cloud computing environments. It is an extension to the ISO 27001 standard.": "תקן המספק הנחיות לספקי שירותי ענן וללקוחות כיצד ליישם בקרות אבטחת מידע, במיוחד עבור סביבות מחשוב ענן. זוהי הרחבה לתקן ISO 27001.",
        "Standard that provides guidelines for implementing information security management in healthcare organizations. Based on the ISO 27001 standard and focuses specifically on the security challenges faced by healthcare organizations.": "תקן המספק הנחיות ליישום ניהול אבטחת מידע בארגוני בריאות. מבוסס על תקן ISO 27001 ומתמקד באתגרי האבטחה עמם מתמודדים ארגוני בריאות.",
        "SOC1 Audit report provides assurance of a service organization that are relevant to their customers financial reporting. It is commonly used by companies that provide outsourced services such as payroll processing, data center operations, or financial transaction processing.": "דוח ביקורת המעניק ביטחונות לארגון שירות הרלוונטי לדיווח הכספי של לקוחותיו. נמצא בשימוש נפוץ על ידי חברות המספקות שירותים במיקור חוץ כגון חשבות שכר, תפעול מרכזי נתונים או עיבוד עסקאות פיננסיות.",
        "SOC2 Audit report is designed to provide assurances about the effectiveness of controls in place at a service organisation that are relevant to the security, availability, or processing integrity of the system used to process clients information, or the confidentiality or privacy of that information.": "דוח ביקורת הנועד לספק ביטחונות לגבי האפקטיביות של הבקרות הקיימות בארגון המעניק שירות רלוונטי לאבטחה, לזמינות או לשלמות העיבוד של המערכת המשמשת לעיבוד מידע של לקוחות, או לסודיות ולפרטיות של מידע זה.",
        "Sarbanes-Oxley Information Technology General Controls - Set of guidelines designed to ensure the accuracy and reliability of financial reporting by improving IT systems security and integrity, ultimately increasing investor confidence and reducing corporate fraud.": "חוק פדרלי בארצות הברית המחייב חברות ציבוריות להקים ולתחזק בקרה פנימית אפקטיבית על דיווח כספי. בקרות ITGC SOX נועדו להבטיח שמערכות מידע פועלות בצורה ויעילה וחסרת מאמץ, ושהנתונים הפיננסיים שנוצרו על ידי מערכות אלו מדויקים ואמינים.",

        "Build your compliance framework": "בנה תקני ציות משלך",
        "In addition to offering a range of pre-built compliance frameworks, we also provide our users with the ability to build custom frameworks. We understand that every business has unique security and privacy needs, and that a one-size-fits-all approach may not be suitable for everyone. That's why we've made it easy for you to build a framework that aligns with your specific regulatory requirements.\nOur platform offers a simple yet powerful interface that allows you to create and customize your compliance framework. You can select from a variety of controls, policies, and procedures that are tailored to your business needs. Our user-friendly interface makes it easy for you to add, modify, and remove controls as necessary, so you can stay up-to-date with any changes to your regulatory requirements.": "בנוסף להצעת מגוון תקני ציות מובנים, אנו מספקים למשתמשים שלנו גם את היכולת לבנות תקנים מותאמים אישית משלהם. אנו מבינים שלכל עסק יש צרכי אבטחה ופרטיות ייחודיים, ושתקן אחד לא יכול להתאים לכולם. זו הסיבה שבעטיה יצרנו פלטפורמת לבנייה ועריכה של תקנים, כך שיתאמו את הדרישות הרגולטוריות הספציפיות שלך." +
            " \n" +
            "הפלטפורמה שלנו מציעה ממשק פשוט אך רב עוצמה המאפשר לך ליצור ולהתאים אישית את תקן הציות שלך. אתה יכול לבחור מתוך מגוון של בקרות, סעיפי מדיניות ונהלים שכבר מוגדרים במערכת כדי להתאים לצרכים העסקיים שלך."
    },

    // 'faq': {
    //     "Frequently asked questions": "Frequently asked questions",
    //
    //     "Why compliance is such important?": "Why compliance is such important?",
    //     "Here1": "Here",
    //
    //     "What are the advantages to choose Regulait software?": "What are the advantages to choose Regulait software?",
    //     "Here2": "Here",
    //
    //     "What level of access will Regulait have to my systems and data?": "What level of access will Regulait have to my systems and data?",
    //     "Here3": "Here",
    // },

    "contact_us": {
        "First name": "שם פרטי",
        "Last name": "שם משפחה",
        "E-mail": "דואר אלקטרוני",
        "Company name": "שם החברה",
        "Phone number": "Phone number",
        'Your Message...': 'ההודעה שלך...',
        "I agree to the Terms and Conditions and Privacy Policy.": "אני מסכים לתנאי השירות ולמדיניות הפרטיות",

        "Submit": "שלח",

        "Do you have any questions?": "יש לך שאלות?",
        "Feel free to talk to us if you have any questions. Just fill the form and we will get in touch with you shortly.": "אל תהסס לדבר איתנו אם יש לך שאלות כלשהן. מלא את הטופס ואנו ניצור איתך קשר בהקדם.",
    },

} satisfies Record<string, TTranslation>;

export const tKey = (key: string) => (str: string) => (((translate as Record<string, TTranslation>)?.[key] as Record<string, string>)?.[str]) ?? str;
