import {TComment, TFaq, TTariff, TTitleDescriptionIcon, TTopBarElement} from "./types";

import Comwell from "../../shared/images/new/businesses/comwell.png";
import Shing from "../../shared/images/new/businesses/shing.svg";
import Opus from "../../shared/images/new/businesses/opus.svg";
import Plurilogic from "../../shared/images/new/businesses/plurilogic.png";
import Sterling from "../../shared/images/new/businesses/sterling.png";

// import {ReactComponent as EyeIcon} from "../../shared/images/new/eye.svg";
// import {ReactComponent as EncryptionIcon} from "../../shared/images/new/encryption.svg";
// import {ReactComponent as StatusUpIcon} from "../../shared/images/new/statusup.svg";
import EyeIcon from "../../shared/images/new/eye.svg";
import EncryptionIcon from "../../shared/images/new/encryption.svg";
import StatusUpIcon from "../../shared/images/new/statusup.svg";

// import {ReactComponent as FavoriteIcon} from "../../shared/images/new/carbon_user-favorite-alt-filled.svg";
// import {ReactComponent as GrowIcon} from "../../shared/images/new/grow.svg";
// import {ReactComponent as AwardIcon} from "../../shared/images/new/mdi_award.svg";
import FavoriteIcon from "../../shared/images/new/carbon_user-favorite-alt-filled.svg";
import GrowIcon from "../../shared/images/new/grow.svg";
import AwardIcon from "../../shared/images/new/mdi_award.svg";

import MicroERP from "../../shared/images/new/Micro ERP.png";
import Audit from "../../shared/images/new/audit.png";
import Framework from "../../shared/images/new/framework.png";

import Github from "../../shared/images/new/integrations/github.svg";
import Gitlab from "../../shared/images/new/integrations/gitlab.svg";
import GCP from "../../shared/images/new/integrations/gcp.svg";
import Snyk from "../../shared/images/new/integrations/snyk.svg";
import Zendesk from "../../shared/images/new/integrations/zendesk.svg";
import BambooHr from "../../shared/images/new/integrations/bamboohr.svg";
import Aws from "../../shared/images/new/integrations/aws.svg";
import Tenable from "../../shared/images/new/integrations/tenable.svg";
import Okta from "../../shared/images/new/integrations/okta.svg";
import Atlassian from "../../shared/images/new/integrations/atlassian.svg";
import Onelogin from "../../shared/images/new/integrations/onelogin.svg";
import Azure from "../../shared/images/new/integrations/azure.svg";
import Heroku from "../../shared/images/new/integrations/heroku.svg";
import BitBucket from "../../shared/images/new/integrations/bitbucket.svg";

import Team1 from "../../shared/images/new/team/Rectangle 1482.png";
import Team2 from "../../shared/images/new/team/Rectangle 1483.png";
import Team3 from "../../shared/images/new/team/Rectangle 1484.png";
import Team4 from "../../shared/images/new/team/Rectangle 1485.png";
import Team5 from "../../shared/images/new/team/Rectangle 1486.png";
import Team6 from "../../shared/images/new/team/Rectangle 1487.png";

import SuiteHr from "../../shared/images/new/suite/hr.png";
import SuiteAsset from "../../shared/images/new/suite/asset.png";
import SuiteRisk from "../../shared/images/new/suite/risk.png";
import SuiteVulnerability from "../../shared/images/new/suite/vulnerability.png";
import SuitePortal from "../../shared/images/new/suite/portal.png";
import SuitePortalSmall from "../../shared/images/new/suite/portal.png";
import SuiteAccess from "../../shared/images/new/suite/access.png";
import SuiteVendors from "../../shared/images/new/suite/vendors.png";
import SuiteTraining from "../../shared/images/new/suite/training.png";
import SuiteTrainingSmall from "../../shared/images/new/suite/training.png";

import SuiteHrSmall from "../../shared/images/new/suite/hrSmall.svg";
import SuiteAssetSmall from "../../shared/images/new/suite/physicalSmall.svg";
import SuiteRiskSmall from "../../shared/images/new/suite/risksSmall.svg";
import SuiteVulnerabilitySmall from "../../shared/images/new/suite/vulnerabilitySmall.svg";
import SuiteAccessSmall from "../../shared/images/new/suite/accessSmall.svg";
import SuiteVendorsSmall from "../../shared/images/new/suite/vendorsSmall.svg";

import Investor1 from "../../shared/images/new/investors/investor1.png";

import ISO27001 from "../../shared/images/new/frameworks/ISO27001.svg";
import ISO27002 from "../../shared/images/new/frameworks/ISO27002.svg";
import ISO27017 from "../../shared/images/new/frameworks/ISO27017.svg";
import ISO27018 from "../../shared/images/new/frameworks/ISO27018.svg";
import ISO27032 from "../../shared/images/new/frameworks/ISO27032.svg";
import ISO27799 from "../../shared/images/new/frameworks/ISO27799.svg";
import ISO9001 from "../../shared/images/new/frameworks/ISO9001.svg";
import SOC1 from "../../shared/images/new/frameworks/SOC1.png";
import SOC2 from "../../shared/images/new/frameworks/SOC2.png";
import GDPR from "../../shared/images/new/frameworks/GDPR.png";
import SOXITGC from "../../shared/images/new/frameworks/SOXITGC.png";
import HIPPA from "../../shared/images/new/frameworks/HIPPA.png";
import PCIDSS from "../../shared/images/new/frameworks/PCIDSS.png";
import NIST80053 from "../../shared/images/new/frameworks/NIST80053.png";
import NIST800171 from "../../shared/images/new/frameworks/NIST800171.png";
import CCPA from "../../shared/images/new/frameworks/CCPA.png";
import TISAX from "../../shared/images/new/frameworks/TISAX.png";
import FEDRAMP from "../../shared/images/new/frameworks/FEDRAMP.png";
import CSC from "../../shared/images/new/frameworks/CSC.png";
import SHARIAT from "../../shared/images/new/frameworks/shariat.png";

import Blog1 from "../../shared/images/new/blog/Rectangle 1484.png";
import Blog2 from "../../shared/images/new/blog/Rectangle 1484 (1).png";
import Blog3 from "../../shared/images/new/blog/Rectangle 1484 (2).png";

import Rocket from "../../shared/images/new/careers/rocket.svg";
import Community from "../../shared/images/new/careers/community.svg";
import Blocks from "../../shared/images/new/careers/blocks.svg";
import Fun from "../../shared/images/new/careers/fun.svg";

export const PATH_ARABIC = '/ar';
export const PATH_HEBREW = '/he';
export const PATH_FRANCE = '/fr';

export const PATH_TERMS_AND_CONDITIONS = '/terms';
export const PATH_PRIVACY_POLICY = '/privacy';
export const PATH_TERMS_OF_SERVICE = '/tos';
export const PATH_EULA = '/eula';

export const PATH_PRODUCT = '/product';
export const PATH_SUITE = '/suite';
export const PATH_PARTNERS = '/partners';
export const PATH_FRAMEWORKS = '/frameworks';
// export const PATH_RESOURCES = '/resources';
export const PATH_PRICING = '/pricing';
export const PATH_COMPANY = '/company';
export const PATH_INTEGRATIONS = '/integrations';
export const PATH_CONTACT_US = '/contact_us';
export const PATH_BLOG = '/blog';
export const PATH_COMPARE = '/compare';
export const PATH_CUSTOMERS_STORIES = '/customers_stories';
export const PATH_NEWS = '/news';
export const PATH_HELP = '/help';
export const PATH_CAREERS = '/careers';

export const PATH_RESOURCES = PATH_INTEGRATIONS;

export const PATH_LOG_IN = 'https://app.regulait.com';
export const PATH_REGISTRATION = 'https://app.regulait.com/signup';
export const PATH_LINKEDIN = 'https://www.linkedin.com/company/regulait/';
export const PATH_TWITTER = 'https://twitter.com/BeRegulait';
export const PATH_FACEBOOK = 'https://www.facebook.com/regulait';

export const languages = [
    {title: 'English', path: ''},
    {title: 'العربية', path: PATH_ARABIC},
    {title: 'עברית', path: PATH_HEBREW},
];
export const rlt: string[] = [PATH_ARABIC, PATH_HEBREW];

export const emailRegex = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const topBarElements: TTopBarElement[] = [
    {title: 'Product',      type: 'menu', data: [
            {title: 'Regulait Platform', link: PATH_PRODUCT, type: 'link'},
            {title: 'Frameworks', link: PATH_FRAMEWORKS, type: 'link'},
            {title: 'Integrations', link: PATH_INTEGRATIONS, type: 'link'},
            // {title: 'Compare', link: PATH_COMPARE, type: 'link'},
        ]},
    {title: 'Suite',        type: 'link', link: PATH_SUITE},
    {title: 'Pricing',      type: 'link', link: PATH_PRICING},
    {title: 'Partners',     type: 'link', link: PATH_PARTNERS},
    {title: 'Resources',    type: 'menu', hide: true, data: [
            {title: 'Blog', link: PATH_BLOG, type: 'link'},
            // {title: 'Customer stories', link: PATH_CUSTOMERS_STORIES, type: 'link'},
            // {title: 'News', link: PATH_NEWS, type: 'link'},
            // {title: 'Help center', link: PATH_HELP, type: 'link'},
        ]},
    {title: 'Company',      type: 'menu', data: [
            {title: 'About us', link: PATH_COMPANY, type: 'link'},
            {title: 'Careers', link: PATH_CAREERS, type: 'link', hide: true},
            {title: 'Contact us', link: PATH_CONTACT_US, type: 'link'},
        ]},
];

export const globalBusinesses: {id: string, icon: string}[] = [
    {id: 'Sterling', icon: Sterling},
    {id: 'Opus', icon: Opus},
    {id: 'Shing digital', icon: Shing},
    {id: 'Comwell', icon: Comwell},
    {id: 'Plurilogic', icon: Plurilogic},
];

export const complianceSuiteCards: TTitleDescriptionIcon[] = [
    {title: 'Fast and easy',         icon: EyeIcon,         description: 'Track and manage requirements, automate tasks and generate audit reports and alerts. Easy to navigate and understand, designed to minimize the number of steps to compliance and procedure adherence'},
    {title: 'Compliance confidence', icon: EncryptionIcon,  description: 'Centralized management, automation of compliance tasks and real-time monitoring allows to gain visibility and control over the compliance obligations, changing requirements and more'},
    {title: 'GROW YOUR BUSINESS',    icon: StatusUpIcon,    description: 'By prioritizing compliance, you can meet regulatory requirements, build customer trust, create new business opportunities, and gain a competitive advantage in your industry'},
];

export const software: TTitleDescriptionIcon<{link: string}>[] = [
    {title: 'Unlimited Frameworks', link: PATH_FRAMEWORKS, icon: Framework, description: 'Stay up-to-date with the latest regulations and industry best practices and benefit from a flexible solution that can adapt to the evolving compliance landscape. Reduce the risk of non-compliance and protect your organization from financial and reputation damage.'},
    {title: 'Audit ready',          link: PATH_PRODUCT,    icon: Audit,     description: 'Defined and implemented compliance processes and systems allows to quickly and easily provide the necessary documentation and evidence to support an audit anytime. Reduce the risk, stress and costs associated with audits, while increasing confidence in your compliance posture.'},
    {title: 'Micro ERP',            link: PATH_SUITE,      icon: MicroERP,  description: 'Small and specialized enterprise resource planning system. Designed to provide a comprehensive and integrated all-in-one approach to fit Compliance related tasks, processes and other requirements. Regulait ERP helps streamline and automate processes and procedures such as HR Management, Risk Management, Asset Management and Discovery, Access Management, Vulnerability Monitoring and many others.'},
];

export const customersExperience: TComment[] = [
    {logo: Opus, name: 'Catherine Baker', department: 'Head of Security, Company name', comment: "In the world of SaaS services, it's difficult to find a product that allows you to take advantage of its capabilities while still keeping the information in our internal environment, and Regulait allows us to do exactly this."},
    {logo: Opus, name: 'Catherine Cooker', department: 'Head of Security, Company name', comment: "In the world of SaaS services, it's difficult to find a product that allows you to take advantage of its capabilities while still keeping the information in our internal environment, and Regulait allows us to do exactly this."},
    {logo: Opus, name: 'Catherine Sale', department: 'Head of Security, Company name', comment: "In the world of SaaS services, it's difficult to find a product that allows you to take advantage of its capabilities while still keeping the information in our internal environment, and Regulait allows us to do exactly this."},
];

export const faq: TFaq[] = [
    {
        question: 'Why compliance is such important?',
        answer: 'Why compliance is such important? Why compliance is such important? Why compliance is such important? Why compliance is such important? ',
    },
    {
        question: 'What are the advantages to choose Regulait software?',
        answer: 'What are the advantages to choose Regulait software? What are the advantages to choose Regulait software? What are the advantages to choose Regulait software? ',
    },
    {
        question: 'What level of access will Regulait have to my systems and data?',
        answer: 'What level of access will Regulait have to my systems and data? What level of access will Regulait have to my systems and data? What level of access will Regulait have to my systems and data? ',
    },
];

export const unlimitedFrameworksAnchor = '#unlimited-frameworks';
export const crossFrameworkMappingAnchor = '#cross-framework-mapping';
export const policyTemplatesAnchor = '#policy-templates';
export const evidenceCollectorAnchor = '#evidence-collector';
export const controlAnalysisAnchor = '#control-analysis';
export const auditSpaceAnchor = '#audit-space';
export const collaborationAnchor = '#collaboration';
export const multiLanguageAnchor = '#multi-language';
export const vikaAnchor = '#vika';

export const footerProduct: {title: string, path: string}[] = [
    {title: 'Regulait platform', path: PATH_PRODUCT},
    {title: 'Frameworks', path: PATH_FRAMEWORKS},
    {title: 'Integrations', path: PATH_INTEGRATIONS},
];
// export const footerProduct: {title: string, path: string}[] = [
//     {title: 'Unlimited frameworks', path: `${PATH_PRODUCT}${unlimitedFrameworksAnchor}`},
//     {title: 'Cross framework mapping', path: `${PATH_PRODUCT}${crossFrameworkMappingAnchor}`},
//     {title: 'Policy templates', path: `${PATH_PRODUCT}${policyTemplatesAnchor}`},
//     {title: 'Evidence collector', path: `${PATH_PRODUCT}${evidenceCollectorAnchor}`},
//     {title: 'Control analysis', path: `${PATH_PRODUCT}${controlAnalysisAnchor}`},
//     {title: 'Audit space', path: `${PATH_PRODUCT}${auditSpaceAnchor}`},
//     {title: 'Collaboration', path: `${PATH_PRODUCT}${collaborationAnchor}`},
//     {title: 'Multi language', path: `${PATH_PRODUCT}${multiLanguageAnchor}`},
//     {title: 'VICA', path: `${PATH_PRODUCT}${vikaAnchor}`},
// ];
// export const footerSuite: {title: string, path: string}[] = [
//     {title: 'HR Management', path: ''},
//     {title: 'Training', path: ''},
//     {title: 'Asset Management', path: ''},
//     {title: 'Risk Management', path: ''},
//     {title: 'Access Management', path: ''},
//     {title: 'Company Portal', path: ''},
//     {title: 'Vendor Management', path: ''},
//     {title: 'Document Life-cycle', path: ''},
//     {title: 'Vulnerability management', path: ''},
// ];
// export const footerResources: {title: string, path: string}[] = [
//     {title: 'Use cases', path: ''},
//     {title: 'Documentation', path: ''},
//     {title: 'Blog', path: PATH_BLOG},
//     {title: 'Support', path: PATH_CONTACT_US},
// ];
export const footerCompany: {title: string, path: string}[] = [
    {title: 'About us', path: PATH_COMPANY},
    {title: 'Contact us', path: PATH_CONTACT_US},
];

export const integrations: {name: string, logo: string, alt: string[]}[] = [
    {name: 'Github', logo: Github, alt: []},
    {name: 'Gitlab', logo: Gitlab, alt: []},
    {name: 'Google cloud platform', logo: GCP, alt: ['gcp']},
    {name: 'Snyk', logo: Snyk, alt: []},
    {name: 'Zendesk', logo: Zendesk, alt: []},
    {name: 'Bamboo hr', logo: BambooHr, alt: ['Bamboohr']},
    {name: 'Amazon web services', logo: Aws, alt: ['aws']},
    {name: 'Tenable', logo: Tenable, alt: []},
    {name: 'Okta', logo: Okta, alt: []},
    {name: 'Atlassian', logo: Atlassian, alt: []},
    {name: 'onelogin', logo: Onelogin, alt: ['one login']},
    {name: 'microsoft azure', logo: Azure, alt: []},
    {name: 'heroku', logo: Heroku, alt: []},
    {name: 'bitbucket', logo: BitBucket, alt: []},
];

export const ourValues: TTitleDescriptionIcon[] = [
    {title: 'customer Focus',  icon: FavoriteIcon, description: "We believe that putting the needs of our customers first is the key to building a successful company. By understanding the pain points and preferences, we deliver products that exceed expectations."},
    {title: 'innovation',  icon: GrowIcon,     description: "We know that new ideas and pushing the boundaries set successful companies apart. That's why we prioritize creativity and encourage fresh thinking in everything we do."},
    {title: 'agility',  icon: AwardIcon,    description: "The business world moves fast, and we understand the importance of being able to pivot quickly to stay ahead of the curve. We're committed to being flexible and adapting to changes as they arise."},
];

export const teamImages: { img: string, cols: number, rows: number }[] = [
    {img: Team5, cols: 2, rows: 2},
    {img: Team3, cols: 2, rows: 2},
    {img: Team1, cols: 1, rows: 1},
    {img: Team6, cols: 2, rows: 2},
    {img: Team4, cols: 1, rows: 2},
    {img: Team2, cols: 1, rows: 1},
];

export const inventors: {name: string, company: string, img: string}[] = [
    {name: 'Investor’s name', company: 'Title, Company name', img: Investor1},
    {name: 'Investor’s name', company: 'Title, Company name', img: Investor1},
    {name: 'Investor’s name', company: 'Title, Company name', img: Investor1},
    {name: 'Investor’s name', company: 'Title, Company name', img: Investor1},
];

export const tariffs: TTariff[] = [
    // {
    //     name: 'Community',
    //     price: 0,
    //     priceYear: 0,
    //     description: 'Great for any team getting started!',
    //     keys: [
    //         'Unlimited Organization Size',
    //         'Unlimited Frameworks',
    //         'Unlimited Reports',
    //         'Manual Evidence Management',
    //         'Single Workspace & Editor',
    //     ],
    //     isPopular: false,
    // },
    {
        name: 'Business',
        price:  null,
        priceYear:  null,
        description: 'Elevate your compliance posture to a completely new level with quick setup and automation.',
        keys: [
            'Automatic Evidence Collection',
            'Unlimited Integrations',
            'Document Lifecycle Automation',
            'Audit log & Sandbox Workspace',
            'Up to 5 Editors',
        ],
        isPopular: false,
    },
    {
        name: 'Enterprise',
        price: null,
        priceYear: null,
        description: 'Do you have custom compliance requirements, larger team and integration requests? Look no further, contact us now.',
        keys: [
            'SAML',
            'Custom Frameworks',
            'Evidence Analysis',
            'Dedicated CSM',
            'Unlimited Editors',
        ],
        isPopular: false,
    },
];

export const suiteCards: (small?: boolean) => TTitleDescriptionIcon<{comingSoon: boolean}>[] = (small = false) => [
    {title: 'HR management',            comingSoon: false, icon: !small ? SuiteHr : SuiteHrSmall, description: 'Manage and automate HR processes and ensure compliance with laborlaws and regulations, save time, store employee information organized, and create data-driven reports. Use features like employee onboarding, employee data management, performance evaluation, and compensation.'},
    {title: 'Asset management',         comingSoon: false, icon: !small ? SuiteAsset : SuiteAssetSmall, description: 'Ensure operational assets and equipment are in place and tracked. Optimize the quality and utilization of assets throughout their lifecycle, increase productive uptime and reduce operational costs. Using features like asset tracking, inventory management, and maintenance scheduling.'},
    {title: 'Risk management',          comingSoon: false, icon: !small ? SuiteRisk : SuiteRiskSmall, description: 'Identify, assess, and mitigate risks, monitor and track them, improve communication and collaboration, ensure compliance, and ultimately reduce the likelihood and impact of negative events. Use features like multiple risk registries, risk assessment, and risk owners collaboration.'},
    {title: 'Vulnerability management', comingSoon: false, icon: !small ? SuiteVulnerability : SuiteVulnerabilitySmall, description: 'Quickly identify security vulnerabilities, ensure comprehensive coverage, prioritize remediation efforts, and maintain compliance with relevant regulations. Scan your external facing systems for known vulnerabilities and provide you with a list of issues that need to be addressed, freeing up your team to focus on other tasks.'},
    {title: 'Company portal',           comingSoon: true,  icon: !small ? SuitePortal : SuitePortalSmall, description: 'A centralized hub where employees can access company-specific information, policies, training, self-service HR resources and stay up-to-date on updates. Foster a sense of community and engagement among employees by improving communication, increasing efficiency, and processes optimization, saving valuable time and resources.'},
    {title: 'Access management',        comingSoon: false, icon: !small ? SuiteAccess : SuiteAccessSmall, description: 'Improve security, compliance, cost savings, and productivity by controlling who has access to what resources within your organization. Ensure that only authorized users can access sensitive data, systems and applications, and meet regulatory requirements.'},
    {title: 'Vendor management',        comingSoon: false, icon: !small ? SuiteVendors : SuiteVendorsSmall, description: 'Take necessary measures for cost control, risk reduction, service deliverability assurance and generating value from vendors. That includes identifying suitable vendors, monitoring the quality of work, evaluating performance and ensuring value for services delivered.'},
    {title: 'Training',                 comingSoon: true,  icon: !small ? SuiteTraining : SuiteTrainingSmall, description: 'A convenient and efficient way to deliver training content for employees, regardless of location or schedule. Ensure that employees are equipped with the skills and knowledge they need to effectively stay compliant. Standardize training across the organization, ensuring that all employees receive consistent and high-quality information.'},
];


export const partnerPrograms: TTitleDescriptionIcon[] = [
    {title: 'Auditors',    icon: FavoriteIcon, description: 'Our top-notch Auditors are distributed globally and across various sectors. This program offers a range of benefits to auditors, including access to a network of industry experts, platform training and support, and the opportunity to collaborate on high-profile audit engagements with leading organizations. As a partner, you will have the opportunity to gain exposure to new industries and markets, expand your reach and build your network.'},
    {title: 'consulting',  icon: GrowIcon,     description: 'Consulting partner program is designed to bring together a network of experienced and trusted consulting professionals who can help our clients solve complex challenges and achieve their goals within their compliance and regulatory requirements. As a partner, you will have the opportunity to collaborate with our team of experts, expand your reach and market presence, and access new opportunities to grow your business.'},
    {title: 'technology',  icon: AwardIcon,    description: "Our technology partner program aims to bring together a network of software suppliers who can offer complementary products and services, with the ultimate goal of providing comprehensive solutions to our customers. Through the program, partners gain access to a broad customer base, co-marketing opportunities, and other benefits, while customers benefit from a broader range of products that work seamlessly together."},
];


export const frameworkCards: TTitleDescriptionIcon[] = [
    {title: 'ISO 27001', icon: ISO27001, description: 'Standard for implementing and maintaining an Information Security Management System (ISMS) that is designed to protect the confidentiality, integrity, and availability of information assets.'},
    {title: 'ISO 27002', icon: ISO27002, description: 'Comprehensive set of best practices for implementing information security controls, supporting the broader framework of ISO 27001. Adopting ISO 27002 benefits organizations by providing guidance to strengthen security measures, manage risks, and foster a culture of trust.'},
    {title: 'ISO 27017', icon: ISO27017, description: 'Standard that provides guidelines for cloud service providers and customers on how to implement information security controls specifically for cloud computing environments. It is an extension to the ISO 27001 standard.'},
    {title: 'ISO 27018', icon: ISO27018, description: 'standard that provides guidelines for protecting personally identifiable information (PII) in public cloud environments, acting as an extension to the ISO 27001 and ISO 27002 frameworks. By implementing ISO 27018, cloud service providers demonstrate a commitment to strong data privacy practices.'},
    {title: 'ISO 27032', icon: ISO27032, description: 'The controls provided by the ISO 27032 are technical controls that are characterized by identifying technical aspects of cyber security (application of level controls, server protection, end user protection, social engineering attack controls, etc.).'},
    {title: 'ISO 27799', icon: ISO27799, description: 'Standard that provides guidelines for implementing information security management in healthcare organizations. Based on the ISO 27001 standard and focuses specifically on the security challenges faced by healthcare organizations.'},
    {title: 'ISO 9001', icon: ISO9001, description: 'Quality management standard that promotes operational efficiency, customer satisfaction, and continuous improvement, leading to enhanced credibility and competitiveness for organizations.'},
    {title: 'SOC 1',    icon: SOC1, description: 'SOC1 Audit report provides assurance of a service organization that are relevant to their customers financial reporting. It is commonly used by companies that provide outsourced services such as payroll processing, data center operations, or financial transaction processing.'},
    {title: 'SOC 2',    icon: SOC2, description: 'SOC2 Audit report is designed to provide assurances about the effectiveness of controls in place at a service organisation that are relevant to the security, availability, or processing integrity of the system used to process clients information, or the confidentiality or privacy of that information.'},
    {title: 'GDPR',     icon: GDPR, description: 'Comprehensive data privacy law that governs the collection, processing, and storage of personal data for individuals within the European Union. By adhering to GDPR, organizations avoid significant fines for non-compliance, and demonstrate their commitment to responsible data handling practices.'},
    {title: 'SOX ITGC', icon: SOXITGC, description: 'Sarbanes-Oxley Information Technology General Controls - Set of guidelines designed to ensure the accuracy and reliability of financial reporting by improving IT systems security and integrity, ultimately increasing investor confidence and reducing corporate fraud.'},
    {title: 'HIPAA',    icon: HIPPA, description: 'A US federal law that establishes standards for protecting the privacy and security of patients medical information. By complying with HIPAA, healthcare providers and organizations handling protected health information (PHI) can ensure patient confidentiality and safeguard sensitive data.'},
    {title: 'PCI-DSS',  icon: PCIDSS, description: 'The Payment Card Industry Data Security Standard is an information security standard used to handle credit cards from major card brands. The standard is administered by the Payment Card Industry Security Standards Council and its use is mandated by the card brands.'},
    {title: 'NIST 800-53',  icon: NIST80053, description: 'NIST 800-53 is a cybersecurity standard and compliance framework developed by the National Institute of Standards in Technology. It\'s a continuously updated framework tries to flexibly define standards, controls, and assessments based on risk, cost-effectiveness, and capabilities.'},
    {title: 'NIST 800-171', icon: NIST800171, description: 'NIST 800-171 sets standards for safeguarding sensitive information on federal contractors\' IT systems and networks. By requiring best-practice cybersecurity processes from government contractors, the resilience of the whole federal supply chain is strengthened.'},
    {title: 'CCPA',     icon: CCPA, description: 'California Consumer Privacy Act (CCPA) is a comprehensive data privacy law that grants California residents specific rights related to their personal information, including access, deletion, and the ability to opt-out of the sale of their data.'},
    {title: 'TISAX',    icon: TISAX, description: 'An assessment and exchange mechanism for information security in the automotive industry, based on the ISO 27001 standard. By adhering to TISAX, companies can streamline information security assessments with partners and demonstrate compliance to automotive manufacturers and suppliers.'},
    {title: 'FedRamp',  icon: FEDRAMP, description: 'The Federal Risk and Authorization Management Program (FedRAMP) is a government-wide program that provides a standardized approach to security assessment, authorization, and continuous monitoring for cloud products and services.'},
    {title: 'Cyber Secure Canada', icon: CSC, description: 'Voluntary certification program designed to raise cybersecurity awareness and provide guidance for small and medium-sized enterprises (SMEs) in Canada. By participating in the program, SMEs can improve their cybersecurity posture, protect sensitive information, and increase customer confidence.'},
];
export const frameworkCardsArabic: TTitleDescriptionIcon[] = [
    {title: 'SHARIAT', icon: SHARIAT, description: "Islamic law stipulates that the banking system should be free from transactions prohibited by Islam such as usury, gambling, uncertainty and not allow the financing of illegal activities."},
        ...frameworkCards.filter((card) => (
            ['ISO 27001', 'ISO 27799', 'SOC 1', 'SOC 2', 'SOX ITGC'].includes(card.title)
        )),
];
export const frameworkCardsHebrew: TTitleDescriptionIcon[] = [
        ...frameworkCards.filter((card) => (
            ['ISO 27001', 'ISO 27017', 'ISO 27799', 'SOC 1', 'SOC 2', 'SOX ITGC'].includes(card.title)
        )),
];

export const blogCards: TTitleDescriptionIcon<{writer: string}>[] = [
    {
        title: 'Introducing regulait software and why to choose us',
        description: 'Anything we do should serve the personal and professional growth of our customers and our team. Anything we do should serve the personal and professional growth of our customers and our team.',
        writer: 'George Veitsman, Founder and CEO of Regulait',
        icon: Blog1,
    },
    {
        title: 'Introducing regulait software and why to choose us',
        description: 'Anything we do should serve the personal and professional growth of our customers and our team. Anything we do should serve the personal and professional growth of our customers and our team.',
        writer: 'George Veitsman, Founder and CEO of Regulait',
        icon: Blog2,
    },
    {
        title: 'Introducing regulait software and why to choose us',
        description: 'Anything we do should serve the personal and professional growth of our customers and our team. Anything we do should serve the personal and professional growth of our customers and our team.',
        writer: 'George Veitsman, Founder and CEO of Regulait',
        icon: Blog3,
    },
];

export const careerCards: {id: string, title: string, place: string, timeStatus: string, description: string, link?: string}[] = [
    {
        id: '1',
        title: 'Front-End developer',
        place: 'Ashdod, Israel',
        timeStatus: 'Full Time',
        description: 'We are looking for an experienced Front-End developer to join us. You will work closely with the design team to meet both technical and business requirements.',
    },
    {
        id: '2',
        title: 'Product designer',
        place: 'Ashdod, Israel',
        timeStatus: 'Full Time',
        description: 'We are looking for an experienced Front-End developer to join us. You will work closely with the design team to meet both technical and business requirements.',
    },
    {
        id: '3',
        title: 'Back-End Engineer',
        place: 'Ashdod, Israel',
        timeStatus: 'Full Time',
        description: 'We are looking for an experienced Front-End developer to join us. You will work closely with the design team to meet both technical and business requirements.',
    },
    {
        id: '4',
        title: 'Front-End developer',
        place: 'Ashdod, Israel',
        timeStatus: 'Full Time',
        description: 'We are looking for an experienced Front-End developer to join us. You will work closely with the design team to meet both technical and business requirements.',
    },
    {
        id: '5',
        title: 'Product designer',
        place: 'Ashdod, Israel',
        timeStatus: 'Full Time',
        description: 'We are looking for an experienced Front-End developer to join us. You will work closely with the design team to meet both technical and business requirements.',
    },
    {
        id: '6',
        title: 'Back-End Engineer',
        place: 'Ashdod, Israel',
        timeStatus: 'Full Time',
        description: 'We are looking for an experienced Front-End developer to join us. You will work closely with the design team to meet both technical and business requirements.',
    },
];

export const careerValues: TTitleDescriptionIcon[] = [
    {title: 'Growth Opportunities',  icon: Rocket,  description: 'We provide clear paths for career advancement with promotions, training programs, and development initiatives. At our company, you can grow your skills and take your career to the next level.'},
    {title: 'Competitive Benefits',  icon: Fun,     description: 'We offer a comprehensive package that includes competitive salaries, health benefits, retirement plans, and other perks. Our commitment to fair compensation and benefits is a reflection of how much we value our employees.'},
    {title: 'Collaborative Culture', icon: Community, description: 'We foster a collaborative work environment that encourages teamwork, communication, and mutual support. You\'ll work alongside talented and passionate professionals who share your commitment to compliance.'},
    {title: 'Ethical Standards',     icon: Blocks,    description: 'We prioritize ethical behavior and hold ourselves to the highest standards of integrity. Our commitment to ethical behavior is not just a regulatory requirement, it\'s an essential part of who we are as a company.'},
];
