import React, {FC, Fragment} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container, Gear} from "../../../main/components/styled";
import colors from "../../../../shared/theme/colors";
import {TProductRow} from "../../types";

type TProps = TProductRow;
export const ProductRow: FC<TProps> = ({count, title, description, img, reverse}) => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    if (!reverse && down1024) return (
        <Box bgcolor={colors.landing.secondary1} mt={'10rem'} p={'8rem 0'}>
            <Container alignItems={'center'} flexDirection={'column'}>
                <Box width={'min(100%, 105rem)'}>
                    <Typography variant={'decorative'} color={colors.landing.text2}>
                        {count}
                    </Typography>
                    <Typography variant={'h1'}>
                        {title.split('\n').map((e, index) => (
                            <Fragment key={e}>
                                {e}
                                {index < title.length - 1 && <br/>}
                            </Fragment>
                        ))}
                    </Typography>
                    <Typography variant={"body1"} marginTop={'1.6rem'}>
                        {description.split('\n').map((e, index) => (
                            <Fragment key={e}>
                                {e}
                                {index < description.length - 1 && <br/>}
                                {index < description.length - 1 && <br/>}
                            </Fragment>
                        ))}
                    </Typography>
                </Box>

                <Box position={"relative"} sx={{'& svg.gear path': {fill: '#DDE7FE'}}} mt={'10rem'} width={'100%'} display={"flex"} justifyContent={"center"}>
                    <Gear className={'gear'} style={{position: 'absolute', top: '-6rem', right: '-6rem'}}/>
                    <img src={img} alt={' icon'} style={{margin: 'auto', maxWidth: '80%', position: "relative", zIndex: 3}}/>
                </Box>

            </Container>
        </Box>
    );

    if (!reverse) return (
        <Box bgcolor={colors.landing.secondary1} mt={'30rem'} p={'16.3rem 0 8rem'}>
            <Container alignItems={'center'}>
                <Box mb={'10rem'} width={'calc(60% - 13rem / 2)'}>
                    <Typography variant={'decorative'} color={colors.landing.text2}>
                        {count}
                    </Typography>
                    <Typography variant={'h1'}>
                        {title.split('\n').map((e, index) => (
                            <Fragment key={e}>
                                {e}
                                {index < title.length - 1 && <br/>}
                            </Fragment>
                        ))}
                    </Typography>
                    <Typography variant={"body1"} marginTop={'1.6rem'}>
                        {description.split('\n').map((e, index) => (
                            <Fragment key={e}>
                                {e}
                                {index < description.length - 1 && <br/>}
                                {index < description.length - 1 && <br/>}
                            </Fragment>
                        ))}
                    </Typography>
                </Box>

                <Box style={{minWidth: down1366 ? '7rem' : '13rem', height: '4rem'}}/>

                <Box position={"relative"} mr={down1366 ? '7rem' : '15.6rem'} sx={{'& svg.gear path': {fill: '#DDE7FE'}}} width={'calc(40% - 13rem / 2)'}>
                    <Gear className={'gear'} style={{position: 'absolute', top: '-6rem', right: '-6rem'}}/>
                    <img src={img} alt={'CrossFramework icon'} style={{maxWidth: '100%', position: "relative", zIndex: 3}}/>
                </Box>
            </Container>
        </Box>
    )

    if (reverse && down1024) return (
        <Box bgcolor={"inherit"} mt={'10rem'}>
            <Container alignItems={'center'} flexDirection={'column'}>
                <Box maxWidth={'100%'} width={'min(100%, 105rem)'}>
                    <Typography variant={'decorative'} color={colors.landing.text2}>
                        {count}
                    </Typography>
                    <Typography variant={'h1'}>
                        {title.split('\n').map((e, index) => (
                            <Fragment key={e}>
                                {e}
                                {index < title.length - 1 && <br/>}
                            </Fragment>
                        ))}
                    </Typography>
                    <Typography variant={"body1"} marginTop={'1.6rem'}>
                        {description.split('\n').map((e, index) => (
                            <Fragment key={e}>
                                {e}
                                {index < description.length - 1 && <br/>}
                                {index < description.length - 1 && <br/>}
                            </Fragment>
                        ))}
                    </Typography>
                </Box>

                <Box position={"relative"} sx={{'& svg.gear path': {fill: '#DDE7FE'}}} mt={'10rem'}
                     display={"flex"} alignItems={"center"}>
                    {/*<Gear className={'gear'} style={{position: 'absolute', top: '-6rem', right: '-6rem'}}/>*/}
                    <img src={img} alt={' img'} style={{maxWidth: '100%', position: "relative", zIndex: 3}}/>
                </Box>
            </Container>
        </Box>
    );

    return (
        <Box bgcolor={"inherit"} mt={'13rem'}>
            <Container alignItems={'center'}>
                <Box position={"relative"} ml={down1366 ? '3rem' : '8.6rem'} sx={{'& svg.gear path': {fill: '#DDE7FE'}}} width={'calc(200% - 13rem / 2)'}>
                    {/*<Gear className={'gear'} style={{position: 'absolute', top: '-6rem', right: '-6rem'}}/>*/}
                    <img src={img} alt={'DocumentsIcon icon'} style={{maxWidth: '100%', position: "relative", zIndex: 3}}/>
                </Box>

                <Box style={{minWidth: '13rem', height: '4rem'}}/>

                <Box mb={'10rem'} maxWidth={'84.8rem'}>
                    <Typography variant={'decorative'} color={colors.landing.text2}>
                        {count}
                    </Typography>
                    <Typography variant={'h1'}>
                        {title.split('\n').map((e, index) => (
                            <Fragment key={e}>
                                {e}
                                {index < title.length - 1 && <br/>}
                            </Fragment>
                        ))}
                    </Typography>
                    <Typography variant={"body1"} marginTop={'1.6rem'}>
                        {description.split('\n').map((e, index) => (
                            <Fragment key={e}>
                                {e}
                                {index < description.length - 1 && <br/>}
                                {index < description.length - 1 && <br/>}
                            </Fragment>
                        ))}
                    </Typography>
                </Box>
            </Container>
        </Box>
    )


}