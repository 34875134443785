import React, {FC} from "react";
import {Box, Divider, Typography} from "@mui/material";
import {Container} from "../../../main/components/styled";

import George from "../../../../shared/images/new/george.png";
import Gregory from "../../../../shared/images/new/gregory.png";

export const Founders: FC = () => {

    return (
        <Container mt={'17rem'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant={"h1"} color={'rgba(0,0,0,0.87)'}>
                {'Founders'}
            </Typography>

            <Box display={"flex"} alignItems={"center"} mt={'6.4rem'} gap={'12rem'} flexWrap={"wrap"} justifyContent={"center"}>
                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} maxWidth={'54.2rem'} gap={'1.6rem'}>
                    <img src={George} alt={'George'} style={{maxHeight: '35rem'}}/>

                    <Typography variant={'h2'} color={'rgba(0,0,0,0.87)'} textAlign={"center"} mt={'2.257rem'}>
                        {'George Veitsman'}
                    </Typography>

                    <Typography variant={'body2'} color={'#4330B8'} textAlign={"center"}>
                        {'Founder, CEO'}
                    </Typography>

                    <Divider flexItem variant={"middle"}/>

                    <Typography variant={"body1"} color={'rgba(0,0,0,0.87)'} textAlign={"start"}>
                        {'Seasoned IT and Security professional with over 15 Years of practical and leadership roles at the Intelligence Corps, Merck, Yotpo, Team8, Siemplify and Google.'}
                    </Typography>
                </Box>

                <Box display={"flex"} flexDirection={"column"} alignItems={"center"} maxWidth={'54.2rem'} gap={'1.6rem'}>
                    <img src={Gregory} alt={'Gregory'} style={{maxHeight: '35rem'}}/>

                    <Typography variant={'h2'} color={'rgba(0,0,0,0.87)'} textAlign={"center"} mt={'2.257rem'}>
                        {'Gregory Sheygam'}
                    </Typography>

                    <Typography variant={'body2'} color={'#4330B8'} textAlign={"center"}>
                        {'Co-Founder, CTO'}
                    </Typography>

                    <Divider flexItem variant={"middle"}/>

                    <Typography variant={"body1"} color={'rgba(0,0,0,0.87)'} textAlign={"start"}>
                        {'Extensive experience of over 14 years in R&D, System Architecture, Teaching and Project Leadership. BSc in Computer Science.'}
                    </Typography>
                </Box>
            </Box>


        </Container>
    )
}
