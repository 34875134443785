import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";

import Lines from "../../../../shared/images/new/lines.svg"

const title = 'Compliance is easier together';
const description = "At Regulait, we understand the challenges of maintaining compliance in today's fast-paced business world. That's why we've created the Ultimate Compliance Solution - a comprehensive platform that brings all your compliance-related tasks under one roof.";

export const ComplianceTogether: FC = () => {

    const theme = useTheme();
    // const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box position={'relative'} >
            <img src={Lines} alt={''} style={{
                position: 'absolute', top: 0, left: '0', zIndex: 0,
                width: '140%'
            }}/>

            <Container mt={!down1366 ? '14rem' : '10rem'} flexDirection={'column'} alignItems={'center'}
                       sx={{overflowX: 'hidden', overflowY: 'visible', position: 'relative'}}
                       pb={'1rem'}>

                <Typography variant={'h1'} textAlign={'center'}>
                    {title}
                </Typography>

                <Box mt={'1.6rem'} width={'100%'}>
                    {description.split('\n').map(e => (
                        <Typography key={JSON.stringify(e)} variant={"body1"} textAlign={'center'} maxWidth={'min(100%, 115.4rem)'} m={'0 auto'}>
                            {e}
                        </Typography>
                    ))}
                </Box>

            </Container>
        </Box>
    )
}
