import Lines from "../../../../shared/images/new/lines.svg";
import {Container} from "../../../main/components/styled";
import {Box, Button, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Footer} from "../../../barsEnvironment/footer";
import React, {FC} from "react";
import {PATH_REGISTRATION} from "../../../main/constants";

const title = 'Begin your compliance journey today';

export const BlogFooter: FC = () => {

    const theme = useTheme();
    const down800 = useMediaQuery(theme.breakpoints.down('c800'));
    // const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box position={'relative'} overflow={"hidden"} mt={'7rem'}>
            <img src={Lines} alt={''} style={{
                position: 'absolute', top: 0, left: 0, zIndex: 0,
                width: '140%',
            }}/>

            <Container mt={'8rem'} position={'relative'} zIndex={4} flexDirection={'column'}
                       alignItems={'center'}>
                <Box display={"flex"} flexDirection={"column"} zIndex={4} maxWidth={'min(100%, 106rem)'}>
                    <Typography {...(down800 ? {
                        fontWeight: 500, fontSize: '5rem', lineHeight: '8.14rem',
                    } : {
                        fontWeight: 500, fontSize: '7.8rem', lineHeight: '10.14rem',
                    }) } textAlign={'center'}>
                        {title}
                    </Typography>

                    <Button variant={"contained"} size={"large"} sx={{margin: '6.4rem auto 0', width: 'fit-content'}} href={PATH_REGISTRATION} target={'_blank'}>
                        {'Get started'}
                    </Button>
                </Box>
            </Container>


            <Footer/>
        </Box>
    )
}