import ReactGA from "react-ga4";
import React, {FC, useEffect} from "react";
import {useLocation} from "react-router";
import {GOOGLE_ANALYTICS} from "../../../app/components/App";
//@ts-ignore
import {Helmet} from "react-helmet";

// export const useAnalyticsEventTracker = (category="Blog category") => {
//     const eventTracker = (action = "test action", label = "test label") => {
//         ReactGA.event({category, action, label});
//     }
//     return eventTracker;
// }

export const MetrikaPageView: FC = () => {
    const location = useLocation();

    useEffect(() => {
        ReactGA.initialize(GOOGLE_ANALYTICS, {
            // gaOptions: {
            //
            // },
        });
    }, [])

    // useEffect(() => {
    //     console.log(ReactGA?.isInitialized)
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [ReactGA?.isInitialized])

    useEffect(() => {
        // ReactGA.pageview(location.pathname + location.search);
        ReactGA.ga('set', 'page', location.pathname + location.search);
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });

        // //@ts-ignore
        // window.ym && window.ym(YANDEX_METRIKA, 'hit', location.pathname + location.search)
    }, [location.pathname, location.search]);

    return (
        <>
            <Helmet>
                {/*Yandex.Metrika counter*/}
                {/*<script type="text/javascript" defer>*/}
                {/*    {*/}
                {/*        "(function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};\n" +*/}
                {/*        "   m[i].l=1*new Date();\n" +*/}
                {/*        "   for (var j = 0; j < document.scripts.length; j++) {if (document.scripts[j].src === r) { return; }}\n" +*/}
                {/*        "   k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})\n" +*/}
                {/*        "   (window, document, \"script\", \"https://mc.yandex.ru/metrika/tag.js\", \"ym\");\n" +*/}
                {/*        "\n" +*/}
                {/*        `   ym(${YANDEX_METRIKA}, "init", {\n` +*/}
                {/*        "        defer:true,\n" +*/}
                {/*        "        clickmap:true,\n" +*/}
                {/*        "        trackLinks:true,\n" +*/}
                {/*        "        accurateTrackBounce:true,\n" +*/}
                {/*        "        webvisor:true,\n" +*/}
                {/*        // "        trackHash:true\n" +*/}
                {/*        "   });"*/}
                {/*    }*/}
                {/*</script>*/}
                {/*/Yandex.Metrika counter*/}

                {/*Start of HubSpot Embed Code*/}
                <script type="text/javascript" id="hs-script-loader" async defer src={"//js-eu1.hs-scripts.com/26885524.js"}></script>
                {/*End of HubSpot Embed Code*/}

                {/*Google tag (gtag.js)*/}
                {/*<script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS2}`}></script>*/}
                {/*<script defer>*/}
                {/*    {*/}
                {/*        "window.dataLayer = window.dataLayer || [];\n" +*/}
                {/*        "function gtag(){dataLayer.push(arguments)}\n" +*/}
                {/*        "gtag('js', new Date());\n" +*/}
                {/*        `gtag('config', '${GOOGLE_ANALYTICS2}')`*/}
                {/*    }*/}
                {/*</script>*/}
            </Helmet>
            {/*<noscript><div><img src={`https://mc.yandex.ru/watch/${YANDEX_METRIKA}`} style={{position: 'absolute', left: '-9999px'}} alt="" /></div></noscript>*/}
        </>
    )
};