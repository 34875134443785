//Core
import React from 'react';
import {BrowserRouter as Router} from "react-router-dom";
import {Provider} from "react-redux";
//Other
import {store} from '../../shared/redux';
//SnackBar
import {SnackbarProvider} from 'notistack';

//Components
import {Shell} from "../../domain/barsEnvironment/shell";


//Styled Theme
import {ThemeProvider as StyledThemeProvider} from 'styled-components';

import {createTheme, ThemeProvider} from "@mui/material";
import {muiTheme} from "../../shared/theme/MaterialStyles";
import {Snack} from "../../domain/snack";
import {useDirection} from "../../shared/hooks/useDirection";
import {CacheProvider} from "@emotion/react";

import rtlPlugin from "stylis-plugin-rtl";
import {prefixer} from "stylis";
import createCache from "@emotion/cache";
import theme from "../../shared/theme/theme";
import {MetrikaPageView} from "../../domain/barsEnvironment/googleAnalytics";
import ReactGA from "react-ga4";
// @ts-ignore
import {Helmet} from "react-helmet";
import {CookiesAccept} from "../../domain/barsEnvironment/cookiesAccept";
import {devEnv} from "../../shared/constants";

// Create rtl cache
const cacheRtl = createCache({
    key: "muirtl",
    // prepend: true,
    //@ts-ignore
    stylisPlugins: [prefixer, rtlPlugin]
});
const cacheLtr = createCache({
    key: "muiltr",
    prepend: true,
    stylisPlugins: []
});

// export const YANDEX_METRIKA = "92984747";
export const GOOGLE_ANALYTICS = "G-JPEP6BN0QJ";
export const GOOGLE_ANALYTICS2 = "G-QJSXNZTP0V";

ReactGA.initialize(GOOGLE_ANALYTICS, {
    // gaOptions: {
    //
    // },
});

export default function App() {
    const {revDir} = useDirection();

    // useEffect(() => {
    //     ReactGA.ga('send', 'pageview', {'sessionControl': 'start'});
    //     return () => {
    //
    //         ReactGA.ga('send', 'pageview', {'sessionControl': 'end'});
    //     }
    // }, [])

    return (
        <CacheProvider value={!revDir ? cacheLtr : cacheRtl}>
            <Provider store={store}>
                <StyledThemeProvider theme={theme} >
                    <ThemeProvider theme={createTheme({...muiTheme, direction: !revDir ? 'ltr' : 'rtl'})}>
                        <SnackbarProvider anchorOrigin={{vertical: 'bottom', horizontal: 'right'}} preventDuplicate>
                            <Snack />
                            <Router>
                                {devEnv ? (
                                    <Helmet>
                                        <meta name="robots" content="noindex"></meta>
                                    </Helmet>
                                ) : (
                                    <MetrikaPageView/>
                                )}
                                <Shell/>
                            </Router>

                            {devEnv && <CookiesAccept/>}
                        </SnackbarProvider>
                    </ThemeProvider>
                </StyledThemeProvider>
            </Provider>
        </CacheProvider>
    );
}
