import {TTranslation} from "../types";

export const translate = {
    "common":{
        "Log in":"الدخول",
        "Sign up":"أنشئ حساب",
        "Start free":"ابدأ مجانًا",
        "Start now":"ابدأ مجانًا",
        "Try free":"ابدأ مجانًا",
        "Submit":"يرسل",
        "Language":"Language"
    },
    "header":{
        "Home":"الرئيسية",
        "Product":"المنتج",
        "Suite":"المنظومة",
        "Integrations":"التكاملات",
        "Frameworks":"أطر العمل",
        "Contacts":"للاتصال بنا",
        "Legal Inquiries":"استفسارات قانونية",
        "Investor Relations":"علاقات المستثمرين",
        "Terms and conditions":"شروط الاستخدام",
        "Privacy policy":"سياسة الخصوصية",
        "© All rights reserved to Regulait, 2023":"جميع الحقوق محفوظة لشركة Regulait Ltd، 2023",
        "About us":"معلومات عنا",
        "Regulait makes the Compliance process a task by establishing a consistent control analysis and automated procedures across every framework and regulation adherence.":"تجعل Regulait عملية الامتثال مهمة يمكن القيام بها من خلال إنشاء تحليل رقابة متسقة وإجراءات مؤتمتة عبر كل إطار والتزام باللوائح التنفيذية."
    },
    "top":{
        "Streamline Compliance":"قم بتبسيط الامتثال",
        "Automate Assurance":"قم بأتمتة تلقي الضمان المطلوب",
        "Easily manage and automate your compliance needs with our all-in-one suite. The only solution that provides a complete suite of systems for compliance assurance.":"قم بإدارة وأتمتة احتياجات الامتثال الخاصة بك بسهولة من خلال مجموعة الكل في واحد الخاصة بنا. هذا هو الحل الوحيد الذي يوفر مجموعة كاملة من الأنظمة لضمان الامتثال"
    },
    "product":{
        "Software that delivers Compliance confidence":"برنامج يوفر الثقة في الامتثال",
        "Promoting responsible business practices and automatically mitigating risk of non-compliance":"تعزيز ممارسات الأعمال المسؤولة والتخفيف تلقائيًا من مخاطر عدم الامتثال",
        "Cross framework mapping":"مسح الأطر المشتركة",
        "Built-in cross-framework mappings allow you to reuse integration data, evidence & policies, across multiple regulatory and industry frameworks such as ISO27001, NIST, PCI DSS and more, cutting down the completion time and resources by up to 80%.\nThe goal is to simplify the compliance process by allowing you to leverage the controls and best practices from one framework to meet the requirements of another framework.":"مسح الأطر المشتركة المندمج في البرنامج يسمح لك بإعادة استخدام تكامل البيانات والأدلة والسياسات عبر العديد من الأطر التنظيمية والصناعية مثل ISO27001 و NIST-و PCI DSS-والمزيد، مما يقلل من الوقت المطلوب لإكمال العمل والموارد المطلوبة لذلك بنسبة تصل إلى 80٪.\nالهدف هو تبسيط عملية الامتثال من خلال السماح لك بالاستفادة من الضوابط وأفضل الممارسات من إطار عمل واحد لتلبية متطلبات إطار عمل آخر.",
        "Document library & lifecycle automation":"مكتبة المستندات وأتمتة دورة الحياة",
        "A rich library of standardized document templates that outline the guidelines, rules, and procedures for a particular area of business operations or compliance requirements, and can be used as a starting point to create your specific documents and policies. Document lifecycle can now be completely automatic using Regulait automation pipelines. Sharing, reviewing or signoff, all included.":"مكتبة غنية من قوالب ونماذج المستندات التي تحدد الإرشادات والقواعد والإجراءات بالنسبة لفئة أو صنف معين من العمليات التجارية أو من متطلبات التوافق ويمكن استخدامها كنقطة بداية لإنشاء مستنداتك وسياساتك المحددة. يمكن الآن أن تكون دورة حياة المستند تلقائية تمامًا من خلال استخدام الأنظمة الآلية التابعة لRegulait-. إضافة لذلك، مشاركة المستندات أو مراجعتها أو التوقيع عليها مشمولة في هذه المنظومة.",
        "Audit space":"مساحة التدقيق",
        "Get faster, more efficient audits, save hours of back-and-forth communication, never misplace crucial evidence, and share documentation instantly. All in one, intuitive suite. Regulait Reporting can be used to securely share sensitive content with official auditors, customers or prospects.":"أحصل على عمليات تدقيق أسرع وأكثر كفاءة ووفر الساعات من الاتصال بجهات أخرى ذهابًا وإيابًا، ولا تضيع أبدًا الأدلة المهمة، وشارك الوثائق على الفور. إنها منظومة الكل في واحد، ويمكن العمل بها بشكل بديهي. يمكن استخدام Regulait Reporting  لمشاركة المحتوى الحساس بأمان مع المراجعين الرسميين أو الزبائن أو الزبائن المحتملين.",
        "Automatic evidence collection & analysis":"جمع وتحليل آلي للأدلة",
        "Benefit with less time and resources spent on manual data collection, avoid errors and inconsistencies in the analysis process. Now you can automatically track changes and updates to your compliance posture and quickly identify and address any compliance gaps. Demonstrate a commitment to responsible business practices with all stakeholders.":"إستفد من تقليل الوقت والموارد التي يتم إنفاقها على جمع البيانات يدويًا، وتجنب الأخطاء والتناقضات في عملية التحليل. يمكنك الآن تتبع التغييرات والتحديثات تلقائيًا في وضع الامتثال الخاص بك وتحديد وجود ثغرات في عملية الامتثال ومعالجتها بسرعة. كما يمكن إظهار الالتزام بممارسات الأعمال المسؤولة مع جميع أصحاب المصلحة.",
        "VICA":"VICA  (مساعد الامتثال الافتراضي)",
        "An automated compliance support and guidance system. Using machine learning algorithms to provide relevant information, guidance, and support to manage compliance posture, understand and respond to inquiries and more. Regulait VICA can provide step-by-step guidance for compliance processes, and describe specific procedures to ensure compliance requirements are met. VICA is a cost-effective and convenient alternative to traditional compliance support, helping organizations stay informed and up-to-date on their compliance posture, required remediation tasks or updates to current regulatory requirements.":"هذا هو نظام دعم وتوجيه آلي للامتثال. يمكن من خلاله استخدام خوارزميات التعلم الآلي لتوفير المعلومات ذات الصلة والتوجيه والدعم لإدارة وضع الامتثال وفهم الاستفسارات والرد عليها وأكثر. يمكن أن يوفر Regulait VICA إرشادات، خطوة بخطوة، لعمليات الامتثال، ويمكنه وصف إجراءات محددة لضمان تلبية متطلبات الامتثال. يعد VICA بديلاً مناسبًا وفعالاً من حيث التكلفة لدعم الامتثال التقليدي، مما يساعد المؤسسات على البقاء على اطلاع ومواكبة وضع الامتثال الخاص بها، وتنفيذ مهام الإصلاح أو التحديثات المطلوبة للمتطلبات التنظيمية الحالية.",
        "Collaboration":"التعاون",
        "Regulait collaboration functionality makes sure that all relevant parties and stakeholders work together to develop effective compliance strategies and processes, share best practices, and ensure that everyone is aware of their responsibilities. Improve the overall effectiveness and efficiency of an organization's compliance program by building trust with stakeholders, customers, and regulators through transparency and accountability.":"تتأكد أداة التعاون الخاصة ب-Regulait من أن جميع الأطراف المعنية وأصحاب المصلحة يعملون معًا لتطوير استراتيجيات وعمليات امتثال فعالة، ولمشاركة أفضل الممارسات. كما وهي تتأكد من أن الجميع على دراية بمسؤولياتهم. قم بتحسين الفعالية والكفاءة العامة لبرنامج الامتثال للمؤسسة من خلال بناء الثقة مع أصحاب المصلحة والزبائن والمنظمين من خلال الشفافية والمحاسبة."
    },
    "suite":{
        "Compliance is easier together":"منظومة امتثال تتناسب مع عملك",
        "At Regulait, we understand the challenges of maintaining compliance in today's fast-paced business world. That's why we've created the Ultimate Compliance Solution - a comprehensive platform that brings all your compliance-related tasks under one roof.":"يدور الحديث عن وحدة تحكم إدارة موحدة تجمع بين نظام تخطيط موارد المؤسسات الصغيرة وأتمتة الأدلة والتحكم ومساحة التدقيق وبوابة الخدمة الذاتية وخدمات كثيرة أخرى.",
        "HR management":"إدارة الموارد البشرية",
        "Manage and automate HR processes and ensure compliance with laborlaws and regulations, save time, store employee information organized, and create data-driven reports. Use features like employee onboarding, employee data management, performance evaluation, and compensation.":"إدارة وأتمتة عمليات الموارد البشرية وضمان الامتثال لقوانين ولوائح العمل، وتوفير الوقت، وتخزين معلومات الموظف بشكل منظم وتأليف تقارير تستند إلى البيانات. يمكن القيام بذلك من خلال استخدام ميزات مثل توظيف الموظفين وإدارة بيانات الموظفين وتقييم أدائهم المهني والرواتب التي تدفع لهم.",
        "Asset management":"إدارة الأصول",
        "Ensure operational assets and equipment are in place and tracked. Optimize the quality and utilization of assets throughout their lifecycle, increase productive uptime and reduce operational costs. Using features like asset tracking, inventory management, and maintenance scheduling.":"تأكد من وجود أصول ومعدات تشغيلية ومن خضوعها للمراقبة. قم بتحسين جودة الأصول واستخدمها طوال دورة حياتها. قم بزيادة وقت التشغيل الإنتاجي وبتقليل التكاليف التشغيلية. يمكنك القيام بذلك من خلال استخدام ميزات مثل تتبع الأصول وإدارة المخزون وجدولة الصيانة.",
        "Risk management":"إدارة المخاطر",
        "Identify, assess, and mitigate risks, monitor and track them, improve communication and collaboration, ensure compliance, and ultimately reduce the likelihood and impact of negative events. Use features like multiple risk registries, risk assessment, and risk owners collaboration.":"قم بتشخيص وتقييم وتخفيف المخاطر ومراقبتها. كما قم بتحسين التواصل والتعاون وقم بضمان الامتثال، مما يؤدي إلى تقليل احتمالية وقوع أحداث سلبية ورقعة تأثيرها. يمكنك استخدام ميزات مثل سجلات المخاطر المتعددة وتقييم المخاطر وإقامة تعاون بين أصحاب المخاطر.",
        "Vulnerability management":"إدارة نقاط الضعف",
        "Quickly identify security vulnerabilities, ensure comprehensive coverage, prioritize remediation efforts, and maintain compliance with relevant regulations. Scan your external facing systems for known vulnerabilities and provide you with a list of issues that need to be addressed, freeing up your team to focus on other tasks.":"قم بتشخيص الثغرات الأمنية بسرعة، وتأكد من ضمان التغطية الشاملة ومن تحديد أولويات جهود الإصلاح والحفاظ على الامتثال للوائح ذات الصلة. إفحص الأنظمة الخارجية التي تواجهك بحثًا عن نقاط الضعف المعروفة وعليه سيتم تزويدك بقائمة من المشكلات التي يجب معالجتها، مما يحرر فريقك للتركيز على المهام الأخرى.",
        "Vendor management":"إدارة البائعين",
        "Take necessary measures for cost control, risk reduction, service deliverability assurance and generating value from vendors. That includes identifying suitable vendors, monitoring the quality of work, evaluating performance and ensuring value for services delivered.":"إتخذ التدابير اللازمة للتحكم في التكاليف ولتقليل المخاطر ولضمان تقديم الخدمة ولتوليد القيمة من البائعين. يشمل ذلك تحديد البائعين المناسبين ومراقبة جودة عملهم وتقييم أدائهم وضمان قيمة الخدمات المقدمة من قبلهم.",
        "Access management":"إدارة ميزات الوصول",
        "Improve security, compliance, cost savings, and productivity by controlling who has access to what resources within your organization. Ensure that only authorized users can access sensitive data, systems and applications, and meet regulatory requirements.":"قم بتحسين الأمان والامتثال وتوفير التكاليف والإنتاجية من خلال السيطرة على من يمكنه الوصول إلى الموارد الموجودة داخل مؤسستك. تأكد من أن المستخدمين المصرح لهم فقط لديهم حق الوصول إلى البيانات والأنظمة والتطبيقات الحساسة، وتلبية المتطلبات التنظيمية."
    },
    "complianceJourney":{
        "Begin your compliance journey today":"إبدأ رحلة الامتثال اليوم"
    },
    "integrations":{
        "Integrations":"التكاملات",
        "Regulait integrates with dozens of services. With these integrations, you'll have access to a wide range of tools and services to help you manage your compliance efforts more effectively. From cloud storage solutions to secure communications platforms, you'll have everything you need to ensure your compliance management is always up-to-date.":"تتكامل Regulait مع عشرات الخدمات. من خلال التكاملات هذه، ستتمكن من الوصول إلى مجموعة واسعة من الأدوات والخدمات التي تساعدك في إدارة جهود الامتثال الخاصة بك بشكل أكثر فعالية, إبتداءً من حلول التخزين السحابي مرورًا إلى منصات الاتصالات الآمنة، سيكون لديك كل ما تحتاجه لضمان تحديث إدارة الامتثال دائمًا."
    },
    "frameworks":{
        "Built-in Framework Library":"مكتبة الأطر المضمنة",
        "We offer a wide range of compliance frameworks and add new frameworks monthly to help you streamline your compliance operations and maintain an effective Compliance posture strategy.":"نقدم مجموعة من أطر عمل الامتثال ونضيف أطر عمل جديدة شهريًا لمساعدتك على تبسيط عمليات الامتثال الخاصة بك والحفاظ على استراتيجية فعالة للامتثال.",
        "SHARIAT":"إطار الشريعة",
        "Islamic law stipulates that the banking system should be free from transactions prohibited by Islam such as usury, gambling, uncertainty and not allow the financing of illegal activities.":"تنص الشريعة الإسلامية على أن النظام المصرفي يجب أن يكون خاليًا من المعاملات التي يحرمها الإسلام مثل الربا والمقامرة وعدم اليقين وألا يسمح بتمويل الأنشطة غير القانونية.",
        "Standard for implementing and maintaining an Information Security Management System (ISMS) that is designed to protect the confidentiality, integrity, and availability of information assets.":"معيار لتنفيذ وصيانة نظام إدارة أمن المعلومات (ISMS) المصمم لحماية سرية أصول المعلومات وسلامتها وتوفرها.",
        "ISO 27002 is an information security standard published by the International Organization for Standardization and by the International Electrotechnical Commission, titled Information security, cybersecurity and privacy protection - Information security controls.":"معيار ISO 27017 الذي يوفر إرشادات لموفري الخدمات السحابية والزبائن حول كيفية تنفيذ ضوابط أمن المعلومات على وجه التحديد لبيئات الحوسبة السحابية. إنه يشكل امتدادا لمعيار ISO 27001.",
        "Standard that provides guidelines for implementing information security management in healthcare organizations. Based on the ISO 27001 standard and focuses specifically on the security challenges faced by healthcare organizations.":"معيار يوفر إرشادات لتطبيق إدارة أمن المعلومات في مؤسسات الرعاية الصحية. إنه يستند إلى المعيار   ISO 27001 وهو يركز بشكل خاص على التحديات الأمنية التي تواجهها مؤسسات الرعاية الصحية.",
        "SOC1 Audit report provides assurance of a service organization that are relevant to their customers financial reporting. It is commonly used by companies that provide outsourced services such as payroll processing, data center operations, or financial transaction processing.":"تقرير تدقيق يوفر تأكيدًا لمنظمة تقدم الخدمات لها صلة بالتقارير المالية التي يقدمها زبائنها. يتم استخدامه بشكل شائع من قبل الشركات التي تقدم خدمات من خلال شركات خارجية مثل معالجة كشوف الرواتب أو عمليات مركز البيانات أو معالجة المعاملات المالية.",
        "SOC2 Audit report is designed to provide assurances about the effectiveness of controls in place at a service organisation that are relevant to the security, availability, or processing integrity of the system used to process clients information, or the confidentiality or privacy of that information.":"تقرير تدقيق تم تصميمه لتقديم تأكيدات حول فعالية الضوابط المعمول بها في مؤسسة خدماتية ذات صلة بأمن أو توفر أو تكامل معالجة النظام المستخدم لمعالجة معلومات الزبائن، أو سرية أو خصوصية تلك المعلومات.",
        "Sarbanes-Oxley Information Technology General Controls - Set of guidelines designed to ensure the accuracy and reliability of financial reporting by improving IT systems security and integrity, ultimately increasing investor confidence and reducing corporate fraud.":"قانون  فيدرالي في الولايات المتحدة يتطلب من الشركات المتداولة علنًا إنشاء ضوابط داخلية فعالة حول التقارير المالية والحفاظ عليها. تم تصميم ضوابط ITGC SOX لضمان عمل أنظمة تكنولوجيا المعلومات بكفاءة وفعالية ولكون البيانات المالية الناتجة عن هذه الأنظمة دقيقة وموثوقة بها.",
        "Build your compliance framework":"بناء إطار الامتثال الخاص بك",
        "In addition to offering a range of pre-built compliance frameworks, we also provide our users with the ability to build custom frameworks. We understand that every business has unique security and privacy needs, and that a one-size-fits-all approach may not be suitable for everyone. That's why we've made it easy for you to build a framework that aligns with your specific regulatory requirements.\nOur platform offers a simple yet powerful interface that allows you to create and customize your compliance framework. You can select from a variety of controls, policies, and procedures that are tailored to your business needs. Our user-friendly interface makes it easy for you to add, modify, and remove controls as necessary, so you can stay up-to-date with any changes to your regulatory requirements.":"بالإضافة إلى تقديم مجموعة من أطر عمل الامتثال المعدة مسبقًا، فإننا نوفر أيضًا لمستخدمينا القدرة على إنشاء أطر عمل خاصة بهم. نحن ندرك أن لكل شركة احتياجات أمنية وخصوصية فريدة من نوعها، وأن نهجًا واحدًا يناسب الجميع قد لا يكون مناسبًا للجميع. لهذا السبب جعلنا من السهل عليك إنشاء إطار عمل خاص بك يتوافق مع متطلباتك التنظيمية المحددة.\n \nتوفر منصتنا واجهة بسيطة وإنما قوية تسمح لك بإنشاء وتخصيص إطار عمل الامتثال الخاص بك. يمكنك الاختيار من بين مجموعة متنوعة من الضوابط والسياسات والإجراءات التي تم تحديدها مسبقا لتلائم احتياجات عملك."
    },
    "faq":{
        "Frequently asked questions":"Frequently asked questions",
        "Why compliance is such important?":"Why compliance is such important?",
        "Here1":"Here",
        "What are the advantages to choose Regulait software?":"What are the advantages to choose Regulait software?",
        "Here2":"Here",
        "What level of access will Regulait have to my systems and data?":"What level of access will Regulait have to my systems and data?",
        "Here3":"Here"
    },
    "contact_us":{
        "First name":"الاسم الأول",
        "Last name":"اسم العائلة",
        "E-mail":"البريد الإلكتروني",
        "Company name":"اسم الشركة",
        "Phone number":"Phone number",
        "Your Message...":"رسالتك...",
        "I agree to the Terms and Conditions and Privacy Policy.":"أوافق على شروط الخدمة وسياسة الخصوصية",
        "Submit":"إرسال الرسالة",
        "Do you have any questions?":"هل لديك اسئلة؟",
        "Feel free to talk to us if you have any questions. Just fill the form and we will get in touch with you shortly.":"لا تتردد في التحدث إلينا إذا كانت لديك أي أسئلة. ما عليك سوى ملء الاستمارة وسنتواصل معك قريبًا."
    }
} satisfies Record<string, TTranslation>;

export type TTkey = (key: string) => (str: string) => string;
export const tKey: TTkey = (key) => (str) => (((translate as Record<string, TTranslation>)?.[key] as Record<string, string>)?.[str]) || str;
