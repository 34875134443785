import {TProductRow} from "./types";
import CrossFrameworkIcon from "../../shared/images/new/crossFramework.svg"
import DocumentsIcon from "../../shared/images/new/documents.svg";
import AuditSpaceIcon from "../../shared/images/new/auditSpace.svg";
import CollectorIcon from "../../shared/images/new/collector.svg";
import VikaIcon from "../../shared/images/new/VICA.svg";
import CollaborationIcon from "../../shared/images/new/collaboration.svg";

export const products: TProductRow[] = [
    {
        count: '01',
        title: 'Cross framework mapping',
        description: 'Built-in cross-framework mappings allow you to reuse integration data, evidence & policies, across multiple regulatory and industry frameworks such as ISO27001, NIST, PCI DSS and more, cutting down the completion time and resources by up to 80%.\nThe goal is to simplify the compliance process by allowing you to leverage the controls and best practices from one framework to meet the requirements of another framework.',
        img: CrossFrameworkIcon,
        reverse: false,
    },
    {
        count: '02',
        title: 'Document library & lifecycle automation',
        description: 'A rich library of standardized document templates that outline the guidelines, rules, and procedures for a particular area of business operations or compliance requirements, and can be used as a starting point to create your specific documents and policies. Document lifecycle can now be completely automatic using Regulait automation pipelines. Sharing, reviewing or signoff, all included.',

        img: DocumentsIcon,
        reverse: true,
    },
    {
        count: '03',
        title: 'Audit space',
        description: 'Get faster, more efficient audits, save hours of back-and-forth communication, never misplace crucial evidence, and share documentation instantly. All in one, intuitive suite. Regulait Reporting can be used to securely share sensitive content with official auditors, customers or prospects.',

        img: AuditSpaceIcon,
        reverse: false,
    },
    {
        count: '04',
        title: 'Automatic evidence collection & analysis',
        description: 'Benefit with less time and resources spent on manual data collection, avoid errors and inconsistencies in the analysis process. Now you can automatically track changes and updates to your compliance posture and quickly identify and address any compliance gaps. Demonstrate a commitment to responsible business practices with all stakeholders.',

        img: CollectorIcon,
        reverse: true,
    },
    {
        count: '05',
        title: 'VICA',
        description: 'An automated compliance support and guidance system. Using machine learning algorithms to provide relevant information, guidance, and support to manage compliance posture, understand and respond to inquiries and more. Regulait VICA can provide step-by-step guidance for compliance processes, and describe specific procedures to ensure compliance requirements are met. VICA is a cost-effective and convenient alternative to traditional compliance support, helping organizations stay informed and up-to-date on their compliance posture, required remediation tasks or updates to current regulatory requirements.',

        img: VikaIcon,
        reverse: false,
    },
    {
        count: '06',
        title: 'Collaboration',
        description: "Regulait collaboration functionality makes sure that all relevant parties and stakeholders work together to develop effective compliance strategies and processes, share best practices, and ensure that everyone is aware of their responsibilities. Improve the overall effectiveness and efficiency of an organization's compliance program by building trust with stakeholders, customers, and regulators through transparency and accountability.",
        img: CollaborationIcon,
        reverse: true,
    },
];
