import React, {ChangeEvent, FC, useState} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {ContactTextField, Container} from "../../../main/components/styled";
import {TContactForm} from "../../../main/types";
import Lines from "../../../../shared/images/new/lines.svg";
import {Footer} from "../../../barsEnvironment/footer";
import {useSnack} from "../../../snack/hooks/useSnack";
import {emailRegex} from "../../../main/constants";
import {LoadingButton} from "@mui/lab";
import {sendContactUsEmail} from "../../../../shared/api";

export const BecomePartner: FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [form, setForm] = useState<TContactForm>({
        firstName: '', lastName: '', companyName: '', email: '', message: '', contactNumber: ''
    })
    const handleChange = (field: keyof TContactForm) => (e: ChangeEvent<HTMLTextAreaElement>) => {
        setForm(prev => ({...prev, [field]: e.target.value}))
    }

    const snack = useSnack();
    const handleSubmit = async () => {
        if (form.firstName.trim() === '') {
            snack('First name should not be empty', "error");
            return;
        }
        if (form.lastName.trim() === '') {
            snack('Last name should not be empty', "error");
            return;
        }

        if (!emailRegex.test(form.email)) {
            snack(`${form.email} doesn't looks like 'email'`, "error");
            return;
        }

        if (form.message.trim() === '') {
            snack('Message should not be empty', "error");
            return;
        }

        try {
            setLoading(true);
            // await sendEmail({
            await sendContactUsEmail({
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email,
                companyName: form.companyName,
                contactNumber: form.contactNumber,
                message: form.message,
            }, (message) => snack(message, "error"));
            snack('Message sent', 'success');
            setForm({firstName: '', lastName: '', companyName: '', email: '', message: '', contactNumber: ''})
        } finally {
            setLoading(false);
        }
    }

    const theme = useTheme();
    const down800 = useMediaQuery(theme.breakpoints.down('c800'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box position={'relative'} overflow={"hidden"} pb={'2rem'}>
            <img src={Lines} alt={''} style={{
                position: 'absolute', top: 0, left: 0, zIndex: 0,
                width: '140%',
            }}/>

            <Container mt={'15rem'} position={'relative'} justifyContent={'space-around'}
                       alignItems={'center'} flexDirection={down1366 ? 'column' : undefined} gap={'3.2rem'}>
                <Box display={"flex"} flexDirection={"column"} zIndex={4} maxWidth={'min(100%, 73rem)'}>
                    <Typography variant={'h1'} textAlign={'start'}>
                        {'Become a partner'}
                    </Typography>
                    <Typography variant={"subtitle2"} mt={'3.2rem'} textAlign={'start'} textTransform={"uppercase"}>
                        {"Contact our team for more information"}
                    </Typography>
                    <Typography variant={"body1"} mt={'0.6rem'} textAlign={'start'}>
                        {"By becoming a partner, you'll gain access to our extensive knowledge and experience in the compliance industry. You'll also have the opportunity to participate in exclusive training and development programs and receive support from our dedicated partnership team."}
                    </Typography>
                </Box>

                <Box display={"flex"} flexDirection={"column"} p={!down800 ? '4rem' : '2rem 2rem'} zIndex={4} mt={'5rem'}
                     width={!down800 ? undefined : '100%'}
                     sx={{
                         background: '#FFFFFF',
                         boxShadow: '0rem 0.4rem 2rem rgba(0, 0, 0, 0.16)',
                         borderRadius: '1rem',
                     }}>
                    <Box display={"flex"} flexDirection={down800 ? "column" : undefined}>
                        <ContactTextField value={form.firstName} onChange={handleChange('firstName')} label={'First name'} name={'firstName'}/>
                        <div style={{width: '2rem', height: '2rem'}}/>
                        <ContactTextField value={form.lastName} onChange={handleChange('lastName')} label={'Last name'} name={'lastName'}/>
                    </Box>
                    <div style={{width: '2rem', height: '2rem'}}/>
                    <ContactTextField value={form.email} onChange={handleChange('email')} label={'E-mail'} name={'email'}/>
                    <div style={{width: '2rem', height: '2rem'}}/>
                    <ContactTextField value={form.contactNumber} onChange={handleChange('contactNumber')} label={'Contact number'} name={'contactNumber'}/>
                    <div style={{width: '2rem', height: '2rem'}}/>
                    <ContactTextField value={form.companyName} onChange={handleChange('companyName')} label={'Company name'} name={'company'}/>
                    <div style={{width: '2rem', height: '2rem'}}/>
                    <ContactTextField value={form.message} onChange={handleChange('message')} label={'Your Message...'} name={'comment'}
                                      multiline minRows={3} maxRows={3}/>


                    <div style={{width: '2rem', height: '2rem'}}/>
                    <LoadingButton loading={loading} variant={"contained"} fullWidth size={"large"} onClick={handleSubmit}>Submit</LoadingButton>
                </Box>
            </Container>


            <Footer/>
        </Box>
    )
}
