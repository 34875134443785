import {Box, Button, Link, ListItem, styled, TextField, Typography} from "@mui/material";


import {ReactComponent as GearRaw} from "../../../../shared/images/new/gear.svg";

// import {ReactComponent as DashboardRaw} from "../../../../shared/images/new/dashboard.svg";
import {ReactComponent as CircleLinesRaw} from "../../../../shared/images/new/circleLines.svg";
import React, {FC} from "react";
import colors from "../../../../shared/theme/colors";
import {KeyboardArrowLeft, KeyboardArrowRight} from "@mui/icons-material";
import {useDirection} from "../../../../shared/hooks/useDirection";

export const Container = styled(Box)<{}>(({theme, ...props}) => ({
    zIndex: 2,
    position: 'relative',

    display: 'flex',
    marginLeft: 'auto',
    marginRight: 'auto',

    width: 'min(calc(100% - 9.2rem * 2), calc(192rem - 9.2rem * 2))',

    [theme.breakpoints.down('c1920')]: {
        width: 'min(calc(100% - 4rem * 2), calc(192rem - 4rem * 2))',
    },

    [theme.breakpoints.down('c1366')]: {
        width: 'min(calc(100% - 2rem * 2), calc(192rem - 2rem * 2))',
    },
}))

export const RoundButton = styled(Button)<{}>(({theme, ...props}) => ({
    padding: '1.8rem !important',
    minWidth: '6rem !important',
    minHeight: '6rem !important',
    width: '6rem !important',
    height: '6rem !important',
    borderRadius: '100% !important',
    boxShadow: 'none',

    '& svg': {
        width: '2.7rem !important',
        height: '2.7rem !important',
        color: '#323232',
    }
}))

export const ContactTextField = styled(TextField)(({theme, multiline, value, focused}) => ({

    '& .MuiInputBase-root': {
        fontSize: '1.6rem',
        fontWeight: 400,
        lineHeight: '2.3rem',
        borderRadius: '1rem',
        ...(multiline ? {
            // minHeight: '5.1rem',
        } : {
            height: '5.1rem',
        }),
    },

    '& label.MuiFormLabel-root': {
        fontSize: '1.4rem',
    },

    '& label.MuiInputLabel-shrink': {
        transform: 'translate(1.4rem, -0.7rem) scale(0.75)',
        // transform: typeof value === "string" && value === '' && !focused ? 'none' : 'translate(1.4rem, -0.7rem) scale(0.75)',
        // transform: 'none',
    },

    '& legend': {
        fontSize: '1.05rem'
    },

    [theme.breakpoints.down('c1366')]: {
        '& label.MuiFormLabel-root': {
            fontSize: '1.5rem',
            marginTop: '-0.25rem',
        },

        '& legend': {
            fontSize: '1.1rem'
        },
    },

    [theme.breakpoints.down('c375')]: {
        '& label.MuiFormLabel-root': {
            fontSize: '1.5rem',
            marginTop: '-0.9rem',
        },

        '& label.MuiInputLabel-shrink': {
            transform: 'translate(1.8rem, 0.1rem) scale(0.75)',
            // transform: typeof value === "string" && value === '' && !focused ? 'none' : 'translate(1.4rem, -0.7rem) scale(0.75)',
            // transform: 'none',
        },

        '& legend': {
            fontSize: '1.1rem'
        },
    },

    // '& label.MuiInputLabel-shrink': typeof value === "string" && value === '' && !focused ? {
    //     transform: 'none',
    // } : {},
}))


export const FooterListItem = styled(ListItem, {
    shouldForwardProp: (prop) => !['isTitle', 'isLink', 'isPath'].includes(prop as string)
})<{isTitle?: boolean, isLink?: boolean, isPath?: boolean, component?: typeof Link, href?: string}>(({theme, isTitle, isLink, isPath, ...props}) => ({
    ...(isTitle ? {
        fontWeight: 500,
        fontSize: '1.7rem',
        lineHeight: '2.55rem',
    } : {
        fontWeight: 400,
        fontSize: '1.6rem',
        lineHeight: '2.4rem',
        color: '#616365',
    }),

    ...(isLink ? {color: '#4788C9'} : {}),

    '&:hover': isLink || isPath ? {
        color: '#072C51',
        cursor: 'pointer',
    } : {},
}))

export const UnderFooterText = styled(Typography, {
    shouldForwardProp: (prop) => !['isLink'].includes(prop as string)
})<{isLink?: boolean, component?: typeof Link, href?: string}>(({theme, isLink, ...props}) => ({
    fontWeight: 400,
    fontSize: '1.4rem',
    lineHeight: '1.8rem',
    color: '#81939E',

    '&:hover': isLink ? {
        cursor: 'pointer',
        // color: '#4788C9',
    } : {}
}))

export const Gear = styled(GearRaw)(({theme, ...props}) => ({
    height: '13.2rem',
    width: '13.2rem',
}))

export const CircleLines = styled(CircleLinesRaw)(({theme, ...props}) => ({
    height: '20.1rem',
    width: '20.1rem',
}))

export const SeeMoreButton: FC<{title: string, link: string, center?: boolean}> = ({title, link, center}) => {
    const {revDir} = useDirection();

    return (
        <Box display={"flex"} alignItems={"center"} alignSelf={"flex-start"} flexShrink={1} mt={'2rem'} gap={'0.8rem'} sx={{cursor: 'pointer', ...(center ? {ml: 'auto', mr: 'auto'} : {})}} component={Link} href={link}>
            <Typography variant={"body1"} color={colors.landing.text2}>
                {title}
            </Typography>
            {!revDir ? <KeyboardArrowRight sx={{color: colors.landing.text2}}/> : <KeyboardArrowLeft sx={{color: colors.landing.text2}}/>}
        </Box>
    )
}