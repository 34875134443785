import React, {FC} from 'react';
import {Header} from "../barsEnvironment/header";
import {BlogTop} from "./components/blog";
import {BlogCards} from "./components/blogCards";
import {BlogFooter} from "./components/blogFooter";

export const Blog: FC = () => {

    return (
        <>
            <Header/>

            <BlogTop/>
            <BlogCards/>

            <BlogFooter/>

            {/*<Footer/>*/}
        </>
    )
}