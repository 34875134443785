import React from "react";
import {Box, styled, useScrollTrigger} from "@mui/material";

// function for create elevation on scroll for header
export function ElevationScroll(props: { window?: () => Window; children: React.ReactElement }) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: 0,
        sx: trigger
            ? {
                background: 'linear-gradient(107.56deg, #FFFFFF 0%, #F0F6FB 54.69%, #FFFFFF 100%)',
                p: '1rem 0',
                boxShadow: '0rem 0.1rem 0.5rem #D2E0EC',
            }
            : {p: '1rem 0'}
    });
}

export const TopTextButton = styled(Box)<{href?: string, target?: string}>(({theme, fontWeight}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    flexShrink: 1,

    cursor: 'pointer',

    height: '100%',
    gap: '1rem',

    whiteSpace: 'nowrap',
}))