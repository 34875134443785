import {useHistory, useLocation} from "react-router";

export const useRoutes = ({func}: {func: () => void} = {func: () => {}}) => {

    const history = useHistory();
    const {pathname} = useLocation();
    // const moveInside = useAnalyticsEventTracker('moveInside');
    // const moveOutside = useAnalyticsEventTracker('moveOutside');


    // const handleHistoryNewTab = (link: string) => () => {
    //     // goOutside('')
    //     const a = document.createElement('a');
    //     a.href = link;
    //     a.target = '_blank';
    //     a.click();
    // }
    //
    // const handleHistoryThisTab = (link: string) => () => {
    //     // goInside('')
    //     const a = document.createElement('a');
    //     a.href = link;
    //     a.click();
    // }
    //
    const handleHistoryPush = (link: string) => () => {
        // goInside('')
        func();
        if (pathname === link) window.scrollTo(0, 0)
        else history.push(link)
    }
    //
    // const handleHistoryAnchor = (anchor: string, link?: string) => () => {
    //     // goInside('')
    //     func();
    //     if (anchor.length === 0) window.scrollTo(0, 0)
    //     history.push(
    //         anchor.length > 0
    //             ? `${link || pathname}#${anchor}`
    //             : link || pathname
    //     )
    // }

    return {
        history,
        // handleHistoryNewTab,
        // handleHistoryThisTab,
        handleHistoryPush2: handleHistoryPush,
        // handleHistoryAnchor,
    }
}