import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

export const PrivacyPolicy: FC = () => {

    // const theme = useTheme();
    // const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%',
            '& .MuiTypography-subtitle1': {
                marginBottom: '0',
            },
            '& .MuiTypography-body1': {
                marginTop: '1rem',
            },
        }}>
            <Typography variant={'h1'}>Privacy Policy and Notice</Typography>
            <Typography variant={"body1"}>Last updated: June 19, 2023</Typography>


            <Typography variant={"body2"}>Regulait Security Ltd. (“Regulait”, “we”, “our” or the “Company”, and their cognates) respects the privacy of visitors of its website (the “Website”) and the users of its online platform (the “Platform”), and is committed to protecting the personal information you may share with us (these and any others with respect to whom we collect personal data, shall collectively be referred to as “Customers”, “Users”, “you” or “Data Subjects”). The Platform allows clients to manage, monitor and implement compliance requirements and security standards (the “Services”).</Typography>
            <Typography variant={"body2"}>This policy and notice (the “Privacy Policy”) explains the types of information we may collect from you, that we may receive about you or that you may provide in the course of your interest in our Services and business transactions. We are transparent about our practices regarding the information we may collect, use, maintain and process and describe our practices in this policy and notice. Please read the following carefully to understand our practices regarding your personal data and how we will treat it.</Typography>
            <Typography variant={"body2"}>For the purposes of EU General Data Protection Regulation (the “GDPR”), the California Consumer Privacy Act, as amended, (“CCPA”) and other applicable data protection laws, we will usually be a data controller (or a business, under CCPA) in relation to the Personal Data of our Customers and Users of the Platform. Note however that with regards to the client content processed through the Platform Regulait is a data processor (service provider) and therefore this Privacy Policy does not apply to such processing.</Typography>
            <Typography variant={"subtitle1"}>WHICH INFORMATION MAY WE COLLECT?</Typography>
            <Typography variant={"body1"}>Summary: we collect various categories of personal data in order to meet our contractual obligations, and also to meet various legitimate interests, such as fraud prevention and marketing.</Typography>
            <Typography variant={"body2"}>We collect data about you in connection with your transactions with us, or in processing data for our users. We also collect data about  website visitors. One type of data collected is non-identifiable and anonymous information (“non-personal data”). We also collect several categories of personal data (“Personal Data”), as described below.</Typography>
            <Typography variant={"body2"}>Personal Data which is being gathered consists of any details which are personally identifiable and which are provided consciously and voluntarily by you, through our Services or your registration and use of our Website (as described below) and Platform, by email, or other ways in which you communicate and interact with us. This generally includes your name (first and last), email address, company, phone number, role, country, social media accounts, actions, and online identifiers such as IP address. Additionally, we may obtain location data related to the geographic location of your laptop, mobile device or other digital device on which our website is used. We also process your Platform profile data and how you use and interact with the Services.</Typography>
            <Typography variant={"body2"}>You do not have any legal obligation to provide any information to us, however, we require certain information in order to perform contracts, or to provide any Services. If you choose not to provide us with certain information, then we may not be able to provide you or your organization with some or all of the Services.</Typography>
            <Typography variant={"body2"}>By contacting us or submitting requests for information or support via the website, email etc., we will collect details, including also your name, personal or company email you provided, country, handling history of your case and other information provided by you. We use this information to offer our Services and support.</Typography>
            <Typography variant={"subtitle1"}>HOW DO WE COLLECT PERSONAL DATA OF YOURS ON OUR SERVICES?</Typography>
            <Typography variant={"body1"}>Summary: we collect Personal Data when you or your organization send it to us, or when a vendor, distributor or other business partner, sends it to us; we collect Personal Data through our website and Services, and through our interactions with you.</Typography>
            <Typography variant={"body2"}>We collect Personal Data required to provide Services when you register interest, or when you provide us such information, by entering it manually or automatically.</Typography>
            <Typography variant={"body2"}>We collect Personal Data in the course of preparing a contract with you or otherwise engaging with us, in particular, your name, role, email, and phone number.</Typography>
            <Typography variant={"body2"}>We also collect Personal Data when you call us for support or fill in a contact form on the Website, in which case we collect the information you provide us.</Typography>
            <Typography variant={"body2"}>We also collect Personal Data through your use of the Website and Platform. In other words, when you are using the Website and Platform, we are aware of it and may gather, collect and record the information relating to such usage, either independently or through the help of third-party services as detailed below. This includes technical information and behavioral information such as the user’s Internet protocol (IP) address used to connect your device to the Internet or an identifier attributed to you by a third party, your uniform resource locators (URL), operating system, type of browser, browser plug-in types and versions, screen resolution, time zone setting, the user’s ‘clickstream’ on the platform, the period of time the user visited the platform, methods used to browse away from a page, and any phone number used to call our users’ service number.</Typography>
            <Typography variant={"body2"}>We likewise may place cookies on your browsing devices (see 'Cookies' section below).</Typography>
            <Typography variant={"subtitle1"}>WHAT ARE THE PURPOSES OF PERSONAL DATA WE COLLECT?</Typography>
            <Typography variant={"body1"}>Summary: we process Personal Data to meet our obligations, protect our rights, and manage our business.</Typography>
            <Typography variant={"body2"}>We will use Personal Data to provide and improve our Services to our Customers, Users and others and meet our contractual, ethical and legal obligations. All Personal Data will remain accurate complete and relevant for the stated purposes for which it was processed, including for example:</Typography>
            <Typography variant={"body1"}>Processing which is necessary for the performance of a contract to which you are a party or in order to take steps at your request prior to entering into a contract:</Typography>
            <ul>
                <li>            carrying out our obligations arising from any contracts entered into between you and Regulait and/or any contracts entered into with us and to provide you with the Services as well as update, maintain, support and improve the Services that you request from Regulait, and to communicate with you accordingly;</li>
                <li>            To sign up and create your account on the Platform and sign in as well as to identify you when you visit or use the Website or the Platform;</li>
                <li>            verifying and carrying out financial transactions in relation to payments you make in connection with the Services.</li>
            </ul>

            <Typography variant={"body1"}>Processing which is necessary for the purposes of the legitimate interests pursued by Regulait or by a third party of providing an efficient and wide-ranging service to users:</Typography>
            <ul>
                <li>            notifying you about changes to our Services and/or to products we manage, new items and features on the Website or the Platform;</li>
                <li>            contacting you via email, SMS, telephone or any other electronic means, including social media and other platforms to give you commercial and marketing information about events or promotions or additional products or Services offered by Regulait which may be of interest to you, including advertisements on social media and other platforms;</li>
                <li>            soliciting feedback in connection with the Services;</li>
                <li>            tracking use of Regulait Services, analyzing user behavior to enable us to optimize them;</li>
                <li>            for security purposes.</li>
            </ul>

            <Typography variant={"body1"}>Processing which is necessary for compliance with a legal obligation to which Regulait is subject:</Typography>
            <ul>
                <li>            compliance and audit purposes, such as meeting our reporting obligations in our various jurisdictions, anti money laundering, tax related obligations, and for crime prevention and prosecution in so far as it relates to our staff, users, service providers, facilities etc;</li>
                <li>            if necessary, we will use Personal Data to enforce our terms, policies and legal agreements, to comply with court orders and warrants and assist law enforcement agencies as required by law, to collect debts, to prevent fraud, infringements, identity thefts and any other service misuse, and to take any action in any legal dispute and proceeding.</li>
            </ul>

            <Typography variant={"subtitle1"}>SHARING DATA WITH THIRD PARTIES</Typography>
            <Typography variant={"body1"}>Summary: we share Personal Data with our service providers, partners, and authorities where required.</Typography>
            <Typography variant={"body2"}>We transfer personal data to third parties in a variety of circumstances. We endeavor to ensure that these third parties use your information only to the extent necessary to perform their functions, and to have a contract in place with them to govern their processing on our behalf. These third parties may include business partners, suppliers, affiliates, agents and/or sub-contractors for the performance of any contract we enter into with you. They may assist us in providing the services we offer, processing transactions, fulfilling requests for information, receiving and sending communications, analysing data, providing IT and other support services or in other tasks, from time to time. These third parties may also include analytics and search engine providers that assist us in the improvement and optimisation of our website, and our marketing.</Typography>
            <Typography variant={"body2"}>We periodically add and remove third party providers. At present our third-party providers to whom we may transfer personal data include also the following:</Typography>
            <ul>
                <li>            Website analytics;</li>
                <li>            E-signature platforms;</li>
                <li>            Cloud server providers;</li>
                <li>            Customers communication service providers;</li>
                <li>            Partners and eCommerce platforms;</li>
                <li>            Document management and sharing services;</li>
                <li>            User ticketing and support;</li>
                <li>            On-site and cloud-based database services;</li>
                <li>            CRM software;</li>
                <li>            Data security, data backup, and data access control systems;</li>
                <li>            Marketing, user acquisition and retargeting platforms;</li>
                <li>            Our affiliates;</li>
                <li>            Our lawyers, accountants, and other standard business software and partners.</li>
            </ul>


            <Typography variant={"body2"}>In addition, we may disclose your personal data to third parties if some or all of our companies or assets are acquired by a third party including by way of a merger, share acquisition, asset purchase or any similar transaction, in which case personal data may be one of the transferred assets. Likewise, we may transfer personal data to third parties if we are under a duty to disclose or share your personal data in order to comply with any legal or audit or compliance obligation, in the course of any legal or regulatory proceeding or investigation, or in order to enforce or apply our terms and other agreements with you or with a third party; or to assert or protect our rights, property, or safety, or those of our customers or others. This includes exchanging information with other companies and organisations for the purposes of fraud protection and credit risk reduction and to prevent cybercrime.</Typography>
            <Typography variant={"body2"}>For avoidance of doubt, we may transfer and disclose non-personal data to third parties at our own discretion.</Typography>
            <Typography variant={"body2"}>Selling or Sharing of Personal Information under the CCPA. For purposes of the CCPA, we do not ‘sell’ or ‘share’ personal information, nor do we allow any personal information to be used by third parties for their own marketing purposes (except where you explicitly requested or provided consent for us to do so). However, we use analytics tools and other technologies (as described above), which may be construed as a ‘sale’ under CCPA, to which you may opt-out of.</Typography>
            <Typography variant={"body2"}>We may share anonymous and de-identified data to our business partners. Such data includes, for example, statistical information about the number of visitors on the Website and Platform users. For avoidance of doubt, we may transfer and disclose non-Personal Data to third parties at our own discretion.</Typography>

            <Typography variant={"subtitle1"}>WHERE DO WE STORE YOUR DATA?</Typography>
            <Typography variant={"body1"}>Summary: we store your Personal Data across multiple locations globally</Typography>
            <Typography variant={"body2"}>We store your Personal Data on servers owned or controlled by us, or processed by third parties on our behalf, by reputable cloud-service providers (see the following section regarding international transfers).</Typography>

            <Typography variant={"subtitle1"}>INTERNATIONAL DATA TRANSFERS</Typography>
            <Typography variant={"body1"}>Summary: we transfer Personal Data within and to the EEA, USA, Israel and elsewhere, with appropriate safeguards in place.</Typography>
            <Typography variant={"body2"}>Depending on your location, Personal Data may be transferred to, and stored and processed at, a destination outside your location. EU Personal data is transferred outside the European Economic Area (EEA). This includes transfer to our headquarters in Israel, a jurisdiction deemed adequate by the EU Commission, and to the USA, not currently deemed adequate. Where your Personal Data is transferred outside of the EEA, we will take all steps reasonably necessary to ensure that your Data is subject to appropriate safeguards, including entering into contracts that require the recipients to adhere to data protection standards that are considered satisfactory under EU law and other applicable, and that it is treated securely and in accordance with this Privacy Policy. Transfers to Israel are made based on an adequacy ruling by the EU Commission. Transfers to the USA are made based on the Standard Contractual Clauses published by the EU Commission. For more information about these safeguards, please contact us as set forth below.</Typography>
            <Typography variant={"body2"}>We transfer Personal Data to locations outside of the EEA, including in particular USA and Israel, in order to:</Typography>
            <ul>
                <li>            store or backup the information;</li>
                <li>            enable us to provide you with the Services and products and fulfill our contract with you;</li>
                <li>            fulfill any legal, audit, ethical or compliance obligations which require us to make that transfer;</li>
                <li>            facilitate the operation of our group businesses, where it is in our legitimate interests and we have concluded these are not overridden by your rights;</li>
                <li>            to serve our users across multiple jurisdictions; and</li>
                <li>            to operate our affiliates in an efficient and optimal manner.</li>
            </ul>

            <Typography variant={"subtitle1"}>DATA RETENTION</Typography>
            <Typography variant={"body1"}>Summary: we retain Personal Data according to our data retention policy, as required to meet our obligations, protect our rights, and manage our business.</Typography>
            <Typography variant={"body2"}>Regulait will retain Personal Data it processes only for as long as required in our view, to provide the Services and as necessary to comply with our legal and other obligations, to resolve disputes and to enforce agreements. We will also retain Personal Data to meet any audit, compliance and business best-practices.</Typography>
            <Typography variant={"body2"}>Data that is no longer retained will be anonymized or deleted. Likewise, some metadata and statistical information concerning the use of our Services are not subject to the deletion procedures in this policy and will be retained by Regulait. We will not be able to identify you from this data. Some data may also be retained on our third-party service providers’ servers until deleted in accordance with their privacy policy and their retention policy, and in our backups until overwritten.</Typography>
            <Typography variant={"subtitle1"}>SERVICES AND WEBSITE DATA COLLECTION AND COOKIES</Typography>
            <Typography variant={"body1"}>Summary: with your consent, we place cookies on your device. You control our use of cookies through a cookie management tool on our website, or through your device and browser.</Typography>
            <Typography variant={"body2"}>Regulait uses cookies, pixel tags and other forms of identification and local storage (together referred to as “tags/files” hereunder) to distinguish you from other users of the Website or Platform. This helps us to provide you with a good user-experience when you browse the Website or Platform and also allows us to improve our Services.</Typography>
            <Typography variant={"body2"}>In many cases, these tags/files lead to the use of your device’s processing or storage capabilities. Some of these tags/files are set by Regulait  itself, others by third parties; some only last as long as your browser session, while others can stay active on your device for a longer period of time.</Typography>
            <Typography variant={"body2"}>These tags/files can fall into several categories: (i) those that are necessary for functionality of Website, Platform or Services that you request or for the transmission of communications (functionality tags/files); (ii) those that we use to carry out website performance and audience metrics (analytics tags/files) and (iii) the rest (tracking across a network of other websites, advertising, etc.) (other tags/files).</Typography>
            <Typography variant={"body2"}>Internet browsers allow you to change your cookie settings, for example to block certain kinds of cookies or files. You can therefore block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies, you may not be able to access all or parts of the website, due to the fact that some may be functionality cookies. For further information about deleting or blocking cookies, please visit: https://www.aboutcookies.org/how-to-delete-cookies/</Typography>
            <Typography variant={"body2"}>Functionality tags/files do not require your consent. For other tags/files, however, we request your consent before placing them on your device. You can allow cookies in your browser settings and by using our website cookie management too.</Typography>
            <Typography variant={"body2"}>To consult the list of cookies which we use on our website, please check your browser's settings. Instructions: https://www.wikihow.com/View-Cookies.</Typography>
            <Typography variant={"subtitle1"}>SECURITY AND STORAGE OF INFORMATION</Typography>
            <Typography variant={"body1"}>Summary: we take data security very seriously, invest in security systems, and train our staff. In the event of a breach, we will notify the right people as required by law.</Typography>
            <Typography variant={"body2"}>We take great care in implementing, enforcing and maintaining the security of the Personal Data we process. Regulait implements, enforces and maintains security measures, technologies and policies to prevent the unauthorized or accidental access to or destruction, loss, modification, use or disclosure of Personal Data. We likewise take steps to monitor compliance of such policies on an ongoing basis. Where we deem it necessary in light of the nature of the data in question and the risks to data subjects, we encrypt data. Likewise, we take industry standard steps to ensure our website and Services are safe.</Typography>
            <Typography variant={"body2"}>Note however, that no data security measures are perfect or impenetrable, and we cannot guarantee that unauthorized access, leaks, viruses and other data security breaches will never occur.</Typography>
            <Typography variant={"body2"}>Within Regulait , we endeavor to limit access to Personal Data to those of our personnel who: (i) require access in order for Regulait to fulfill its obligations, including also under its agreements, and as described in this Privacy Policy, and (ii) have been appropriately and periodically trained with respect to the requirements applicable to the processing, care and handling of the Personal Data, and (iii) are under confidentiality obligations as may be required under applicable law.</Typography>
            <Typography variant={"body2"}>Regulait shall act in accordance with its policies and with applicable law to promptly notify the relevant authorities and data subjects in the event that any Personal Data processed by Regulait is lost, stolen, or where there has been any unauthorized access to it, all in accordance with applicable law and on the instructions of qualified authority. Regulait shall promptly take reasonable remedial measures..</Typography>
            <Typography variant={"subtitle1"}>DATA SUBJECT RIGHTS</Typography>
            <Typography variant={"body1"}>Summary: depending on the law that applies to your Personal Data, you may have various data subject rights, such as rights to access, erase, and correct Personal Data, and information rights. We will respect any lawful request to exercise those rights.</Typography>
            <Typography variant={"body2"}>Data subjects with respect to whose data GDPR, CCPA or other data protection or privacy laws apply, have rights under applicable laws, including, in different circumstances, rights to data portability, rights to access data, rectify data, object to processing, and erase data. It is clarified for the removal of doubt, that where Personal Data is provided by a user being the data subject's employer, such data subject rights will have to be effected through that user, the data subject’s employer. In addition, data subject rights cannot be exercised in a manner inconsistent with the rights of Regulait employees and staff, with Regulait proprietary rights, and third-party rights. As such, job references, reviews, internal notes and assessments, documents and notes including proprietary information or forms of intellectual property, cannot be accessed or erased or rectified by data subjects. In addition, these rights may not be exercisable where they relate to data that is not in a structured form, for example emails, or where other exemptions apply. If processing occurs based on consent, data subjects have a right to withdraw their consent.</Typography>
            <Typography variant={"body2"}>A data subject who wishes to modify, delete or retrieve their Personal Data, may do so by contacting us (privacy@regulait.com). Note that we may have to undertake a process to identify a data subject exercising their rights. We may keep details of such rights exercised for its own compliance and audit requirements. Please note that Personal Data may be either deleted or retained in an aggregated manner without being linked to any identifiers or Personal Data, depending on technical commercial capability. Such information may continue to be used by us.</Typography>
            <Typography variant={"body2"}>Data subjects in the EU have the right to lodge a complaint, with a data protection supervisory authority in the place of their habitual residence. If the supervisory authority fails to deal with a complaint, you may have the right to an effective judicial remedy.</Typography>
            <Typography variant={"subtitle1"}>GENERAL</Typography>
            <Typography variant={"body2"}>Minors. We do not knowingly collect or solicit information or data from or about children under the age of 16 without parental consent, or knowingly allow children under the age of 16 to register for Regulait Services. If you are under 16, do not register or attempt to register for any of the Regulait Services or send any information about yourself to us. If we learn that we have collected or have been sent Personal Data from a child under the age of 16 without appropriate permissions, we will delete that Personal Data as soon as reasonably practicable without any liability to Regulait. If you believe that we might have collected or been sent information from a minor under the age of 16, please contact us at: privacy@regulait.com, as soon as possible.</Typography>
            <Typography variant={"body2"}>Changes to this Privacy Policy. The terms of this Privacy Policy will govern the use of the Services and website and any information collected in connection with them. We may amend or update this Privacy Policy from time to time. The most current version of this Privacy Policy will be available here. Changes to this Privacy Policy are effective as of the stated “Last Revised” date and your continued use of our Services will constitute your active acceptance of the changes to and terms of the Privacy Policy.</Typography>
            <Typography variant={"body2"}>Regulait aims to process only adequate, accurate and relevant data limited to the needs and purposes for which it is gathered. It also aims to store data for the time period necessary to fulfill the purpose for which the data is gathered. Regulait only collects data in connection with a specific lawful purpose and only processes data in accordance with this Privacy Policy. Our policies and practices are constantly evolving and improving, and we invite any suggestions for improvements, questions, complaints or comments concerning this Privacy Policy, you are welcome to contact us (details below) and we will make an effort to reply within a reasonable timeframe.</Typography>
            <Typography variant={"body2"}>Regulait’s data protection officer (DPO) may be contacted at:  privacy@regulait.com.</Typography>

        </Box>
    )
}