import React, {FC} from "react";
import {Container, Gear} from "../styled";
import {Box, Grid, Typography} from "@mui/material";
import {globalBusinesses} from "../../constants";


export const GlobalBusinesses: FC = () => {

    return (
        <Container mt={'16.9rem'} flexDirection={'column'}>
            <Box position={"relative"} height={'9.6rem'} display={"flex"} alignItems={"center"}>
                <Gear style={{position: 'absolute', zIndex: 1, top: '-1.8rem', left: '-4.5rem'}}/>

                <Typography variant={"subtitle1"} color={'rgba(0,0,0,0.87)'} textTransform={'uppercase'}
                            zIndex={3}>
                    {'Global businesses trust Regulait'}
                </Typography>
            </Box>

            <Grid container spacing={3} position={"relative"} justifyContent={'center'}>
                {globalBusinesses.map(el => (
                    <Grid item key={el.id} display={"flex"} justifyContent={"center"} alignItems={"center"} zIndex={3}
                          xs={4} c800={3} c1366={2} c1680={2} c1920={2} sx={{p: '0 1rem'}}>
                        {/*<img src={el.icon} alt={el.id} style={{maxWidth: '18.5rem', maxHeight: '18.5rem'}}/>*/}
                        <img src={el.icon} alt={el.id} style={{maxWidth: '100%', maxHeight: '9rem'}}/>
                    </Grid>
                ))}

                <Gear style={{position: 'absolute', zIndex: 1, top: '-3rem', right: '-6.1rem', scale: '0.75'}}/>
            </Grid>

        </Container>
    )
}
