import React, {FC} from "react";
import {Box, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import {TTkey} from "../../translates/arabic";
import {suiteCards} from "../../../main/constants";
import {ANCHOR_SUITE} from "../../constants";

export const Suite: FC<{tKey: TTkey}> = ({tKey}) => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const t = tKey('suite');

    const title = t('Compliance is easier together');
    const description = t('At Regulait, we understand the challenges of maintaining compliance in today\'s fast-paced business world. That\'s why we\'ve created the Ultimate Compliance Solution - a comprehensive platform that brings all your compliance-related tasks under one roof.');

    return (
        <Container alignItems={'center'} flexDirection={'column'} {...(down1366 ? {mt: '10rem'} : {mt: '14rem'})} id={ANCHOR_SUITE}>
            <Box width={'min(100%, 105rem)'}>
                <Typography variant={'h1'} textAlign={"center"}>
                    {title}
                </Typography>
                <Typography variant={"body1"} marginTop={'1.6rem'} textAlign={"center"}>
                    {description}
                </Typography>
            </Box>

            <Grid container spacing={4} columns={12} sx={{mt: '3.2rem'}}>
                {suiteCards(true)
                    .filter(e => !e.comingSoon)
                    .map(item => (
                        <Grid item key={JSON.stringify(item)} xs={12} c800={6} c1366={4}>
                            <Box sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                padding: down1024 ? '3.6rem 2.4rem' : down1366 ? '4rem 2.8rem' : '4.4rem 3.2rem',

                                boxShadow: '0rem 0rem 0.5rem rgba(0, 0, 0, 0.15)',
                                borderRadius: '2rem',
                                height: '100%',

                                background: '#FFFFFF',
                                // '&:hover': {
                                //     background: '#FFFFFF',
                                // },
                            }}>
                                <img src={item.icon} alt={item.title + ' img'} style={{height: '6rem'}}/>

                                <Typography variant={"h2"} textAlign={'center'} mt={'1.6rem'} >
                                    {t(item.title)}
                                </Typography>

                                <Typography variant={"body1"} textAlign={'center'} mt={'1rem'}>
                                    {t(item.description)}
                                </Typography>
                            </Box>
                        </Grid>
                    ))}
            </Grid>
        </Container>
    )
}