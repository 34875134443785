import React, {FC} from "react";
import {
    Box,
    Button,
    Divider,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Container} from "../../../main/components/styled";

import Lines from "../../../../shared/images/new/lines.svg"
import MostPopular from "../../../../shared/images/new/mostPopular.svg"

import {PATH_REGISTRATION, tariffs} from "../../../main/constants";
import {DoneRounded} from "@mui/icons-material";

const title = 'Simple Plans, Transparent Pricing';
const description = "Regulait Suite applies to companies of all shapes and sizes. We have a plan suitable for every customer type, ensuring we help as many companies as possible to be assured in their compliance posture at all times.";

// const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
//     <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//     [`& .${tooltipClasses.tooltip}`]: {
//         backgroundColor: 'rgba(250, 253, 255, 1)',
//         color: colors.landing.text,
//         fontWeight: 500,
//         fontSize: '1.4rem',
//         lineHeight: '2.4rem',
//         borderRadius: '0.8rem',
//         filter: 'drop-shadow(0px 8px 20px rgba(125, 151, 174, 0.16))',
//     },
//     [`& .${tooltipClasses.arrow}`]: {
//         color: 'rgba(250, 253, 255, 1)',
//     },
// }));

export const Plans: FC = () => {

    const theme = useTheme();
    const down440 = useMediaQuery(theme.breakpoints.down('c440'));
    const down800 = useMediaQuery(theme.breakpoints.down('c800'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    // const [yearly] = useState<boolean>(false);

    return (
        <Box position={'relative'} >
            <img src={Lines} alt={''} style={{
                position: 'absolute', top: 0, left: '0', zIndex: 0, minWidth: '100%',
            }}/>

            <Container mt={!down1366 ? '14rem' : '10rem'} flexDirection={'column'} alignItems={'center'}
                       sx={{position: 'relative'}}
                       pb={'1rem'}>

                <Typography variant={'h1'} textAlign={'center'}>
                    {title}
                </Typography>

                <Typography variant={"body1"} textAlign={'center'} mt={'1.6rem'} maxWidth={'min(100%, 115.4rem)'}>
                    {description}
                </Typography>

                {/*<Box sx={{*/}
                {/*    mt: '8rem',*/}
                {/*    display: "flex",*/}
                {/*    filter: 'drop-shadow(2px 2px 5px #D0D4D8)',*/}
                {/*}}>*/}
                {/*    <PriceSwitch onClick={() => setYearly(prev => !prev)} sx={{cursor: 'pointer'}}>*/}
                {/*        <Box className="mask" style={{transform: `translateX(${!yearly ? 0 : "12.5rem"})`}}/>*/}
                {/*        <Button disableRipple variant="text" >*/}
                {/*            <Typography sx={{ color: yearly ? 'rgba(167, 171, 174, 1)' : "rgba(0, 0, 0, 0.87)" }} variant={"button"}>{'Monthly'}</Typography>*/}
                {/*        </Button>*/}
                {/*        <LightTooltip title={'Save 20%'} placement={'top'} arrow open>*/}
                {/*            <Button disableRipple variant="text" >*/}
                {/*                <Typography sx={{ color: !yearly ? 'rgba(167, 171, 174, 1)' : "rgba(0, 0, 0, 0.87)" }} variant={"button"}>{'Yearly'}</Typography>*/}
                {/*            </Button>*/}
                {/*        </LightTooltip>*/}
                {/*    </PriceSwitch>*/}
                {/*</Box>*/}

                <Box display={"flex"} gap={'4rem'} mt={'3rem'} flexWrap={down1366 ? "wrap" : undefined} justifyContent={"center"}>
                    {tariffs.map(e => {

                        // let price = !yearly ? e.price : e.priceYear;
                        // let priceText = price && (price > 0) ? '$' + price : 'Free';

                        return (
                            <Box key={e.name} position={"relative"}
                                 flexBasis={'33%'} minWidth={'min(100%, 41.5rem)'}
                                 sx={{
                                     display: 'flex',
                                     flexDirection: 'column',
                                     padding: !down800 ? '5.3rem 5rem' : '3.2rem 5rem',

                                     background: '#FFFFFF',
                                     // border: '1px solid #B8B4D2',

                                     boxShadow: '0rem 0.1rem 1rem rgba(0, 0, 0, 0.2), 0rem 0.4rem 0.5rem rgba(0, 0, 0, 0.12), 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.14)',
                                     borderRadius: '2rem',
                                 }}>

                                <Box display={"flex"} flexDirection={"column"}>
                                    <Typography variant={'price'} color={'rgba(67, 48, 184, 0.87)'}>
                                        {e.name}
                                    </Typography>
                                </Box>

                                <Divider flexItem style={{marginTop: '3.2rem'}}/>

                                <Typography variant={"body1"} color={'rgba(0, 0, 0, 0.6)'} mt={'6.4rem'}>
                                    {e.description}
                                </Typography>

                                <Typography variant={"subtitle2"} color={'rgba(84, 84, 84, 1)'} mt={'3.2rem'}
                                            textTransform={"uppercase"}>
                                    {'Key features available'}
                                </Typography>
                                <List sx={{mb: '6.4rem'}}>
                                    {e.keys.map(e => (
                                        <ListItem key={e} sx={{
                                            paddingLeft: 0,
                                            paddingTop: !down1366 ? '0.8rem' : '0.4rem',
                                            paddingBottom: !down1366 ? '0.8rem' : '0.4rem',
                                        }}>
                                            <ListItemIcon sx={{minWidth: '3.1rem'}}><DoneRounded sx={{color: 'rgba(20, 182, 65, 1)', scale: '1.6'}}/></ListItemIcon>
                                            <ListItemText><Typography variant={"body1"} color={'rgba(0, 0, 0, 0.87)'}>{e}</Typography></ListItemText>
                                        </ListItem>
                                    ))}
                                </List>

                                <Button variant={"contained"} size={"large"} sx={{margin: 'auto auto 0', width: 'fit-content'}} component={Link} href={PATH_REGISTRATION} target={'_blank'}>
                                    {e.price === 0 ? 'Sign up' : 'Get started'}
                                </Button>

                                {e.isPopular && (
                                    <img src={MostPopular} alt={'most popular'} style={{
                                        position: 'absolute',
                                        top: down440 ? '3rem' : '4rem',
                                        right: down440 ? '-2.6rem' : '-3.9rem',
                                        width: down440 ? '16rem' : '24rem',
                                    }}/>
                                )}
                            </Box>
                        )
                    })}
                </Box>
            </Container>
        </Box>
    )
}

