import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {CircleLines, Container} from "../../../main/components/styled";
import {ourValues} from "../../../main/constants";
import {OneOfThree} from "../../../../shared/components/oneOfThree";


export const OurValues: FC = () => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    if (down1366) return (
        <Container mt={'13rem'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant={'h1'} color={'#1C0D40'} width={'min(100%, 105rem)'} textAlign={"center"}>
                {'Our values'}
            </Typography>

            <Box position={"relative"} mt={!down1024 ? '6.4rem' : '6.4rem'}>
                <CircleLines style={{position: 'absolute', zIndex: -1, top: '-10rem', right: '26.1rem'}}/>
                <Box position={"relative"} display={"flex"} alignItems={"center"} justifyContent={"space-between"} flexDirection={'column'}
                     padding={!down1366 ? '8rem 9rem 5rem' : '6rem 6rem'} bgcolor={'#FFFFFF'} zIndex={3} gap={'10rem'}
                     sx={{
                         boxShadow: '0rem 1.6rem 4rem rgba(189, 205, 229, 0.25)',
                         backdropFilter: 'blur(2.25rem)',
                         borderRadius: '2rem',
                     }}>
                    {ourValues.map(e => (
                        <OneOfThree key={JSON.stringify(e)} icon={e.icon} title={e.title} description={e.description}/>
                    ))}
                </Box>
            </Box>
        </Container>
    );

    return (
        <Container mt={'17rem'} flexDirection={'column'} alignItems={'center'}>
            <Typography variant={'h1'} color={'#1C0D40'} maxWidth={'92.9rem'} textAlign={"center"}>
                {'Our values'}
            </Typography>

            <Box position={"relative"} mt={'6.4rem'}>
                <CircleLines style={{position: 'absolute', zIndex: -1, top: '-10rem', right: '26.1rem'}}/>
                <Box position={"relative"} display={"flex"} alignItems={"flex-start"} justifyContent={"space-between"}
                     padding={'8rem 9rem 5rem'} bgcolor={'#FFFFFF'} zIndex={3} gap={'13rem'}
                     sx={{
                         boxShadow: '0rem 1.6rem 4rem rgba(189, 205, 229, 0.25)',
                         backdropFilter: 'blur(2.25rem)',
                         borderRadius: '2rem',
                     }}>
                    {ourValues.map(e => (
                        <OneOfThree key={JSON.stringify(e)} icon={e.icon} title={e.title} description={e.description}/>
                    ))}
                </Box>
            </Box>
        </Container>
    )
}
