import React, {FC} from "react";
import {Box, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import {TTkey} from "../../translates/arabic";
import {ANCHOR_FRAMEWORKS} from "../../constants";
import {TTitleDescriptionIcon} from "../../../main/types";

export const FrameworkCards: FC<{tKey: TTkey, cards: TTitleDescriptionIcon[]}> = ({tKey, cards}) => {

    const theme = useTheme();
    // const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const t = tKey('frameworks');

    const title = t('Built-in Framework Library');
    const description = t("We offer a wide range of compliance frameworks and add new frameworks monthly to help you streamline your compliance operations and maintain an effective Compliance posture strategy.");

    return (
        <Container mt={!down1366 ? '15rem' : '15rem'} display={'flex'} alignItems={'center'} flexDirection={'column'}
                   sx={{position: 'relative', zIndex: 3}} id={ANCHOR_FRAMEWORKS}>

            <Box display={'flex'} flexDirection={'column'} flexBasis={'50%'}>
                <Typography variant={'h1'} textAlign={'center'}>
                    {title}
                </Typography>

                <Box width={'100%'} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    {description.split('\n').map((e) => (
                        <Typography variant={"body1"} textAlign={'center'} key={e}>
                            <br/>{e}
                        </Typography>
                    ))}
                </Box>
            </Box>

            <Grid container spacing={4} columns={12} mt={'6.4rem'}>
                {cards.map(item => (
                    <Grid item key={item.title} xs={12} c440={6} c800={4} c1024={3} c1920={2}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            padding: '2rem', gap: '1.6rem',

                            background: '#FFFFFF',

                            boxShadow: '0rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2), 0rem 0.3rem 0.4rem rgba(0, 0, 0, 0.12), 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.14)',
                            borderRadius: '2rem',
                            height: '100%',
                            width: '100%',
                        }}>
                            <img src={item.icon} alt={item.title + ' img'} style={{
                                height: '6.4rem'
                            }}/>

                            <Typography variant={"subtitle2"}>
                                {t(item.title)}
                            </Typography>

                            <Typography variant={"body1"} mt={'1rem'} sx={{
                                // overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', maxHeight: '100%',
                                // '-webkit-box-orient': 'vertical', "-webkit-line-clamp": '6', display: '-webkit-box',
                            }}>
                                {t(item.description)}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}