// Core

import { configureStore } from '@reduxjs/toolkit'

// Instruments
import { rootReducer } from './rootReducer';
import { middleware} from './middleware';
import {useDispatch} from "react-redux";

// export const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(...middleware)));

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(middleware)
    ,
    devTools:true
});

export {store};

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>() ;



