import React, {FC} from "react";
import {Box, Grid, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import {frameworkCards} from "../../../main/constants";
import {KeyboardArrowRight} from "@mui/icons-material";
import colors from "../../../../shared/theme/colors";

const title = 'Built-in Framework Library';
const description = "We offer a wide range of compliance frameworks and add new frameworks monthly to help you streamline your compliance operations and maintain an effective Compliance posture strategy.";

export const FrameworkCards: FC = () => {

    const theme = useTheme();
    // const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Container mt={!down1366 ? '15rem' : '15rem'} display={'flex'} alignItems={'center'} flexDirection={'column'}
                   sx={{position: 'relative', zIndex: 3}}>

            <Box display={'flex'} flexDirection={'column'} flexBasis={'50%'}>
                <Typography variant={'h1'} textAlign={'center'}>
                    {title}
                </Typography>

                <Box width={'100%'} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    {description.split('\n').map((e) => (
                        <Typography variant={"body1"} textAlign={'center'} key={e}>
                            <br/>{e}
                        </Typography>
                    ))}
                </Box>
            </Box>

            <Grid container spacing={4} columns={12} mt={'6.4rem'}>
                {frameworkCards.map(item => (
                    <Grid item key={item.title} xs={12} c800={6} c1024={4} c1920={3}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            padding: '2rem', gap: '1.6rem',

                            background: '#FFFFFF',

                            boxShadow: '0rem 0.1rem 0.5rem rgba(0, 0, 0, 0.2), 0rem 0.3rem 0.4rem rgba(0, 0, 0, 0.12), 0rem 0.2rem 0.4rem rgba(0, 0, 0, 0.14)',
                            borderRadius: '2rem',
                            height: '100%',
                            width: '100%',
                        }}>
                            <Box flexBasis={'25%'}>
                                <img src={item.icon} alt={item.title + ' img'} style={{
                                    width: '100%',
                                }}/>
                            </Box>

                            <Box display={"flex"} flexDirection={"column"} flexBasis={'75%'}>
                                <Typography variant={"subtitle2"}>
                                    {item.title}
                                </Typography>

                                <Typography variant={"body1"} mt={'1rem'} sx={{
                                    // overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%', maxHeight: '100%',
                                    // '-webkit-box-orient': 'vertical', "-webkit-line-clamp": '6', display: '-webkit-box',
                                }}>
                                    {item.description}
                                </Typography>

                                {item.link && (
                                    <Box display={"flex"} alignItems={"center"} alignSelf={"flex-end"} flexShrink={1} mt={'2rem'} gap={'0.8rem'} sx={{cursor: 'pointer'}}>
                                        <Typography variant={"body1"} color={colors.landing.text2}>
                                            {'View details'}
                                        </Typography>
                                        <KeyboardArrowRight sx={{color: colors.landing.text2}}/>
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    )
}