import React, {FC} from "react";
import {Box, Typography} from "@mui/material";

export const TermsAndConditions: FC = () => {

    // const theme = useTheme();
    // const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Box sx={{display: 'flex', flexDirection: 'column', width: '100%',
            '& .MuiTypography-subtitle2': {
                marginBottom: '0.8rem',
            },
            '& .MuiTypography-body1': {
                marginTop: '1rem',
            },
        }}>
            <Typography variant={"h1"}>Terms & Conditions</Typography>
            <Typography variant={"body1"}>Date last modified: March 12, 2023</Typography>
            <Typography variant={"body1"}>By using this website, you agree to the following terms and conditions.</Typography>

            <Typography variant={"subtitle2"}>General</Typography>
            <Typography variant={"body2"}>By accessing or using this website (the “Regulait Website”) of Regulait Security Ltd, with address at Abba Hillel Silver Road 7, Ramat Gan, Israel (“Regulait”), you agree to be bound by these terms  and conditions (these “Terms”), as may change from time to time, and the Privacy Policy, available at https://regulait.com/privacy, as may change from time to time (these Terms together with the Privacy Policy, the “Terms & Conditions”), , If you do not agree with the Terms & Conditions, you must  leave the Regulate Website and not use it.</Typography>
            <Typography variant={"subtitle2"}>Materials</Typography>
            <Typography variant={"body2"}>All materials contained on the Regulait Website are provided for informational purposes only and should not be construed as an advice, commercial offer, license, or professional relationship between you and Regulait. No information provided on the Regulait Website should be considered a substitute for your independent research.</Typography>
            <Typography variant={"subtitle2"}>Proprietary Rights</Typography>
            <Typography variant={"body2"}>All content, including but not limited to text, graphics, logos, images, and software, on the Regulait Website is the property of Regulait and is protected by copyright laws. You may not use, modify, reproduce or distribute any of the content without Regulait’s prior written approval.</Typography>
            <Typography variant={"subtitle2"}>Lists of Customers, Partners or Investors</Typography>
            <Typography variant={"body2"}>The Regulait Website may include lists of customers, investors or partners who have invested in or partnered with Regulait. These lists are for informational purposes only and do not constitute an endorsement of Regulait or its products or services.</Typography>
            <Typography variant={"subtitle2"}>Contact Window</Typography>
            <Typography variant={"body2"}>The Regulait Website may provide a contact window or form such as ‘talk to an expert’ for users to contact Regulait (with clicking a ‘get in touch’ button). Should you use it, you are solely responsible for the content of your messages and must not use the contact window or form to send spam or other unsolicited messages. Regulait reserves the right to block users who abuse the contact window or form. Please note that if you do not fill in your correct and accurate contact details, Regulait will not be able to contact you. For the avoidance of doubt, in any event, Regulait has no obligation to contact you.</Typography>
            <Typography variant={"subtitle2"}>Links</Typography>
            <Typography variant={"body2"}>The Regulait Website may contain links to Regulait’s services or products that may be subject to other terms of use or other provisions. You acknowledge that when you click on any such link you may be bound and subject to such terms of use or other provisions other than or further to the Terms and Conditions.</Typography>
            <Typography variant={"body2"}>The Regulait Website may also contain links to third-party websites, services, or products that are not owned or controlled by Regulait. Regulait is not responsible for the content, privacy policies, or practices of any third-party websites and makes no guarantees or promises regarding such websites.</Typography>
            <Typography variant={"subtitle2"}>Disclaimer</Typography>
            <Typography variant={"body2"}>THE REGULAIT WEBSITE AND ITS CONTENT ARE PROVIDED "AS IS," WITHOUT ANY WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED. REGULAIT MAKES NO WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE QUALITY, ACCURACY, COMPLETENESS, RELIABILITY, OR SUITABLITY OF THE CONTENT.</Typography>
            <Typography variant={"subtitle2"}>Opinions</Typography>
            <Typography variant={"body2"}>The Regulait Website may present opinions or testimonials from customers who have used Regulait’s products or services. These opinions are the sole views of the customers and do not represent the views or opinions of Regulait. Regulait makes no guarantees or promises regarding the accuracy, reliability, or authenticity of these opinions.</Typography>
            <Typography variant={"subtitle2"}>Unlawful or Prohibited Use</Typography>
            <Typography variant={"body2"}>You may not reproduce, duplicate, copy or otherwise exploit any of the content on the Regulait Website for a commercial or any unauthorized purpose without a prior written consent of Regulait.</Typography>
            <Typography variant={"subtitle2"}>Indemnification</Typography>
            <Typography variant={"body2"}>You hereby agree to defend, indemnify and hold Regulait, their owners, employees, consultants and affiliates, harmless from and against all damages, claims, costs, charges and expenses (including attorneys’ fees) arising out of your use of the Regulait Website.</Typography>
            <Typography variant={"subtitle2"}>Data Protection and Privacy</Typography>
            <Typography variant={"body2"}>In performing the Services, Reguliat will comply with the Regulait Privacy Policy, which is available at https://regulait.com/privacy and incorporated herein by reference, as may change from time to time (the “Privacy Policy”). Regulait highly regards your rights in your personal data and your privacy. The collection and use of your data is subject to the Privacy Policy.</Typography>
            <Typography variant={"subtitle2"}>Amendments</Typography>
            <Typography variant={"body2"}>Regulait reserves the right, at its sole discretion, to modify or replace the Terms & Conditions at any time. You are advised to review the Terms & Conditions on a regular basis. By continuing to access or use the Regulait Website, after any revisions become effective, you agree to be bound by the revised terms.</Typography>
            <Typography variant={"subtitle2"}>Waiver</Typography>
            <Typography variant={"body2"}>No failure to exercise, and no delay in exercising, on the part of either party, any right or any power under the Terms & Conditions shall operate as a waiver of that right or power. Nor shall any single or partial exercise of any right or power under the Terms & Conditions preclude further exercise of that or any other right granted herein.</Typography>
            <Typography variant={"subtitle2"}>Severability</Typography>
            <Typography variant={"body2"}>If any provision of the Terms & Conditions is deemed to be void, invalid, unenforceable or illegal, Regulait will replace such invalid provision while the other provisions shall continue in full force and effect.</Typography>
            <Typography variant={"subtitle2"}>Law and Jurisdiction</Typography>
            <Typography variant={"body2"}>The Terms & Conditions and your use of the Services are governed by the laws of the State of Israel. The courts of competent jurisdiction located within the district of Tel Aviv-Jaffa, Israel, will have the exclusive jurisdiction over any and all disputes arising out of, relating to or concerning the Terms & Conditions or in which the Terms & Conditions are a material fact.</Typography>
            <Typography variant={"subtitle2"}>No Employment or Agency Relationship</Typography>
            <Typography variant={"body2"}>No provision of the Terms & Conditions, or any part of the relationship between you and Regulait, is intended to create nor shall they be deemed or construed to create any relationship between you and Regulait other than as specifically referred to in the Terms & Conditions.</Typography>
            <Typography variant={"subtitle2"}>Entire Agreement</Typography>
            <Typography variant={"body2"}>The Terms & Conditions constitute the entire agreement between you and Rgulait with respect to the use of the Regulait Website.</Typography>
            <Typography variant={"subtitle2"}>Contact Us</Typography>
            <Typography variant={"body2"}>Don't hesitate to contact us if you have any questions about the Terms & Conditions.</Typography>
            <Typography variant={"body2"}>
                <ul>
                    <li>
                        Via Email: support@regulait.com
                    </li>
                    <li>
                        Via this Link: https://regulait.com/contact
                    </li>
                </ul>
            </Typography>
        </Box>
    )
}