import {ThemeOptions} from "@mui/material/styles/createTheme";
import colors from "./colors";

export const muiTheme: ThemeOptions = {
    direction: 'ltr',
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            c375: 375,
            c440: 440,
            c600: 600,
            c800: 800,
            c1024: 1024,
            c1366: 1366,
            c1440: 1440,
            c1680: 1680,
            c1920: 1920,
            c2048: 2048,
        }
    },

    typography: {
        fontFamily: `'Poppins', sans-serif`,
        fontSize: 14,
        fontWeightLight: 100,
        fontWeightRegular: 400,
        fontWeightMedium: 600,
        fontWeightBold: 700,

        allVariants: {
            fontFamily: `'Poppins', sans-serif`,
            color: colors.landing.text,
            fontWeight: 400,
            fontSize: '2rem',
            lineHeight: '3rem',
        },

        h1: {
            fontWeight: 600,
            fontSize: '4.8rem',
            lineHeight: '5.856rem',
        },
        h2: {
            fontWeight: 600,
            fontSize: '3.6rem',
            lineHeight: '5.4rem',
        },
        h3: {
            fontWeight: 500,
            fontSize: '2.4rem',
            lineHeight: '3.6rem',
        },

        subtitle1: {
            fontWeight: 700,
            fontSize: '2.4rem',
            lineHeight: '3.6rem',
        },
        subtitle2: {
            fontWeight: 600,
            fontSize: '1.8rem',
            lineHeight: '2.8rem',
        },

        body1: {
            fontWeight: 400,
            fontSize: '1.6rem',
            lineHeight: '2.16rem',
        },
        body2: {
            fontWeight: 500,
            fontSize: '1.4rem',
            lineHeight: '2.4rem',
            color: 'rgba(0, 0, 0, 0.6)',
        },
        caption: {
            fontWeight: 400,
            fontSize: '2.0rem',
            lineHeight: '3rem',
        },

        button: {
            fontWeight: 600,
            fontSize: '1.6rem',
            lineHeight: '3rem',
            letterSpacing: '0.04em',
            textTransform: 'none',
            color: 'white'
        },
    },
    // shape: {borderRadius: 25},
    palette: {
        primary: {
            main: colors.landing.button,
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: colors.landing.secondary1,
            contrastText: '#FFFFFF'
            // contrastText: "#323232",
        },
        text: {
            primary: colors.landing.text,
        },
        // error: {
        //     main: '#FF5959'
        // },
        // warning: {
        //     main: '#FAC000'
        // },
        // info: {
        //     main: '#64B5F6'
        // },
        // success: {
        //     main: '#3BCC89'
        // },
        // grey: {
        //     A700: "#56657F"
        // },

        //extend colors here
    },

    zIndex: {
        modal: 3100,
        snackbar: 3200,
        tooltip: 3300
    },

    components: {
        MuiTypography: {
            defaultProps: {
                variant: 'caption',
            },
            variants: [
                {
                    props: {variant: 'decorative'}, style: ({theme}) => ({
                        fontWeight: 600, fontSize: '10.2rem', lineHeight: '15.3rem',
                        [theme.breakpoints?.down('c800')]: {
                            fontWeight: 600, fontSize: '6.4rem', lineHeight: '7.744rem',
                        },
                    })
                },
                {
                    props: {variant: 'price'}, style: ({theme}) => ({
                        fontWeight: 600, fontSize: '4.8rem', lineHeight: '4.8rem', marginTop: 'calc((8.16rem - 4.8rem) / 2)',
                        [theme.breakpoints?.down('c800')]: {
                            fontWeight: 600, fontSize: '4rem', lineHeight: '4rem', marginTop: 'calc((6.8rem - 4rem) / 2)',
                        },
                    })
                },
                {
                    props: {variant: 'tabText'}, style: ({theme}) => ({
                        fontWeight: 500, fontSize: '1.8rem', lineHeight: '3rem',
                        // [theme.breakpoints?.down('c800')]: {
                        //     fontWeight: 600, fontSize: '4rem', lineHeight: '6.8rem',
                        // },
                    })
                },
                {
                    props: {variant: 'smallText'}, style: ({theme}) => ({
                        fontWeight: 500, fontSize: '1.4rem', lineHeight: '2.4rem',
                        // [theme.breakpoints?.down('c800')]: {
                        //     fontWeight: 600, fontSize: '6.4rem', lineHeight: '7.744rem',
                        // },
                    })
                },
            ],
            styleOverrides: {
                h1: ({theme}) => ({
                    [theme.breakpoints.down('c800')]: {
                        fontWeight: 600,
                        fontSize: '3.2rem',
                        lineHeight: '3.904rem',
                    },
                }),
                h2: ({theme}) => ({
                    [theme.breakpoints.down('c800')]: {
                        fontWeight: 600,
                        fontSize: '2.4rem',
                        lineHeight: '2.928rem',
                    },
                }),
                h3: ({theme}) => ({
                    [theme.breakpoints.down('c800')]: {
                        fontWeight: 500,
                        fontSize: '2rem',
                        lineHeight: '2.44rem',
                    },
                }),
                body1: ({theme}) => ({
                    [theme.breakpoints.down('c800')]: {
                        fontWeight: 400,
                        fontSize: '1.4rem',
                        lineHeight: '1.932rem',
                    },
                }),
                subtitle1: ({theme}) => ({
                    [theme.breakpoints.down('c800')]: {
                        fontWeight: 700,
                        fontSize: '2rem',
                        lineHeight: '3rem',
                    },
                }),
                button: ({theme}) => ({
                    [theme.breakpoints.down('c440')]: {
                        fontWeight: 600,
                        fontSize: '1.4rem',
                        lineHeight: '3rem',
                    },
                }),
            }
        },

        MuiButton: {
            defaultProps: {
                variant: 'contained',
                color: 'primary',
                size: 'large',
            },

            styleOverrides: {
                sizeMedium: ({theme}) => ({
                    padding: '1.4rem 4rem',
                    gap: '1rem',
                    height: '5rem',
                    borderRadius: '1rem',
                    // height: 'auto',

                    ...theme.typography.button,
                    color: 'white',

                    textDecoration: 'none',
                    textTransform: 'none',

                    [theme.breakpoints.down('c440')]: {
                        padding: '0.6rem 2rem',
                        height: '4.2rem',

                        fontSize: '1.4rem',
                    },
                }),
                sizeLarge: ({theme}) => ({
                    padding: '1.4rem 4rem',
                    gap: '1rem',
                    height: '5rem',

                    borderRadius: '1rem',
                    // height: 'auto',

                    ...theme.typography.button,
                    color: 'white',

                    textDecoration: 'none',
                    textTransform: 'none',
                }),
            }
        },

        MuiIconButton: {
            styleOverrides: {
                sizeLarge: ({theme}) => ({
                    width: '6rem',
                    height: '6rem',
                    // padding: '1.8rem',

                    '& svg': {
                        width: '2.5rem',
                        height: '2.5rem',
                    },

                    [theme.breakpoints.down('c440')]: {
                        width: '5rem',
                        height: '5rem',
                        // padding: '1.8rem',

                        '& svg': {
                            width: '2.5rem',
                            height: '2.5rem',
                        },
                    },
                })
            }
        },

        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                size: 'medium',
            },
        },

        MuiLink: {
            defaultProps: {
                underline: 'none',
                color: 'inherit',
            },
        },
    }
};

// export default theme;/
