import {useEffect, useState} from "react";

type TAccessibility = {
    monochrome: boolean,
    contrast: boolean,
    size: number,
}

export const accessibilityDefault: TAccessibility = {monochrome: false, contrast: false, size: 10}

export const useAccessibility = () => {

    const local = JSON.parse(localStorage.getItem('accessibility') || '{}') as TAccessibility | null;

    const [isOpen, setOpen] = useState<HTMLElement | null>(null);
    const [accessibility, setAccessibility] = useState<TAccessibility>({
        monochrome: local?.monochrome || accessibilityDefault.monochrome,
        contrast: local?.contrast || accessibilityDefault.contrast,
        size: local?.size || accessibilityDefault.size,
    });

    useEffect(() => {
        const html = document.querySelector('html');
        if (html) {
            html.style.fontSize = `${accessibility.size}px`;

            if (accessibility.monochrome) {
                html.classList.add('monochrome');
            } else {
                html.classList.remove('monochrome');
            }

            if (accessibility.contrast) {
                html.classList.add('contrast');
            } else {
                html.classList.remove('contrast');
            }
        }

        const tmp = isOpen;
        setOpen(null);
        setOpen(tmp);
        localStorage.setItem('accessibility', JSON.stringify(accessibility))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessibility])

    return {
        isOpen, setOpen,
        accessibility,

        setAccessibility,
    }
}