import React, {FC} from "react";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {Container} from "../../../main/components/styled";
import {TTkey} from "../../translates/arabic";
import IntegrationsIcon from "../../../../shared/images/new/landing/integrations.png";
import {ANCHOR_INTEGRATIONS} from "../../constants";

export const Integrations: FC<{tKey: TTkey}> = ({tKey}) => {

    const theme = useTheme();
    const down1024 = useMediaQuery(theme.breakpoints.down('c1024'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    const t = tKey('integrations');

    const title = t('Integrations');
    const description = t("Regulait integrates with dozens of services. With these integrations, you'll have access to a wide range of tools and services to help you manage your compliance efforts more effectively. From cloud storage solutions to secure communications platforms, you'll have everything you need to ensure your compliance management is always up-to-date.");

    return (
        <Container mt={!down1366 ? '14rem' : '10rem'} display={'flex'} alignItems={'center'} {...(down1024 ? {flexDirection: 'column'} : {})}
                   sx={{overflowX: 'hidden', overflowY: 'visible', position: 'relative'}} gap={'3.2rem'}
                   pb={'1rem'} id={ANCHOR_INTEGRATIONS}>

            <Box display={'flex'} flexDirection={'column'} flexBasis={'50%'}>
                <Typography variant={'h1'} textAlign={'start'}>
                    {title}
                </Typography>

                <Box width={'100%'} display={"flex"} flexDirection={"column"} alignItems={"flex-start"}>
                    {description.split('\n').map((e) => (
                        <Typography variant={"body1"} textAlign={'start'} key={e}>
                            <br/>{e}
                        </Typography>
                    ))}
                </Box>
            </Box>


            <Box display={'flex'} flexDirection={"column"} alignItems={"center"} flexBasis={'50%'}>
                <img src={IntegrationsIcon} alt={'Frameworks icon'} style={{width: '100%', position: "relative", zIndex: 3}}/>
            </Box>
        </Container>
    )
}