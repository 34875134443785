import React, {ChangeEvent, FC, useState} from "react";
import {CircleLines, ContactTextField, Container} from "../styled";
import {Box, Typography, useMediaQuery, useTheme} from "@mui/material";
import {TContactForm} from "../../types";
import {LoadingButton} from "@mui/lab";
import {useSnack} from "../../../snack/hooks/useSnack";
import {emailRegex} from "../../constants";
import {sendContactUsEmail} from "../../../../shared/api";


export const AnyQuestions: FC = () => {

    const [loading, setLoading] = useState<boolean>(false);
    const [form, setForm] = useState<TContactForm>({
        firstName: '', lastName: '', companyName: '', email: '', message: '', contactNumber: ''
    })
    const handleChange = (field: keyof TContactForm) => (e: ChangeEvent<HTMLTextAreaElement>) => {
        setForm(prev => ({...prev, [field]: e.target.value}))
    }

    const snack = useSnack();
    const handleSubmit = async () => {
        if (form.firstName.trim() === '') {
            snack('First name should not be empty', "error");
            return;
        }
        if (form.lastName.trim() === '') {
            snack('Last name should not be empty', "error");
            return;
        }

        if (!emailRegex.test(form.email)) {
            snack(`${form.email} doesn't looks like 'email'`, "error");
            return;
        }

        if (form.message.trim() === '') {
            snack('Message should not be empty', "error");
            return;
        }

        try {
            setLoading(true);
            // await sendEmail({
            await sendContactUsEmail({
                firstName: form.firstName,
                lastName: form.lastName,
                email: form.email,
                companyName: form.companyName,
                contactNumber: form.contactNumber,
                message: form.message,
            }, (message) => snack(message, "error"));
            snack('Message sent', 'success');
            setForm({firstName: '', lastName: '', companyName: '', email: '', message: '', contactNumber: ''})
        } finally {
            setLoading(false);
        }
    }

    const theme = useTheme();
    const down800 = useMediaQuery(theme.breakpoints.down('c800'));
    const down1366 = useMediaQuery(theme.breakpoints.down('c1366'));

    return (
        <Container mt={'15rem'} position={'relative'} justifyContent={'space-between'}
                   overflow={'hidden'}
                   alignItems={'center'} flexDirection={down1366 ? 'column' : undefined}
                   sx={{backgroundColor: '#FAFBFC', padding: !down800 ? '6.5rem' : '6.5rem 1rem', borderRadius: '2rem'}}>
            <Box display={"flex"} flexDirection={"column"} zIndex={4} width={!down1366 ? 'calc(100% - 50rem)' : undefined}>
                <Typography variant={'h1'} textAlign={'start'}>
                    {'Do you have any questions?'}
                </Typography>
                <Typography variant={"body1"} mt={'1.6rem'} textAlign={'start'}>
                    {'Feel free to talk to us if you have any questions. Just fill the form and we will get in touch with you shortly.'}
                </Typography>
            </Box>

            <Box display={"flex"} flexDirection={"column"} p={!down800 ? '4rem' : '2rem 2rem'} zIndex={4} mt={'5rem'}
                 width={!down800 ? undefined : '100%'}
                 sx={{
                     background: '#FFFFFF',
                     boxShadow: '0rem 0.4rem 2rem rgba(0, 0, 0, 0.16)',
                     borderRadius: '1rem',
                 }}>
                <Box display={"flex"} flexDirection={down800 ? "column" : undefined}>
                    <ContactTextField value={form.firstName} onChange={handleChange('firstName')} label={'First name'} name={'firstName'}/>
                    <div style={{width: '2rem', height: '2rem'}}/>
                    <ContactTextField value={form.lastName} onChange={handleChange('lastName')} label={'Last name'} name={'lastName'}/>
                </Box>
                <div style={{width: '2rem', height: '2rem'}}/>
                <ContactTextField value={form.email} onChange={handleChange('email')} label={'E-mail'} name={'email'}/>
                    <div style={{width: '2rem', height: '2rem'}}/>
                <ContactTextField value={form.companyName} onChange={handleChange('companyName')} label={'Company name'} name={'company'}/>
                    <div style={{width: '2rem', height: '2rem'}}/>
                <ContactTextField value={form.message} onChange={handleChange('message')} label={'Your Message...'} name={'comment'}
                           multiline minRows={3} maxRows={3}/>


                <div style={{width: '2rem', height: '2rem'}}/>
                <LoadingButton loading={loading} variant={"contained"} fullWidth size={"large"} onClick={handleSubmit}>Submit</LoadingButton>
            </Box>

            <Box height={'20.3rem'} width={'100%'} position={'absolute'} bottom={'0'} left={'0'}>
                <CircleLines style={{position: 'absolute', top: '-8rem', left: '-4rem'}}/>
                <div style={{width: '100%', height: '100%', backgroundColor: '#BED1FF', zIndex: 3, position: 'relative'}}/>
            </Box>
        </Container>
    )
}
